import {
  CATEGORIES_REQUESTED,
  CATEGORIES_SUCCESS,
  CATEGORIES_FAILURE,
} from '../types';

const INITIAL_STATE = {
  categories: {},
  isFetching: false,
  status: 'waiting',
  errors: null
};

const categories = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case CATEGORIES_REQUESTED:
      return Object.assign({}, state, {
        categories: {},
        isFetching: true,
        status: 'requested',
        errors: null
      });

    case CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        categories: action.payload.categories || {},
        isFetching: false,
        status: 'success',
        errors: null
      });

    case CATEGORIES_FAILURE:
      return Object.assign({}, state, {
        categories: {},
        isFetching: false,
        status: 'error',
        errors: action.error
      });

    default:
      return state;
  }
}

export default categories;
