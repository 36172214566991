import {
  SCAN_INVENTORY_REQUESTED,
  SCAN_INVENTORY_SUCCESS,
  SCAN_INVENTORY_FAILURE,
  BULK_INVENTORY_CHANGE_REQUESTED,
  BULK_INVENTORY_CHANGE_SUCCESS,
  BULK_INVENTORY_CHANGE_FAILURE,
  BATCH_DETAILS_REQUESTED,
  BATCH_DETAILS_SUCCESS,
  BATCH_DETAILS_FAILURE,
  ADD_BULK_PLACEHOLDER_ACTION,
  CLEAR_BULK_SESSION,
} from '../types';


const INITIAL_STATE = {
  isFetching: false,
  events: [],
  status: 'waiting',
  batchId: null,
  batchDetails: null,
  errors: null
};

const bulkInventorySessions = (state = INITIAL_STATE, action) => {
  const newState = { ...state };

  switch(action.type) {
    case ADD_BULK_PLACEHOLDER_ACTION:
      return Object.assign({}, state, {
        events: [...state.events, { actionType: action.actionType, sku: action.barcode, placeholder: true }]
      });

    case SCAN_INVENTORY_REQUESTED:
      newState.isFetching = true;
      newState.status = 'requesting';
      return newState;

    case SCAN_INVENTORY_SUCCESS:
      newState.isFetching = false;
      newState.status = 'success';
      newState.errors = null;
      if(newState.events[action.payload.actionId]){
        newState.events[action.payload.actionId] = action.payload.item;
      }
      return newState;

    case SCAN_INVENTORY_FAILURE:
      newState.isFetching = false;
      newState.status = 'waiting';
      newState.errors = action.error.error;
      if(newState.events[action.error.actionId]){
        newState.events[action.error.actionId] = action.error;
      }
      return newState;


    case BULK_INVENTORY_CHANGE_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        status: 'requesting',
        batchId: null,
        errors: null
      });

    case BULK_INVENTORY_CHANGE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'success',
        batchId: action.payload.batchId,
        errors: null
      });

    case BULK_INVENTORY_CHANGE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'waiting',
        batchId: null,
        errors: 'error here'
      });

    case BATCH_DETAILS_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        status: 'requesting',
        errors: null
      });

    case BATCH_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'success',
        batchDetails: action.payload.batchResults,
        errors: null
      });

    case BATCH_DETAILS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'waiting',
        errors: 'error here'
      });




    case CLEAR_BULK_SESSION:
      return INITIAL_STATE;

    default:
      return state;

  }
}

export default bulkInventorySessions;
