import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  READ_ORDER_REQUESTED,
  READ_ORDER_SUCCESS,
  READ_ORDER_FAILURE,
  UPDATE_ORDER_FULFILLMENT_STATUS_REQUESTED,
  UPDATE_ORDER_FULFILLMENT_STATUS_SUCCESS,
  UPDATE_ORDER_FULFILLMENT_STATUS_FAILURE,
  INDEX_FULFILLMENT_STATUSES_REQUESTED,
  INDEX_FULFILLMENT_STATUSES_SUCCESS,
  INDEX_FULFILLMENT_STATUSES_FAILURE,
  CLEAR_ORDER,
  SET_CHECKED_LIST,
  SET_UNPICKABLE_LIST,
  UPDATE_LINE_ITEM_QUANTITY_REQUESTED,
  UPDATE_LINE_ITEM_QUANTITY_SUCCESS,
  UPDATE_LINE_ITEM_QUANTITY_FAILURE,
} from '../types';


export const readOrderRequested = () => {
  return {
    type: READ_ORDER_REQUESTED
  }
};

export const readOrderSuccess = (payload) => {
  return {
    type: READ_ORDER_SUCCESS,
    payload: payload
  }
};

export const readOrderFailure = (orderId) => {
  return {
    type: READ_ORDER_FAILURE,
    error: `Could not read order ${orderId}`
  }
};

export const indexFulfillmentStatusesRequested = () => {
  return {
    type: INDEX_FULFILLMENT_STATUSES_REQUESTED
  }
};

export const indexFulfillmentStatusesSuccess = (payload) => {
  return {
    type: INDEX_FULFILLMENT_STATUSES_SUCCESS,
    payload: payload
  }
};

export const indexFulfillmentStatusesFailure = () => {
  return {
    type: INDEX_FULFILLMENT_STATUSES_FAILURE
  }
};


export const updateFulfillmentStatusRequested = () => {
  return {
    type: UPDATE_ORDER_FULFILLMENT_STATUS_REQUESTED
  }
}

export const updateFulfillmentStatusSuccess = (payload) => {
  return {
    type: UPDATE_ORDER_FULFILLMENT_STATUS_SUCCESS,
    payload: payload
  }
}

export const updateFulfillmentStatusFailure = () => {
  return {
    type: UPDATE_ORDER_FULFILLMENT_STATUS_FAILURE
  }
}

export const updateLineItemQuantityRequested = () => {
  return {
    type: UPDATE_LINE_ITEM_QUANTITY_REQUESTED
  }
}

export const updateLineItemQuantitySuccess = (payload) => {
  return {
    type: UPDATE_LINE_ITEM_QUANTITY_SUCCESS,
    payload: payload
  }
}

export const updateLineItemQuantityFailure = () => {
  return {
    type: UPDATE_LINE_ITEM_QUANTITY_FAILURE
  }
}


export const readOrder = (signedPayload, orderId) => {
  return (dispatch) => {
    dispatch(readOrderRequested());

    let endPoint = Config.baseUrl + Config.order + '/' + orderId;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(readOrderSuccess(response.data));
      })
      .catch((error) => {
        dispatch(readOrderFailure(orderId));
      });
  }
}

export const indexFulfillmentStatuses = (signedPayload) => {
  return (dispatch) => {
    dispatch(indexFulfillmentStatusesRequested());

    let endPoint = Config.baseUrl + Config.order_statuses;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(indexFulfillmentStatusesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(indexFulfillmentStatusesFailure());
      });
  }
}

export const updateFulfillmentStatus = (signedPayload, orderId, status) => {
  return (dispatch) => {
    dispatch(updateFulfillmentStatusRequested());

    let endPoint = Config.baseUrl + Config.orders + orderId;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.put(endPoint, { status: status } , { headers: headers })
      .then((response) => {
        dispatch(updateFulfillmentStatusSuccess(response.data));
      })
      .catch((error) => {
        dispatch(updateFulfillmentStatusFailure());
      });
  }
}

export const updateLineItemQuantity = (signedPayload, orderId, lineItemId, quantity) => {
  return (dispatch) => {
    dispatch(updateLineItemQuantityRequested());

    let endPoint = Config.baseUrl + Config.orders + orderId;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.put(endPoint, { quantity: quantity, line_item_id: lineItemId } , { headers: headers })
      .then((response) => {
        if(response.data.status === 200) {
          dispatch(updateLineItemQuantitySuccess(response.data));
        } else {
          dispatch(updateLineItemQuantityFailure());
        }
      })
      .catch((error) => {
        dispatch(updateLineItemQuantityFailure());
      });
  }
}

export const setCheckedList = (checkedList) => {
  return (dispatch) => {
    dispatch({
      type: SET_CHECKED_LIST,
      checkedList: checkedList
    })
  }
}

export const setUnpickableList = (unpickableList) => {
  return (dispatch) => {
    dispatch({
      type: SET_UNPICKABLE_LIST,
      unpickableList: unpickableList
    })
  }
}

export const clearOrder = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ORDER
    })
  }
}

