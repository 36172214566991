import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import ModifyRow from "./ModifyRow";
import SubmittedMessage from "./SubmittedMessage";
import LoadingSpinner from '../partials/LoadingSpinner';
import SettingsPage from '../partials/SettingsPage';
import PageHeader from '../partials/PageHeader';
import { Text, Input, Dropdown, Panel, H4, Button, Textarea, Small } from '@bigcommerce/big-design';
import { ArrowDropDownIcon } from '@bigcommerce/big-design-icons';

import { addBulkPlaceholderAction, clearBulkActions, publishBulkActions } from '../../redux/actions/bulkInventorySessions';
import { scanInventory } from '../../redux/actions/inventorySessions';
import { locationsIndex } from '../../redux/actions/locations';

const Remove = ({ payload, clearSession, postScanInventory, addAction, bulkInventorySession, publishBulkRemove, locations, loadLocations }) => {
  const [submitted, setSubmitted] = useState(false);
  const [scanTerm, setScanTerm] = useState('');
  const [changeNotes, setChangeNotes] = useState('');
  const [bulkChangeValues, setBulkChangeValues] = useState([]);
  const [totalChangeValues, setTotalChangeValues] = useState(0);
  const [activeLocation, setActiveLocation] = useState(null);
  const [activeLabel, setActiveLabel] = useState(null);

  const updateScan = (event) => {
    let value = event.target.value;
    setScanTerm(value);
  }

  const updateNotes = (event) => {
    let value = event.target.value;
    setChangeNotes(value);
  }

  const publishSession = () => {
    let submitCounts = [];

    bulkInventorySession.events.map((event, index) => {
      if(!(event.error)){
        event.modifyCount = bulkChangeValues[event.sku];
        submitCounts.push(event);
      }
    })

    let textArea = document.getElementById("notes");
    let clearButton = document.getElementById("clear-button");
    let submitButton = document.getElementById("submit-button");

    textArea.disabled = "true";
    clearButton.disabled = "true";
    submitButton.disabled = "true";

    publishBulkRemove(payload, submitCounts, changeNotes, activeLocation);
    setSubmitted(true);
  }

  const setValue = (value, sku) => {
    setBulkChangeValues(bulkChangeValues=>({
      ...bulkChangeValues,
      [sku]: value
    }));
  }

  const setTotal = () => {
    let tempValue = 0;

    for (const [key, value] of Object.entries(bulkChangeValues)) {
      tempValue += (parseInt(value) || 0);
    }

    setTotalChangeValues(tempValue);
  }

  const bulkEventsLoaded = () => {
    let firstLoading = bulkInventorySession.events.find((event) => {
      return event.placeholder
    });

    return !firstLoading;
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(scanTerm !== ''){
        addAction(scanTerm);
        postScanInventory(payload, scanTerm, bulkInventorySession.events.length, activeLocation);
        setScanTerm('');
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [scanTerm, 500]);

  useEffect(() => {
    clearSession();
    loadLocations(payload);
  }, []);

  useEffect(() => {
    setTotal();
  }, [bulkChangeValues]);

  useEffect(() => {
    setActiveLocation(locations?.locations[0]);
    setActiveLabel(locations?.locations[0]?.label);
    flashScanBar();
  }, [locations]);

  const switchLocation = (event, setter) => {
    let location = locations.locations.find(location => location.id === event.hash);
    setter(location);
    setActiveLabel(location.label);
    flashScanBar();
  }

  const locationItems = () => {
    let items = [];

    locations.locations.map(location => 
      items.push(
        {
          content: location.label,
          onItemClick: (event) => switchLocation(event, setActiveLocation),
          hash: location.id
        }
      )
    )

    return items;
  }

  const flashScanBar = () => {
    let scanBarElement = document.querySelector("#scan-bar");

    setTimeout(() => {
      scanBarElement?.classList?.add("blink-action");
    }, 500);

    setTimeout(() => {
      scanBarElement?.classList?.remove("blink-action");
    }, 1500);
  }

  const description = () => {
    return(
      <>
        <Text>Select the "Scan a barcode" bar and scan a generated Code 128 SKU (the generated barcodes from the "Labels + Print" section of this app, or similar) with a barcode scanner. This scan should also work with UPCs, EANs or ISBN values if they have been set in products or variants.</Text>
        <Small>Have questions or need some help? Contact us at <a href="mailto:support@athousandapps.com">support@athousandapps.com</a></Small>
      </>
    )
  }

  const sessionDescription = () => {
    return(
      <>
        <Text>When items are scanned, they should appear below. Add a value to the "Modify Quantity" field for each item. When submitted, the quantity of that item that's in stock will change by the set amount.</Text>
        <Text>You can add a description of what's happening to the "Add Notes" section. For example, if you have expired products, you can record the expiration dates or lot numbers of the expiring products for bookkeeping.</Text>
        <Text>Once quantities have been set and information has been added to the notes section, click the "Submit Changes" button to submit the inventory modifications.</Text>
      </>
    )
  }

  return(
    <> 
      <PageHeader stripeBackground='#943737' helpLink="bigcommerce/reduce-inventory/" />
      <SettingsPage title="Remove Inventory" description={description()}>
        <Panel>
          <div className="flex-container">
            <svg width="1.25rem" width="150" height="80" fill="#189dee" viewBox="0 0 32 32" id="svg5" version="1.1">
              <defs id="defs2"/>
              <g id="layer1" transform="translate(-108,-100)">
                <path d="m 111,106 a 1.0001,1.0001 0 0 0 -1,1 v 3 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" id="path11698" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 134,106 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -3 a 1.0001,1.0001 0 0 0 -1,-1 z" id="path11700" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 137,121 a 1,1 0 0 0 -1,1 v 2 h -2 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3 a 1.0001,1.0001 0 0 0 1,-1 v -3 a 1,1 0 0 0 -1,-1 z" id="path11702" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 111,121 a 1,1 0 0 0 -1,1 v 3 a 1.0001,1.0001 0 0 0 1,1 h 3 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 h -2 v -2 a 1,1 0 0 0 -1,-1 z" id="path11704" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 115,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11706" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 118,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11708" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 121,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11710" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 124,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11712" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 127,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11714" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 130,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11716" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 133,110 a 1,1 0 0 0 -1,1 v 5.20703 1.31445 V 121 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 117.52148 116.20703 111 a 1,1 0 0 0 -1,-1 z" id="path11720" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
              </g>
            </svg>
            <div className="flex-4">
              <H4>Locations</H4>
              { locations.status !== 'success' &&
                <LoadingSpinner size="xxSmall" statusText="Loading Locations" />
              }

              { locations.status === 'success' &&
                <Dropdown
                  items={locationItems()}
                  maxHeight={250}
                  placement="bottom-start"
                  toggle={<Button iconRight={<ArrowDropDownIcon />}>{activeLabel || "Select Location"}</Button>}
                />
              }

              <hr/>

              <Input
                id="scan-bar"
                required
                label="Scan Barcode (SKU, UPC, EAN)"
                onChange={updateScan}
                placeholder="Click here to begin scanning"
                type="text"
                value={scanTerm}
                disabled={submitted || !activeLocation}
              />
            </div>
          </div>
        </Panel>
      </SettingsPage>
      <SettingsPage description={sessionDescription()}>
        { submitted && bulkInventorySession.batchId &&
          <SubmittedMessage />
        }
        <Panel>
          <Textarea
            id="notes"
            label="Notes"
            onChange={updateNotes}
            placeholder="Add Notes"
            resize={false}
            rows={3}
            value={changeNotes}
          />
          <div className="flex-container flex-end">
            { !submitted && !bulkInventorySession.isFetching && <Button id="clear-button" className="flex-1 fit-content" onClick={() => clearSession()}>Clear session</Button> }
            { !submitted && bulkInventorySession.isFetching && <Button id="clear-button" className="flex-1 fit-content" disabled>Clear session</Button> }
            { submitted && <Button id="clear-button" className="flex-1 fit-content" onClick={() => clearSession()} disabled>Clear session</Button> }

            { !submitted && !bulkInventorySession.isFetching && (totalChangeValues > 0) && bulkEventsLoaded() && <Button id="submit-button" className="flex-1 fit-content left-15" onClick={() => publishSession()}>Submit Changes</Button> }
            { !submitted && (bulkInventorySession.isFetching || (totalChangeValues === 0) || !bulkEventsLoaded()) && <Button id="submit-button" className="flex-1 fit-content left-15" disabled>Submit Changes</Button> }
            { submitted && !bulkInventorySession.batchId && <Button id="submit-button" className="flex-1 fit-content left-15" isLoading={true}>Submit Changes</Button> }

            { submitted && bulkInventorySession.batchId &&
              <Button id="submit-button" className="flex-1 fit-content left-15" disabled>Changes complete</Button>
            }
          </div>
        </Panel>

        <Panel>
          { bulkInventorySession.events.toReversed().map((event, index) =>
            <ModifyRow
              bulkValues={bulkChangeValues}
              setValue={setValue}
              actionId={bulkInventorySession.events.length - 1 - index}
              submitted={submitted}
              batchEvent={event}
            />
          )}
        </Panel>
      </SettingsPage>
    </>
  )
}

const mapStateToProps = state => ({
  bulkInventorySession: state.bulkInventorySessions,
  locations: state.locations,
});

const mapDispatchToProps = (dispatch) => ({
  postScanInventory: (payload, barcode, actionId, activeLocation) => dispatch(scanInventory(payload, barcode, actionId, activeLocation)),
  addAction: (barcode, actionType) => dispatch(addBulkPlaceholderAction(barcode)),
  clearSession: () => dispatch(clearBulkActions()),
  publishBulkRemove: (payload, actions, comments, activeLocation) => dispatch(publishBulkActions(payload, 'subtract', actions, comments, activeLocation)),  
  loadLocations: (payload) => dispatch(locationsIndex(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Remove);
