import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  BARCODE_TEMPLATE_CREATE_REQUESTED,
  BARCODE_TEMPLATE_CREATE_SUCCESS,
  BARCODE_TEMPLATE_CREATE_FAILURE,
  BARCODE_TEMPLATE_DELETE_REQUESTED,
  BARCODE_TEMPLATE_DELETE_SUCCESS,
  BARCODE_TEMPLATE_DELETE_FAILURE,
  BARCODE_TEMPLATE_INDEX_REQUESTED,
  BARCODE_TEMPLATE_INDEX_SUCCESS,
  BARCODE_TEMPLATE_INDEX_FAILURE,
  BARCODE_TEMPLATE_UPDATE_REQUESTED,
  BARCODE_TEMPLATE_UPDATE_SUCCESS,
  BARCODE_TEMPLATE_UPDATE_FAILURE,
  BARCODE_TEMPLATE_PREVIEW_REQUESTED,
  BARCODE_TEMPLATE_PREVIEW_SUCCESS,
  BARCODE_TEMPLATE_PREVIEW_FAILURE,
  GET_FUNGIBLES_REQUESTED,
  GET_FUNGIBLES_SUCCESS,
  GET_FUNGIBLES_FAILURE,
  GET_LINE_SETTINGS_REQUESTED,
  GET_LINE_SETTINGS_SUCCESS,
  GET_LINE_SETTINGS_FAILURE,
  BARCODE_TEMPLATES_UPGRADE_REQUESTED,
  BARCODE_TEMPLATES_UPGRADE_SUCCESS,
  BARCODE_TEMPLATES_UPGRADE_FAILURE,
} from '../types';

export const createBarcodeTemplateRequested = () => {
  return {
    type: BARCODE_TEMPLATE_CREATE_REQUESTED
  }
};

export const createBarcodeTemplateSuccess = (payload) => {
  return {
    type: BARCODE_TEMPLATE_CREATE_SUCCESS,
    payload: payload
  }
};

export const createBarcodeTemplateFailure = () => {
  return {
    type: BARCODE_TEMPLATE_CREATE_FAILURE
  }
};

export const deleteBarcodeTemplateRequested = () => {
  return {
    type: BARCODE_TEMPLATE_DELETE_REQUESTED
  }
};

export const deleteBarcodeTemplateSuccess = (payload) => {
  return {
    type: BARCODE_TEMPLATE_DELETE_SUCCESS,
    payload: payload
  }
};

export const deleteBarcodeTemplateFailure = () => {
  return {
    type: BARCODE_TEMPLATE_DELETE_FAILURE
  }
};

export const indexBarcodeTemplatesRequested = () => {
  return {
    type: BARCODE_TEMPLATE_INDEX_REQUESTED
  }
};

export const indexBarcodeTemplatesSuccess = (payload) => {
  return {
    type: BARCODE_TEMPLATE_INDEX_SUCCESS,
    payload: payload
  }
};

export const indexBarcodeTemplatesFailure = () => {
  return {
    type: BARCODE_TEMPLATE_INDEX_FAILURE
  }
};

export const updateBarcodeTemplateRequested = () => {
  return {
    type: BARCODE_TEMPLATE_UPDATE_REQUESTED
  }
};

export const updateBarcodeTemplateSuccess = (payload) => {
  return {
    type: BARCODE_TEMPLATE_UPDATE_SUCCESS,
    payload: payload
  }
};

export const updateBarcodeTemplateFailure = () => {
  return {
    type: BARCODE_TEMPLATE_UPDATE_FAILURE
  }
};


export const previewBarcodeTemplateRequested = () => {
  return {
    type: BARCODE_TEMPLATE_PREVIEW_REQUESTED
  }
};

export const previewBarcodeTemplateSuccess = (payload) => {
  return {
    type: BARCODE_TEMPLATE_PREVIEW_SUCCESS,
    payload: payload
  }
};

export const previewBarcodeTemplateFailure = () => {
  return {
    type: BARCODE_TEMPLATE_PREVIEW_FAILURE
  }
};

export const upgradeBarcodeTemplatesRequested = () => {
  return {
    type: BARCODE_TEMPLATES_UPGRADE_REQUESTED
  }
};

export const upgradeBarcodeTemplatesSuccess = (payload) => {
  return {
    type: BARCODE_TEMPLATES_UPGRADE_SUCCESS,
    payload: payload
  }
};

export const upgradeBarcodeTemplatesFailure = () => {
  return {
    type: BARCODE_TEMPLATES_UPGRADE_FAILURE
  }
};


export const getFungiblesRequested = () => {
  return {
    type: GET_FUNGIBLES_REQUESTED
  }
};

export const getFungiblesSuccess = (payload) => {
  return {
    type: GET_FUNGIBLES_SUCCESS,
    payload: payload
  }
};

export const getFungiblesFailure = () => {
  return {
    type: GET_FUNGIBLES_FAILURE
  }
};

export const getLineSettingsRequested = () => {
  return {
    type: GET_LINE_SETTINGS_REQUESTED
  }
};

export const getLineSettingsSuccess = (payload) => {
  return {
    type: GET_LINE_SETTINGS_SUCCESS,
    payload: payload
  }
};

export const getLineSettingsFailure = () => {
  return {
    type: GET_LINE_SETTINGS_FAILURE
  }
};


export const indexBarcodeTemplates = (signedPayload) => {
  return (dispatch) => {
    dispatch(indexBarcodeTemplatesRequested());

    let endPoint = Config.baseUrl + Config.barcodeTemplate + 's';
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(indexBarcodeTemplatesSuccess(response.data));
      });
  }
}

export const createBarcodeTemplate = (signedPayload, options) => {
  return (dispatch) => {
    dispatch(createBarcodeTemplateRequested());

    let endPoint = Config.baseUrl + Config.barcodeTemplate;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.post(endPoint, { options: options } , { headers: headers })
      .then((response) => {
        dispatch(createBarcodeTemplateSuccess(response.data));
      });
  }
}

export const updateBarcodeTemplate = (signedPayload, options) => {
  return (dispatch) => {
    dispatch(updateBarcodeTemplateRequested());

      let endPoint = Config.baseUrl + Config.barcodeTemplate + '/' + Config.update;
      let headers = Object.assign(Config.headers);
      headers.payload = signedPayload;

      return Axios.post(endPoint, { options: options } , { headers: headers })
        .then((response) => {
          dispatch(updateBarcodeTemplateSuccess(response.data));
        });
  }
}

export const previewBarcodeTemplate = (signedPayload, options, values) => {
  return (dispatch) => {
    dispatch(previewBarcodeTemplateRequested());

    let endPoint = Config.baseUrl + Config.barcode + Config.preview;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.post(endPoint, { options: options, values: values } , { headers: headers })
      .then((response) => {
        dispatch(previewBarcodeTemplateSuccess(response.data));
      })
      .catch((error) => {
        dispatch(previewBarcodeTemplateFailure());
      });
  }
}

export const deleteBarcodeTemplate = (signedPayload, uuid) => {
  return (dispatch) => {
    dispatch(deleteBarcodeTemplateRequested());

    let endPoint = Config.baseUrl + Config.barcodeTemplate + '/' + Config.delete;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.post(endPoint, { uuid: uuid } , { headers: headers })
      .then((response) => {
        dispatch(deleteBarcodeTemplateSuccess(response.data));
      });
  }
}

export const getTemplateFungibles = (signedPayload, uuid) => {
  return (dispatch) => {
    dispatch(getFungiblesRequested());

    let endPoint = Config.baseUrl + Config.fungibles + '/' + uuid;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;
    
    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(getFungiblesSuccess(response.data));
      });
  }
}

export const getTemplateLineSettings = (signedPayload, uuid) => {
  return (dispatch) => {
    dispatch(getLineSettingsRequested());

    let endPoint = Config.baseUrl + Config.lineSettings + '/' + uuid;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(getLineSettingsSuccess(response.data));
      });
  }
}

export const upgradeTemplates = (signedPayload) => {
  return (dispatch) => {
    dispatch(upgradeBarcodeTemplatesRequested());

    let endPoint = Config.baseUrl + Config.barcodeTemplate + '/' + Config.upgrade;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(upgradeBarcodeTemplatesSuccess(response.data));
      });
  }
}
