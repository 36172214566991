import { Text, ProgressCircle } from '@bigcommerce/big-design';

const LoadingSpinner = ({ statusText, size }) => {
  return(
    <>
      <ProgressCircle size={size} />
      <Text>{ statusText || 'Loading Preview' }</Text>
    </>
  )
}

export default LoadingSpinner;
