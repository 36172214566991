import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { updateSelectedItems, findBarcodeValueImage } from '../../redux/actions/products';
import { downloadBarcodeImage } from '../../redux/actions/barcodes';

import LoadingSpinner from '../partials/LoadingSpinner';
import { Text, Button, Checkbox, H4 } from '@bigcommerce/big-design';

const ItemLine = ({ payload, hasVariants, sku, upc, brand, itemId, imageUrl, inventoryTracking, options, name, binPickingNumber, selectedProductList, setSelectedProductList, products, downloadBarcode, loadBarcodeImage, downloadUrl, type, rowId, quantity, price, setDownloadSubmitted, settings }) => {
  const [localDownloadSubmitted, setLocalDownloadSubmitted] = useState(false);

  let section = !!options ? selectedProductList.variations : selectedProductList.products;
  let inList = Object.keys(section).indexOf(String(itemId)) > -1;

  const switchSelection = () => {
    if (inList) {
      delete section[itemId];
    } else {
      section[itemId] = {
        sku: sku,
        upc: upc,
        brand: brand,
        imageUrl: imageUrl,
        options: options,
        name: name,
        hasBarcode: (products.searchedBarcodes.indexOf(sku) > -1),
        quantity: quantity,
        price: price,
        binPickingNumber: binPickingNumber
      };
    }

    setSelectedProductList({ ...selectedProductList });
  };

  const downloadImage = (payload, itemId, sku) => {
    setDownloadSubmitted(true);
    setLocalDownloadSubmitted(true);

    if(!settings.barcode_image_generation_field || settings.barcode_image_generation_field === 'sku') {
      downloadBarcode(payload, itemId, sku);
    }

    if(settings.barcode_image_generation_field === 'upc') {
      downloadBarcode(payload, itemId, upc);
    }
  }

  const findBarcodeImage = () => {
    if(!!upc) {
      console.log(itemId);
      console.log(upc);
    }

    if(!settings.barcode_image_generation_field || settings.barcode_image_generation_field === 'sku') {
      loadBarcodeImage(payload, itemId, sku);
    }
    
    if(settings.barcode_image_generation_field === 'upc') {
      loadBarcodeImage(payload, itemId, upc);
    }
  };

  useEffect(() => {
    if(!settings.barcode_image_generation_field || settings.barcode_image_generation_field === 'sku') {
      if(sku && (products.searchedBarcodes.indexOf(sku) == -1) && (products.searchingBarcodes.indexOf(sku) == -1) && (products.missingBarcodes.indexOf(sku) == -1)) {
        findBarcodeImage();
      }
    }

    if(settings.barcode_image_generation_field === 'upc') {
      if(upc && (products.searchedBarcodes.indexOf(upc) == -1) && (products.searchingBarcodes.indexOf(upc) == -1) && (products.missingBarcodes.indexOf(upc) == -1)) {
        findBarcodeImage();
      }
    }
  }, [findBarcodeImage]);

  useEffect(() => {
    if(downloadUrl) { setLocalDownloadSubmitted(false); }
  }, [downloadUrl]);

  return(
    <div className="has-hover sku-line flex-container">
      <div className={`flex-1 ${type === 'variant' && 'left-15'}`}>
        <div className="flex-container">
          { ((inventoryTracking === 'product' && type === 'product') || (inventoryTracking === 'none' && !hasVariants) || (type === 'variant')) &&
            <Checkbox
              id={itemId}
              checked={inList}
              onChange={() => switchSelection()}
            />
          }
          <div className="left-15">
            { imageUrl && <img src={imageUrl} /> }
            { !imageUrl && <Text>No image</Text>}
          </div>
        </div>
      </div>
      <div className="flex-3">
        <div className="flex-container">
          <H4>{name}</H4>
          { (!settings.barcode_image_generation_field || settings.barcode_image_generation_field === 'sku') &&
            <>
              { sku && <Text color="secondary60"> - {sku}</Text> }
              { !sku && <Text color="danger40"> - No SKU found, barcode will not generate.</Text> }
            </>
          }

          { (settings.barcode_image_generation_field === 'upc') &&
            <>
              { upc && <Text color="secondary60"> - {upc}</Text> }
              { !upc && <Text color="danger40"> - No UPC/EAN found, barcode will not generate.</Text> }
            </>
          }
        </div>
        <ul>
          { options && options.map(option =>
              <li>
                <strong>{ option.option_display_name }</strong>: { option.label }
              </li>
            )
          }
          <li>Default Location Stock: { quantity }</li>
          { brand &&
            <li>Brand: { brand }</li>
          }
          { !options &&
            <Text color="warning40">
              Base Product
            </Text>
          }
          { (inventoryTracking === 'none') &&
            <Text color="danger40">Inventory not tracked</Text>
          }
        </ul>

      </div>
      <div className="flex-1">
        { (!settings.barcode_image_generation_field || settings.barcode_image_generation_field === 'sku') &&
          <>
            { (products.searchingBarcodes.indexOf(sku) > -1) &&
              <LoadingSpinner size="xxSmall" statusText="Finding barcode..." />
            }

            { (products.searchedBarcodes.indexOf(sku) > -1) &&
              <button class="btn-barcode" onClick={!localDownloadSubmitted ? () => downloadImage(payload, itemId, sku) : () => null}>
                { !localDownloadSubmitted && <svg width="1.25rem" height="1.25rem" viewBox="0 0 32 32" id="svg5" version="1.1">
                  <defs id="defs2"/>
                  <g id="layer1" transform="translate(-108,-100)">
                    <path d="m 111,106 a 1.0001,1.0001 0 0 0 -1,1 v 3 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" id="path11698" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 134,106 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -3 a 1.0001,1.0001 0 0 0 -1,-1 z" id="path11700" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 137,121 a 1,1 0 0 0 -1,1 v 2 h -2 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3 a 1.0001,1.0001 0 0 0 1,-1 v -3 a 1,1 0 0 0 -1,-1 z" id="path11702" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 111,121 a 1,1 0 0 0 -1,1 v 3 a 1.0001,1.0001 0 0 0 1,1 h 3 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 h -2 v -2 a 1,1 0 0 0 -1,-1 z" id="path11704" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 115,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11706" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 118,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11708" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 121,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11710" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 124,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11712" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 127,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11714" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 130,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11716" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 133,110 a 1,1 0 0 0 -1,1 v 5.20703 1.31445 V 121 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 117.52148 116.20703 111 a 1,1 0 0 0 -1,-1 z" id="path11720" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                  </g>
                </svg> }

                { localDownloadSubmitted && <LoadingSpinner size="xxSmall" statusText=" " /> }
              </button>
            }
            { (products.searchedBarcodes.indexOf(sku) > -1) &&
              <span className="left-15">
                <Link to={`/barcode_actions/${sku}/${brand}`}>
                  <Button>Preview Labels</Button>
                </Link>
              </span>
            }
          </>
        }

        { (settings.barcode_image_generation_field === 'upc') &&
          <>
            { (products.searchingBarcodes.indexOf(upc) > -1) &&
              <LoadingSpinner size="xxSmall" statusText="Finding barcode..." />
            }

            { (products.searchedBarcodes.indexOf(upc) > -1) &&
              <button class="btn-barcode" onClick={!localDownloadSubmitted ? () => downloadImage(payload, itemId, upc) : () => null}>
                { !localDownloadSubmitted && <svg width="1.25rem" height="1.25rem" viewBox="0 0 32 32" id="svg5" version="1.1">
                  <defs id="defs2"/>
                  <g id="layer1" transform="translate(-108,-100)">
                    <path d="m 111,106 a 1.0001,1.0001 0 0 0 -1,1 v 3 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" id="path11698" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 134,106 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -3 a 1.0001,1.0001 0 0 0 -1,-1 z" id="path11700" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 137,121 a 1,1 0 0 0 -1,1 v 2 h -2 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3 a 1.0001,1.0001 0 0 0 1,-1 v -3 a 1,1 0 0 0 -1,-1 z" id="path11702" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 111,121 a 1,1 0 0 0 -1,1 v 3 a 1.0001,1.0001 0 0 0 1,1 h 3 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 h -2 v -2 a 1,1 0 0 0 -1,-1 z" id="path11704" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 115,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11706" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 118,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11708" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 121,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11710" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 124,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11712" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 127,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11714" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 130,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11716" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 133,110 a 1,1 0 0 0 -1,1 v 5.20703 1.31445 V 121 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 117.52148 116.20703 111 a 1,1 0 0 0 -1,-1 z" id="path11720" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                  </g>
                </svg> }

                { localDownloadSubmitted && <LoadingSpinner size="xxSmall" statusText=" " /> }
              </button>
            }
            { (products.searchedBarcodes.indexOf(upc) > -1) &&
              <span className="left-15">
                <Link to={`/barcode_actions/${upc}/${brand}`}>
                  <Button>Preview Labels</Button>
                </Link>
              </span>
            }
          </>
        }
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  selectedProductList: state.products.selectedProductList,
  products: state.products,
  downloadUrl: state.barcodes.downloadUrl,
  settings: state.settings.settings
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedProductList: (items) => dispatch(updateSelectedItems(items)),
  downloadBarcode: (payload, itemId, barcodeValue) => dispatch(downloadBarcodeImage(payload, itemId, barcodeValue)),
  loadBarcodeImage: (payload, itemId, barcodeValue) => dispatch(findBarcodeValueImage(payload, itemId, barcodeValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemLine);
