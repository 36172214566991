import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { generateBarcodes } from '../../redux/actions/barcodes';

import { Modal, Text, H2, Input } from '@bigcommerce/big-design';

const ConfirmOrderUpdateDialog = ({ setDisplayConfirmOrderUpdate, focusScanArea, dialogLineItemId, updateItemQuantity, payload, lineItems, orderId }) => {
  const [lineItem, setLineItem] = useState();
  const [updateQuantity, setUpdateQuantity] = useState();

  useEffect(() => {
    let lineItem = lineItems.find(lineItem => lineItem.line_item_id === dialogLineItemId)
    setUpdateQuantity(lineItem?.quantity);
    setLineItem(lineItem);
  }, [dialogLineItemId]);

  const close = () => {
    setDisplayConfirmOrderUpdate(false);
    focusScanArea();
  }

  const confirm = () => {
    updateItemQuantity(payload, orderId, lineItem.line_item_id, updateQuantity);
    close();
  }

  const validateQuantity = (event) => {
    let value = event.target.value;
    setUpdateQuantity(value.replace(/\D/g,''));
  }

  return(
    <div className="main-overlay main-overlay__warning">
      <Modal
        actions={[
          { 
            text: 'Confirm', 
            onClick: () => confirm()
          },
          {
            text: 'Cancel',
            onClick: () => close()
          }
        ]}
        closeOnClickOutside={true}
        closeOnEscKey={true}
        backdrop={false}
        isOpen={true}
        onClose={() => close()}
        variant="dialog"
      >
        <br/>
        <H2 color="warning50">Confirm Update</H2>
        <div>
          <ul>
            <li><Text><b>Product</b>: { lineItem?.name }</Text></li>
            { lineItem?.options && lineItem?.options.map(option =>
                <li>
                  <Text><b>{ option.display_name }</b>: { option.display_value }</Text>
                </li>
              )
            }
            
            <li><Text><b>Current Quantity</b>: { lineItem?.quantity }</Text></li>
          </ul>
          
          <Input
            required
            label="Update Quantity"
            onChange={validateQuantity}
            placeholder="Update Quantity"
            type="text"
            value={updateQuantity}
          />

          <div className="top-15 left-15">
            Confirm order update. Changing order details should be confirmed with customers and may cause inventory or billing changes.
          </div>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => ({
  lineItems: state.pickOrder.lineItems,
});

export default connect(mapStateToProps, null)(ConfirmOrderUpdateDialog);
