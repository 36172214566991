import {
  SETTINGS_REQUESTED,
  SETTINGS_SUCCESS,
  SETTINGS_FAILURE,
  SETTINGS_UPDATE_REQUESTED,
  SETTINGS_UPDATE_SUCCESS,
  SETTINGS_UPDATE_FAILURE,
} from '../types';

const INITIAL_STATE = {
  settings: {},
  isFetching: false,
  status: 'waiting',
  errors: null
};

const settings = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case SETTINGS_REQUESTED:
      return Object.assign({}, state, {
        settings: {},
        isFetching: true,
        status: 'requested',
        errors: null
      });

    case SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        settings: action.payload.settings || {},
        isFetching: false,
        status: 'success',
        errors: null
      });

    case SETTINGS_FAILURE:
      return Object.assign({}, state, {
        settings: {},
        isFetching: false,
        status: 'error',
        errors: action.error
      });

    case SETTINGS_UPDATE_REQUESTED:
      return Object.assign({}, state, {
        settings: {},
        isFetching: false,
        status: 'updating',
        errors: null
      });

    case SETTINGS_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        settings: action.payload.settings || {},
        isFetching: false,
        status: 'success',
        errors: null
      });

    case SETTINGS_UPDATE_FAILURE:
      return Object.assign({}, state, {
        settings: {},
        isFetching: false,
        status: 'error',
        errors: action.error
      });

    default:
      return state;
  }
}

export default settings;