import { Modal, Text } from '@bigcommerce/big-design';

const DeleteDialog = ({ showDeleteDialog, setShowDeleteDialog, removeTemplate, activeUuid }) => {

  return (
    <Modal
      actions={[
        {
          text: 'Cancel',
          variant: 'subtle',
          onClick: () => setShowDeleteDialog(false),
        },
        { text: 'Delete Template', onClick: () => removeTemplate(activeUuid) },
      ]}
      closeOnClickOutside={true}
      closeOnEscKey={true}
      header="Delete Template"
      isOpen={showDeleteDialog}
      onClose={() => setShowDeleteDialog(false)}
    >
      <Text>
        Are you sure you want to delete this template?
      </Text>
    </Modal>
  )
}

export default DeleteDialog;