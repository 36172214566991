import {
  READ_ORDER_REQUESTED,
  READ_ORDER_SUCCESS,
  READ_ORDER_FAILURE,
  UPDATE_ORDER_FULFILLMENT_STATUS_REQUESTED,
  UPDATE_ORDER_FULFILLMENT_STATUS_SUCCESS,
  UPDATE_ORDER_FULFILLMENT_STATUS_FAILURE,
  INDEX_FULFILLMENT_STATUSES_REQUESTED,
  INDEX_FULFILLMENT_STATUSES_SUCCESS,
  INDEX_FULFILLMENT_STATUSES_FAILURE,
  CLEAR_ORDER,
  SET_CHECKED_LIST,
  SET_UNPICKABLE_LIST,
  CLEAR_ORDER_ACTIONS,
  UPDATE_LINE_ITEM_QUANTITY_REQUESTED,
  UPDATE_LINE_ITEM_QUANTITY_SUCCESS,
  UPDATE_LINE_ITEM_QUANTITY_FAILURE,
} from '../types';


const INITIAL_STATE = {
  isFetching: false,
  lineItems: [],
  checkedItems: [],
  unpickableItems: [],
  status: 'waiting',
  orderId: null,
  orderStatuses: [],
  fulfillmentStatus: null,
  alertId: null,
  orderFulfillmentFetching: false,
  orderFulfillmentMessage: null,
  orderSetQuantityFetching: false,
  orderSetQuantityMessage: null,
  errors: null
};


const pickOrder = (state = INITIAL_STATE, action) => {
  const newState = { ...state };

  switch(action.type) {
    case READ_ORDER_REQUESTED:
      newState.isFetching = true;
      newState.status = 'requesting';
      newState.lineItems = [];
      newState.checkedItems = [];
      newState.unpickableItems = [];
      return newState;

    case READ_ORDER_SUCCESS:
      newState.isFetching = false;
      newState.status = 'success';
      newState.orderId = action.payload.order.order_id;
      newState.lineItems = action.payload.order.line_items;
      newState.fulfillmentStatus = action.payload.order.status_id;
      return newState;

    case READ_ORDER_FAILURE:
      newState.isFetching = false;
      newState.errors = action.error;
      newState.status = 'failure';
      return newState;

    case UPDATE_ORDER_FULFILLMENT_STATUS_REQUESTED:
      newState.isFetching = true;
      newState.status = 'requesting';
      newState.alertId = null;
      newState.orderFulfillmentMessage = null;
      return newState;

    case UPDATE_ORDER_FULFILLMENT_STATUS_SUCCESS:
      newState.isFetching = false;
      newState.status = 'waiting';
      newState.alertId = action.payload.id;
      newState.orderFulfillmentMessage = "Order Status Updated";
      return newState;

    case INDEX_FULFILLMENT_STATUSES_REQUESTED:
      newState.orderStatuses = [];
      newState.orderFulfillmentFetching = true;
      return newState;

    case INDEX_FULFILLMENT_STATUSES_SUCCESS:
      newState.orderStatuses = action.payload.statuses;
      newState.orderFulfillmentFetching = false;
      return newState;

    case SET_CHECKED_LIST:
      newState.checkedItems = action.checkedList;
      return newState;

    case SET_UNPICKABLE_LIST:
      newState.unpickableItems = action.unpickableList;
      return newState;

    case UPDATE_LINE_ITEM_QUANTITY_REQUESTED:
      newState.orderSetQuantityFetching = true;
      newState.orderSetQuantityMessage = null;
      return newState;

    case UPDATE_LINE_ITEM_QUANTITY_SUCCESS:
      newState.orderSetQuantityFetching = false;
      newState.orderSetQuantityMessage = "Line Item Quantity Updated";

      let replacementItem = state.lineItems.find(item => item.line_item_id === action.payload.line_item_id);
      let replacementIndex = state.lineItems.findIndex(item => item.line_item_id === action.payload.line_item_id);

      replacementItem.quantity = action.payload.quantity;
      newState.lineItems[replacementIndex] = replacementItem;

      newState.unpickableItems.splice(state.unpickableItems.indexOf(action.payload.line_item_id), 1);

      return newState;

    case UPDATE_LINE_ITEM_QUANTITY_FAILURE:
      newState.orderSetQuantityFetching = false;

      return newState;

    case CLEAR_ORDER:
      newState.isFetching = false;
      newState.lineItems = [];
      newState.checkedItems = [];
      newState.unpickableItems = [];
      newState.status = 'waiting';
      newState.orderId = null;
      newState.fulfillmentStatus = null;
      newState.errors = null;
      newState.orderFulfillmentMessage = null;
      return newState;

    case CLEAR_ORDER_ACTIONS:
      newState.orderFulfillmentMessage = null;
      return newState;

    default:
      return state;
  }
}

export default pickOrder;