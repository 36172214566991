import {
  CREATE_BARCODES_REQUESTED,
  CREATE_BARCODES_SUCCESS,
  CREATE_BARCODES_FAILURE,
  CREATE_ALL_BARCODES_REQUESTED,
  CREATE_ALL_BARCODES_SUCCESS,
  CREATE_ALL_BARCODES_FAILURE,
  REFRESH_BATCHES_REQUESTED,
  REFRESH_BATCHES_SUCCESS,
  REFRESH_BATCHES_FAILURE,
  DOWNLOAD_BARCODE_IMAGE_REQUESTED,
  DOWNLOAD_BARCODE_IMAGE_SUCCESS,
  DOWNLOAD_BARCODE_IMAGE_FAILURE,
} from '../types';

const INITIAL_STATE = {
  isFetching: false,
  status: 'waiting',
  generateActions: [],
  downloadUrl: '',
  errors: null
};

const barcodes = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case CREATE_BARCODES_REQUESTED:
    case CREATE_ALL_BARCODES_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        downloadUrl: null,
        status: 'requesting'
      });

    case CREATE_BARCODES_SUCCESS:
    case CREATE_ALL_BARCODES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'success',
        generateActions: [...state.generateActions, action.payload.action],
        downloadUrl: null,
        errors: null
      });

    case CREATE_BARCODES_FAILURE:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'waiting',
        downloadUrl: null,
        errors: 'error here'
      });

    case CREATE_ALL_BARCODES_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        downloadUrl: null,
        status: 'requesting'
      });

    // case CREATE_ALL_BARCODES_SUCCESS:
    //   return Object.assign({}, state, {
    //     isFetching: false,
    //     downloadUrl: null,
    //     status: 'success',
    //     errors: null
    //   });

    case CREATE_ALL_BARCODES_FAILURE:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'waiting',
        downloadUrl: null,
        errors: 'error here'
      });

    case REFRESH_BATCHES_SUCCESS:
      return Object.assign({}, state, {
        downloadUrl: null,
        generateActions: action.payload.generate_actions
      });

    case DOWNLOAD_BARCODE_IMAGE_SUCCESS:
      return Object.assign({}, state, {
        downloadUrl: action.payload.image_url
      });

    default:
      return state;
  }
}

export default barcodes;
