import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import PageHeader from '../partials/PageHeader';
import EmptyResults from './EmptyResults';
import LoadingPlaceholder from '../partials/LoadingPlaceholder';
import SettingsPage from '../partials/SettingsPage';

import { Text, H3, H4, Button, Panel, Table, Small } from '@bigcommerce/big-design';
import { getBatchResults } from '../../redux/actions/batchInventoryResults';
import { locationsIndex } from '../../redux/actions/locations';

import { FileDownloadIcon, RestoreIcon } from '@bigcommerce/big-design-icons';
import { ErrorIcon } from '@bigcommerce/big-design-icons';

const BatchUpdateResults = ({ payload, batchInventoryResults, loadBatchResults, locations, loadLocations }) => {
  useEffect(() => {
    loadBatchResults(payload);
    loadLocations(payload);
  }, []);

  const downloadActions = (result) => {
    let rows = [['Ticket ID', 'Date', 'Action Type', 'Comments', 'Location', 'SKU', 'UPC/EAN', 'Quantity', 'Result Code']];

    result.batch_body.items.map (request_item => {
      rows.push([result.ticket_id, result.date_time, result.action_type, result.comments, locations.locations.find(location => location.id === request_item.location_id).label, request_item.sku, request_item.upc, request_item.quantity, (result.transaction_status || 200)])
    });

    let csvContent = "data:text/csv;charset=utf-8," 
        + rows.map(e => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  const description = () => {
    return(
      <>
        <Text>The results for batch inventory adds, updates and reductions appear here. Changes made from live modifications are not saved here and would need to be downloaded from the "Live Modify" session.</Text>
        <Text><strong>This data is only stored for 2 weeks.</strong> Click the "Download" button to download the session results.</Text>
        <Small>Have questions or need some help? Contact us at <a href="mailto:support@athousandapps.com">support@athousandapps.com</a></Small>
      </>
    )
  }

  const tableItems = (result) => {
    let items = [];

    items.push({ property: 'Ticket', value: result.ticket_id });
    items.push({ property: 'Type', value: result.action_type });
    items.push({ property: 'Notes', value: result.comments });
    items.push({ property: 'Date', value: result.date_time });
    if (result.transaction_status) {
      items.push({ property: 'Error Message', value: result.transaction_title });
    }

    return items;
  }

  const tableSkus = (result) => {
    let items = [];

    result.batch_body.items.map (request_item => {
      items.push({ location: locations.locations.find(location => location.id === request_item.location_id).label, sku: request_item.sku, upc: request_item.upc, quantity: request_item.quantity });
    })

    return items;
  }

  if (locations.status === 'success' && batchInventoryResults.status === 'success' && (batchInventoryResults.batchResults.length > 0)) {
    return(
      <>
        <PageHeader stripeBackground='#124b5f' helpLink="bigcommerce/view-batch-changes/" />
        <SettingsPage title="Batch Update Results" description={description()}>
          <Panel>
            <H3>Refresh Batch Results</H3>
            <Button iconRight={<RestoreIcon />} onClick={() => loadBatchResults(payload)}>Refresh Batches</Button>
            <hr/>
            { batchInventoryResults.batchResults.map(result =>
              <>
                <H4>Batch Details</H4>
                <div
                  className={ (result?.transaction_status && result?.transaction_title) ? 'error-table' : 'success-table' }
                >
                  <Table
                    columns={[
                      { header: 'Detail', hash: 'property', render: ({ property }) => 
                        property === 'Error Message' ? (
                          <>
                            <Text color="danger"><ErrorIcon color="danger" />{property}</Text>
                          </>
                        ) : (
                          <Text>{property}</Text>
                        )
                      },
                      { header: 'Value', hash: 'value', render: ({ value }) => value }
                    ]}
                    items={tableItems(result)}
                  />
                  <Table
                    columns={[
                      { header: 'Location', hash: 'location', render: ({ location }) => location },
                      { header: 'SKU', hash: 'sku', render: ({ sku }) => sku },
                      { header: 'UPC/EAN', hash: 'upc', render: ({ upc }) => upc },
                      { header: 'Quantity', hash: 'quantity', render: ({ quantity }) => quantity }
                    ]}
                    items={tableSkus(result)}
                  />

                  <Button iconRight={<FileDownloadIcon />} className="left-15 bottom-15" onClick={() => downloadActions(result)}>Download</Button>
                </div>
                <hr/>
              </>
            )}
          </Panel>
        </SettingsPage>
      </>
    )
  }

  if (batchInventoryResults.status === 'success' && (batchInventoryResults.batchResults.length === 0)) {
    return(
      <>
        <PageHeader stripeBackground='#124b5f' helpLink="bigcommerce/view-batch-changes/" />
        <EmptyResults 
          payload={payload}
        />
      </>
    )
  }

  return(
    <>
      <PageHeader stripeBackground='#124b5f' helpLink="bigcommerce/view-batch-changes/" />
      <SettingsPage title="Batch Update Results" description={description()}>
        <LoadingPlaceholder title="Batch Update Results" />
      </SettingsPage>
    </>
  )
}

const mapStateToProps = state => ({
  batchInventoryResults: state.batchInventoryResults,
  locations: state.locations,
});

const mapDispatchToProps = (dispatch) => ({
  loadBatchResults: (payload) => dispatch(getBatchResults(payload)),
  loadLocations: (payload) => dispatch(locationsIndex(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BatchUpdateResults);
