import { Link } from "react-router-dom";

import { Button, Panel, Text, H2 } from '@bigcommerce/big-design';

const FeatureCard = ({ title, description, buttonText, linkUrl, image, showImage, stripeBackground }) => {
  return(
    <Link to={linkUrl}>
      <Panel className="full-width feature-card-margins">
        <Text>
          <div className="flex-container feature-card-content">
            <div className="flex-4" style={{ 'border-bottom': `2px solid ${stripeBackground}` }}>
              <H2>{title}</H2>
              <Text>{description}</Text>
               <Button actionType="normal" isLoading={false} variant="primary">{buttonText}</Button>
            </div>
            <div className="flex-1">
              <div className="text-center">
                {image}
              </div>
            </div>
          </div>
        </Text>
      </Panel>
    </Link>
  )
}

export default FeatureCard;
