import { connect } from 'react-redux';
import { getBatchResults } from '../../redux/actions/batchInventoryResults';

import { Text, Button, Panel, Box, H4, H3 } from '@bigcommerce/big-design';
import SettingsPage from '../partials/SettingsPage';

import { RestoreIcon } from '@bigcommerce/big-design-icons';

const EmptyResults = ({ payload, loadBatchResults }) => {
  const description = () => {
    return(
      <>
        <Text>The results for batch inventory adds, updates and reductions appear here. Changes made from live modifications are not saved here and would need to be downloaded from the "Live Modify" session.</Text>
        <Text><strong>This data is only stored for 2 weeks.</strong> Click the "Download" button to download the session results.</Text>
      </>
    )
  }

  return(
    <SettingsPage title="Batch Update Results" description={description()}>
      <Panel>
        <H3>Refresh Batch Results</H3>
        <Button iconRight={<RestoreIcon />} onClick={() => loadBatchResults(payload)}>Refresh Batches</Button>
        <hr/>
        <Box>
          <H4>You have no stored batch changes</H4>
          <Text>When additions, updates or inventory reductions are performed, results of those actions will appear here. Live modifications are not saved. Information is saved for 2 weeks after a batch change is made.</Text>
        </Box>
      </Panel>
    </SettingsPage>
  )
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch) => ({
  loadBatchResults: (payload) => dispatch(getBatchResults(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmptyResults); 