import {
  LOCATIONS_INDEX_REQUESTED,
  LOCATIONS_INDEX_SUCCESS,
  LOCATIONS_INDEX_FAILURE,
} from '../types';

const INITIAL_STATE = {
  isFetching: false,
  status: 'waiting',
  locations: [],
  errors: null
};


const locations = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case LOCATIONS_INDEX_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        locations: [],
        status: 'requesting',
        errors: null,
      });

    case LOCATIONS_INDEX_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'success',
        locations: action.payload.locations,
        errors: null
      });

    case LOCATIONS_INDEX_FAILURE:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'waiting',
        locations: [],
        errors: 'error here'
      });

    default:
      return state;
  }
}

export default locations;