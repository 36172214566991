import {
  STORE_FIND_OR_CREATE_REQUESTED,
  STORE_FIND_OR_CREATE_SUCCESS,
  STORE_FIND_OR_CREATE_FAILURE,
  STORE_SETTINGS_FIND_REQUESTED,
  STORE_SETTINGS_FIND_SUCCESS,
  STORE_SETTINGS_FIND_FAILURE,
} from '../types';

const INITIAL_STATE = {
  store: null,
  settings: {},
  isFetching: false,
  status: 'waiting',
  errors: null
};

const stores = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case STORE_FIND_OR_CREATE_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        status: 'requesting'
      });

    case STORE_FIND_OR_CREATE_SUCCESS:
      return Object.assign({}, state, {
        store: action.payload.store,
        isFetching: false,
        status: 'success',
        errors: null
      });

    case STORE_FIND_OR_CREATE_FAILURE:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'waiting',
        errors: 'error here'
      });

    case STORE_SETTINGS_FIND_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
      });

    case STORE_SETTINGS_FIND_SUCCESS:
      return Object.assign({}, state, {
        settings: action.payload.store_information,
        isFetching: false,
        errors: null
      });

    case STORE_SETTINGS_FIND_FAILURE:
      return Object.assign({}, null, {
        errors: 'error here'
      });

    default:
      return state;
  }
}

export default stores;
