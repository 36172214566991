import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  STORE_FIND_OR_CREATE_REQUESTED,
  STORE_FIND_OR_CREATE_SUCCESS,
  STORE_FIND_OR_CREATE_FAILURE,
  STORE_SETTINGS_FIND_REQUESTED,
  STORE_SETTINGS_FIND_SUCCESS,
  STORE_SETTINGS_FIND_FAILURE,
} from '../types';

export const findOrCreateRequested = () => {
  return {
    type: STORE_FIND_OR_CREATE_REQUESTED
  }
};

export const findOrCreateSuccess = (store) => {
  return {
    type: STORE_FIND_OR_CREATE_SUCCESS,
    payload: store
  }
};

export const findOrCreateFailure = () => {
  return {
    type: STORE_FIND_OR_CREATE_FAILURE
  }
};


export const findSettingsRequested = () => {
  return {
    type: STORE_SETTINGS_FIND_REQUESTED
  }
};

export const findSettingsSuccess = (storeSettings) => {
  return {
    type: STORE_SETTINGS_FIND_SUCCESS,
    payload: storeSettings
  }
};

export const findOSettingsailure = () => {
  return {
    type: STORE_SETTINGS_FIND_FAILURE
  }
};

export const findOrCreate = (signedPayload) => {
  return (dispatch) => {
    dispatch(findOrCreateRequested());

    let endPoint = Config.baseUrl + Config.store;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.post(endPoint, {}, { headers: headers })
      .then((response) => {
        dispatch(findOrCreateSuccess(response.data));
      })
  }
}

export const findSettings = (signedPayload) => {
  return (dispatch) => {
    dispatch(findSettingsRequested());

    let endPoint = Config.baseUrl + Config.store + Config.settings;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.post(endPoint, {}, { headers: headers })
      .then((response) => {
        dispatch(findSettingsSuccess(response.data));
      })

  }
}
