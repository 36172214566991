import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  PRINT_CREATE_REQUESTED,
  PRINT_CREATE_SUCCESS,
  PRINT_CREATE_FAILURE,
} from '../types';

export const printCreateRequested = () => {
  return {
    type: PRINT_CREATE_REQUESTED
  }
};

export const printCreateSuccess = (downloadUrl) => {
  return {
    type: PRINT_CREATE_SUCCESS,
    payload: downloadUrl
  }
};

export const printCreateFailure = () => {
  return {
    type: PRINT_CREATE_FAILURE
  }
};


export const requestPrint = (signedPayload, template, printCounts, sortOrder) => {
  return (dispatch) => {
    dispatch(printCreateRequested());

    let endPoint = Config.baseUrl + Config.print;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.post(endPoint, { template_uuid: template, counts: printCounts, order: sortOrder } , { headers: headers })
      .then((response) => {
        dispatch(printCreateSuccess(response.data));
      })
      .catch((error) => {
        dispatch(printCreateFailure());
      });
  }
}
