import SlSkeleton from '@shoelace-style/shoelace/dist/react/skeleton';
import { Panel } from '@bigcommerce/big-design';

const css = `
  .skeleton-paragraphs sl-skeleton {
    margin-bottom: 1rem;
  }

  .skeleton-paragraphs sl-skeleton:nth-child(2) {
    width: 95%;
  }

  .skeleton-paragraphs sl-skeleton:nth-child(4) {
    width: 90%;
  }

  .skeleton-paragraphs sl-skeleton:last-child {
    width: 50%;
  }
`;


const LoadingPlaceholder = () => {
  return(
    <Panel>
      <div className="skeleton-paragraphs">
        <SlSkeleton effect="sheen" />
        <SlSkeleton effect="sheen" />
        <SlSkeleton effect="sheen" />
        <SlSkeleton effect="sheen" />
        <SlSkeleton effect="sheen" />
      </div>

      <style>{css}</style>
    </Panel>
  )
}

export default LoadingPlaceholder;
