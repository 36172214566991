import { Text, Dropdown, Button } from '@bigcommerce/big-design';
import { ArrowDropDownIcon } from '@bigcommerce/big-design-icons';

    // {
    //   name: '',
    //   page_width: ,
    //   page_length: ,
    //   page_x_margin: ,
    //   page_y_margin: ,
    //   rows: ,
    //   columns: ,
    //   label_x_padding: ,
    //   label_y_padding: ,
    //   label_width: ,
    //   label_length: ,
    //   size_measurement: 
    // }

const PresetSection = ({ 
    setTemplateForm, 
    templateForm,
    setLength,
    setWidth,
    setPageWidth,
    setPageLength,
    setPageXMargin,
    setPageYMargin,
    setPageRows,
    setPageColumns,
    setPageLabelXPadding,
    setPageLabelYPadding,
    setSizeMeasurement,
    handlePreview
  }) => {
  const presets = [
    {
      name: 'US Letter Page',
      page_width: 8.5,
      page_length: 11,
      page_x_margin: 0.25,
      page_y_margin: 0.2,
      rows: null,
      columns: null,
      label_x_padding: null,
      label_y_padding: null,
      label_width: null,
      label_length: null,
      size_measurement: 'in'
    },
    {
      name: 'A4 Page',
      page_width: 210,
      page_length: 297,
      page_x_margin: 5,
      page_y_margin: 3.4,
      rows: null,
      columns: null,
      label_x_padding: null,
      label_y_padding: null,
      label_width: null,
      label_length: null,
      size_measurement: 'mm'
    },
    {
      name: 'Avery 18294 (US Letter, 60 count, 2/3" x 1-3/4")',
      page_width: 8.5,
      page_length: 11,
      page_x_margin: 0.252,
      page_y_margin: 0.4,
      rows: 15,
      columns: 4,
      label_x_padding: 0.3,
      label_y_padding: 0.0,
      label_width: 1.75,
      label_length: 0.666666,
      size_measurement: "in"
    },
    {
      name: 'Avery 5159 (US Letter, 14 count, 1-1/2" x 4")',
      page_width: 8.5,
      page_length: 11,
      page_x_margin: 0.156,
      page_y_margin: 0.25,
      rows: 7,
      columns: 2,
      label_x_padding: 0.188,
      label_y_padding: 0.0,
      label_width: 4,
      label_length: 1.5,
      size_measurement: "in"
    },
    {
      name: 'Avery 5160 (US Letter, 30 count, 1" x 2-5/8")',
      page_width: 8.5,
      page_length: 11,
      page_x_margin: 0.188,
      page_y_margin: 0.5,
      rows: 10,
      columns: 3,
      label_x_padding: 0.125,
      label_y_padding: 0.0,
      label_width: 2.625,
      label_length: 1,
      size_measurement: "in"
    },
    {
      name: 'Avery 5161 (US Letter, 20 count, 1" x 4")',
      page_width: 8.5,
      page_length: 11,
      page_x_margin: 0.156,
      page_y_margin: 0.5,
      rows: 10,
      columns: 2,
      label_x_padding: 0.188,
      label_y_padding: 0.0,
      label_width: 4,
      label_length: 1,
      size_measurement: "in"
    },
    {
      name: 'Avery 5164 (US Letter, 6 count, 3-1/3" x 4")',
      page_width: 8.5,
      page_length: 11,
      page_x_margin: 0.156,
      page_y_margin: 0.5,
      rows: 3,
      columns: 2,
      label_x_padding: 0.188,
      label_y_padding: 0.0,
      label_width: 4,
      label_length: 3.333333,
      size_measurement: "in"
    },
    {
      name: 'Avery 5167 (US Letter, 80 count, 1-3/4" x 1/2")',
      page_width: 8.5,
      page_length: 11,
      page_x_margin: 0.3,
      page_y_margin: 0.5,
      rows: 20,
      columns: 4,
      label_x_padding: 0.3,
      label_y_padding: 0.0,
      label_width: 1.75,
      label_length: 0.5,
      size_measurement: "in"
    },
    {
      name: 'Avery 5735 (US Letter, 12 count, 1" x 3")',
      page_width: 8.5,
      page_length: 11,
      page_x_margin: 0.575,
      page_y_margin: 0.802,
      rows: 6,
      columns: 2,
      label_x_padding: 1.188,
      label_y_padding: 0.656,
      label_width: 3,
      label_length: 1,
      size_measurement: "in"
    },
    {
      name: 'Avery 6870 (US Letter, 30 count, 3/4" x 2-1/4")',
      page_width: 8.5,
      page_length: 11,
      page_x_margin: 0.375,
      page_y_margin: 0.625,
      rows: 10,
      columns: 3,
      label_x_padding: 0.5,
      label_y_padding: 0.25,
      label_width: 2.25,
      label_length: 0.75,
      size_measurement: "in"
    },
    {
      name: 'Avery 6871 (US Letter, 18 count, 1-1/4" x 2-3/8")',
      page_width: 8.5,
      page_length: 11,
      page_x_margin: 0.375,
      page_y_margin: 1.125,
      rows: 6,
      columns: 3,
      label_x_padding: 0.313,
      label_y_padding: 0.25,
      label_width: 2.375,
      label_length: 1.25,
      size_measurement: "in"
    },
    {
      name: 'Avery 6873 (US Letter, 8 count, 2" x 3-3/4")',
      page_width: 8.5,
      page_length: 11,
      page_x_margin: 0.375,
      page_y_margin: 1.125,
      rows: 4,
      columns: 2,
      label_x_padding: 0.25,
      label_y_padding: 0.25,
      label_width: 3.75,
      label_length: 2,
      size_measurement: "in"
    },
    {
      name: 'Avery 6874 (US Letter, 6 count, 3" x 3-3/4")',
      page_width: 8.5,
      page_length: 11,
      page_x_margin: 0.375,
      page_y_margin: 0.625,
      rows: 3,
      columns: 2,
      label_x_padding: 0.25,
      label_y_padding: 0.375,
      label_width: 3.75,
      label_length: 3,
      size_measurement: "in"
    },
    {
      name: 'Avery 6879 (US Letter, 12 count, 1-1/4" x 3-3/4")',
      page_width: 8.5,
      page_length: 11,
      page_x_margin: 0.375,
      page_y_margin: 1.125,
      rows: 6,
      columns: 2,
      label_x_padding: 0.25,
      label_y_padding: 0.25,
      label_width: 3.75,
      label_length: 1.25,
      size_measurement: "in"
    },
    {
      name: 'Avery 45x25-R (A4, 36 count, 45.7mm x 25.4mm)',
      page_width: 210,
      page_length: 297,
      page_x_margin: 5,
      page_y_margin: 15,
      rows: 9,
      columns: 4,
      label_x_padding: 4,
      label_y_padding: 4,
      label_width: 45.7,
      label_length: 25.4,
      size_measurement: 'mm'
    },
    {
      name: 'Avery J4720 (A4, 48 count, 45.7mm x 21.2mm)',
      page_width: 210,
      page_length: 297,
      page_x_margin: 9.75,
      page_y_margin: 21.48,
      rows: 12,
      columns: 4,
      label_x_padding: 2.54,
      label_y_padding: 0,
      label_width: 45.72,
      label_length: 21.17,
      size_measurement: 'mm'
    },
    {
      name: 'Avery J4721 (A4, 27 count, 63.5mm x 29.6mm)',
      page_width: 210,
      page_length: 297,
      page_x_margin: 7.21,
      page_y_margin: 15.165,
      rows: 9,
      columns: 3,
      label_x_padding: 2.54,
      label_y_padding: 0,
      label_width: 63.5,
      label_length: 29.63,
      size_measurement: 'mm'
    }
  ]

  const presetItems = () => {
    let items = [];

    presets.map(preset => 
      items.push(
        {
          content: preset.name,
          onItemClick: (event) => switchPreset(event, preset),
          hash: preset.name
        }
      )
    )

    return items;
  }

  const switchPreset = (event, preset) => {
    console.log(event);
    console.log(preset);

    let updatedTemplate = templateForm;

    setLength(preset['label_length']);
    setWidth(preset['label_width']);
    setSizeMeasurement(preset['size_measurement']);

    updatedTemplate['length'] = preset['label_length']
    updatedTemplate['width'] = preset['label_width']
    updatedTemplate['size_measurement'] = preset['size_measurement']

    setPageWidth(preset['page_width']);
    setPageLength(preset['page_length']);
    setPageXMargin(preset['page_x_margin']);
    setPageYMargin(preset['page_y_margin']);
    setPageRows(preset['rows']);
    setPageColumns(preset['columns']);
    setPageLabelXPadding(preset['label_x_padding']);
    setPageLabelYPadding(preset['label_y_padding']);

    updatedTemplate['page_definition']['page_width'] = preset['page_width'];
    updatedTemplate['page_definition']['page_length'] = preset['page_length'];
    updatedTemplate['page_definition']['page_x_margin'] = preset['page_x_margin'];
    updatedTemplate['page_definition']['page_y_margin'] = preset['page_y_margin'];
    updatedTemplate['page_definition']['rows'] = preset['rows'];
    updatedTemplate['page_definition']['columns'] = preset['columns'];
    updatedTemplate['page_definition']['label_x_padding'] = preset['label_x_padding'];
    updatedTemplate['page_definition']['label_y_padding'] = preset['label_y_padding'];

    setTemplateForm(updatedTemplate);

    handlePreview(templateForm, null);
  }


  return (
    <>
      <Text>
        Select a preset label sheet type. If you don't see the label sheets you're using, and you aren't able to create the template from the options available, please let us know at support@athousandapps.com.
      </Text>
      <Dropdown
        items={presetItems()}
        maxHeight={250}
        placement="bottom-start"
        toggle={<Button iconRight={<ArrowDropDownIcon />}>{"Apply Page Presets"}</Button>}
      />
    </>
  )
}

export default PresetSection;
