import { combineReducers } from 'redux';
import barcodes from './barcodes';
import barcodeTemplates from './barcodeTemplates';
import inventorySessions from './inventorySessions';
import bulkInventorySessions from './bulkInventorySessions'
import batchInventoryResults from './batchInventoryResults'
import products from './products';
import locations from './locations';
import prints from './prints';
import stores from './stores';
import users from './users';
import payments from './payments';
import categories from './categories';
import orderSessions from './orderSessions';
import pickOrder from './pickOrder';
import settings from './settings';

export default combineReducers({
  barcodes,
  barcodeTemplates,
  inventorySessions,
  bulkInventorySessions,
  batchInventoryResults,
  products,
  locations,
  prints,
  stores,
  users,
  settings,
  categories,
  payments,
  orderSessions,
  pickOrder,
});
