import React, { useState, useEffect } from 'react';
import { generateBarcodes } from '../../redux/actions/barcodes';

import { Modal, Text, H2 } from '@bigcommerce/big-design';

const RepeatScanDialog = ({ quantity, setShowRepeatScanDialog, focusScanArea }) => {
  const close = () => {
    setShowRepeatScanDialog(false);
    focusScanArea();
  }

  return(
    <div className="main-overlay main-overlay__warning">
      <Modal
        actions={[
          { 
            text: 'Confirm', 
            onClick: () => close()
          },
        ]}
        closeOnClickOutside={true}
        closeOnEscKey={true}
        backdrop={false}
        isOpen={true}
        onClose={() => close()}
        variant="dialog"
      >
        <br/>
        <H2 color="warning50">Repeat Item</H2>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M11 10a1 1 0 1 1 2 0v3.5a1 1 0 1 1-2 0V10zM12 15.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.268 2.991c.771-1.321 2.683-1.322 3.455 0l9.342 16.004c.778 1.332-.184 3.005-1.728 3.005H2.653C1.109 22 .148 20.327.925 18.995L10.268 2.99zm11.07 17.01L11.994 3.998 2.653 20.001h18.684z" fill="currentColor"/>
          </svg>
          <span className="left-15">The scanned item is in this order, but may have already been picked. Please confirm this quantity:</span>
        </div>
        <p className="pick-quantity">
          { quantity }
        </p>
      </Modal>
    </div>
  )
}

export default RepeatScanDialog;
