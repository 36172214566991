import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import LoadingSpinner from '../partials/LoadingSpinner';

import { Text, Checkbox, H4, H2, Button } from '@bigcommerce/big-design';

const LineItem = ({ pickOrder, lineItem, toggleChecked, checkedItems, unpickableItems, setDialogLineItemId, setDisplayConfirmOrderUpdate, switchUnpickable, settings, hidePickedItems }) => {
  const focus = (event) => {
    event.target.parentElement.classList.add("field--focus");
  }

  const blur = (event) => {
    event.target.parentElement.classList.remove("field--focus");
  }

  const toggle = () => {
    if(!unpickableItems.includes(lineItem.line_item_id)) {
      toggleChecked(lineItem.line_item_id);
    }
  }

  const updateClick = () => {
    setDialogLineItemId(lineItem.line_item_id);
    setDisplayConfirmOrderUpdate(true);
  }

  const removeUnpickable = () => {
    switchUnpickable(lineItem.line_item_id);
  }

  if(hidePickedItems && checkedItems.includes(lineItem.line_item_id) && !unpickableItems.includes(lineItem.line_item_id)) {
    return (
      <></>
    )
  }

  return (
    <div className={`has-hover sku-line flex-container ${unpickableItems.includes(lineItem.line_item_id) ? 'main-overlay__warning' : ''}`} onClick={() => toggle()}>
      <div className="flex-1">
        <div className="flex-container">
          { !unpickableItems.includes(lineItem.line_item_id) &&
            <Checkbox
              checked={checkedItems.includes(lineItem.line_item_id)}
              onChange={() => toggle()}
            />
          }
          <div className="left-15">
            { lineItem.image_url && <img className="live-row-image" src={lineItem.image_url} /> }
            { !lineItem.image_url && <Text>No image</Text>}
          </div>
        </div>
      </div>

      <div className="flex-2">
        <div className="flex-container">
          <H4>{lineItem.name}</H4>
          { lineItem.sku && <Text color="secondary60"> - {lineItem.sku}</Text> }
        </div>
        <ul>
          { lineItem.options && lineItem.options.map(option =>
              <li>
                <strong>{ option.display_name }</strong>: { option.display_value }
              </li>
            )
          }
          { lineItem.brand &&
            <li><strong>Brand</strong>: { lineItem.brand }</li>
          }
          { lineItem.bin_picking_number &&
            <li><strong>BPN</strong>: { lineItem.bin_picking_number }</li>
          }
          { lineItem.options.length === 0 &&
            <Text color="warning40">
              Base Product
            </Text>
          }
        </ul>
      </div>
      <div className="flex-1">
        <div classname="flex-container">
          <H4>Pick Quantity</H4>
          <H2>{lineItem.quantity}</H2>
        </div>
        { unpickableItems.includes(lineItem.line_item_id) && 
          <div classname="flex-container">
            <div className="flex-1 bottom-5">
              { settings.allow_quantity_updates && !pickOrder.orderSetQuantityFetching &&
                <Button onClick={() => updateClick()}>
                  Update Pick Quantity
                </Button>
              }
              { pickOrder.orderSetQuantityFetching &&
                <LoadingSpinner
                  size="xxSmall"
                  statusText='Updating Quantity'
                />
              }
            </div>
            <div className="flex-1">
              <Button onClick={() => removeUnpickable()}>
                Clear Unpickable Status
              </Button>
            </div>
          </div>
        }
      </div>
    </div>
  )

}

const mapStateToProps = state => ({
  pickOrder: state.pickOrder,
  checkedItems: state.pickOrder.checkedItems,
  unpickableItems: state.pickOrder.unpickableItems,
  settings: state.settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
  // searchOrder: (payload, orderId) => dispatch(readOrder(payload, orderId)),
  // pushCheckedList: (skuList) => dispatch(setCheckedList(skuList))
});

export default connect(mapStateToProps, mapDispatchToProps)(LineItem);
