import React, { useState, useEffect } from 'react';
import { generateBarcodes } from '../../redux/actions/barcodes';

import { Modal, Text, H2 } from '@bigcommerce/big-design';

const IncorrectScanDialog = ({ quantity, setShowIncorrectScanDialog, focusScanArea }) => {
  const close = () => {
    setShowIncorrectScanDialog(false);
    focusScanArea();
  }

  return(
    <div className="main-overlay main-overlay__error">
      <Modal
        actions={[
          { 
            text: 'Confirm', 
            onClick: () => close()
          },
        ]}
        closeOnClickOutside={true}
        closeOnEscKey={true}
        backdrop={false}
        isOpen={true}
        onClose={() => close()}
        variant="dialog"
      >
        <br/>
        <H2 color="danger50">Incorrect Item</H2>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18zM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12z" fill="currentColor"/>
            <path d="M10.586 12 9 13.586A1 1 0 1 0 10.414 15L12 13.414 13.586 15A1 1 0 1 0 15 13.586L13.414 12 15 10.414A1 1 0 0 0 13.586 9L12 10.586 10.414 9A1 1 0 1 0 9 10.414L10.586 12z" fill="currentColor"/>
          </svg>
          <span className="left-15">The scanned item is not in this order. Do not pick.</span>
        </div>
      </Modal>
    </div>
  )
}

export default IncorrectScanDialog;