import ModifyRow from "./ModifyRow";
import ScanRow from "./ScanRow";
import RequestRow from "./RequestRow";
import ErrorRow from "./ErrorRow";
import TransferRow from "./TransferRow";

const LiveSession = ({ event }) => {
  if(event.error) {
    return(<ErrorRow event={event}/>)
  }

  if(event.placeholder) {
    return(<RequestRow event={event}/>)
  }

  if(event.actionType === 'scan') {
    return(<ScanRow event={event}/>)
  }

  if(event.actionType === 'transfer') {
    return(<TransferRow event={event}/>)
  }

  if(event.actionType === 'set') {
    return(<ModifyRow event={event} type='Set Inventory' />)
  }

  if(event.actionType === 'add') {
    return(<ModifyRow event={event} type='Add Inventory' />)
  }

  if(event.actionType === 'subtract') {
    return(<ModifyRow event={event} type='Subtract Inventory' />)
  }
}

export default LiveSession;