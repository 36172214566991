import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Document, Page, pdfjs } from 'react-pdf';

import SettingsPage from '../partials/SettingsPage';
import LoadingSpinner from '../partials/LoadingSpinner';
import LoadingPlaceholder from '../partials/LoadingPlaceholder';
import PageHeader from "../partials/PageHeader";
import { useNavigate } from "react-router-dom";
import PresetSection from './PresetSection';

import SlTab from '@shoelace-style/shoelace/dist/react/tab';
import SlTabGroup from '@shoelace-style/shoelace/dist/react/tab-group';
import SlTabPanel from '@shoelace-style/shoelace/dist/react/tab-panel';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';

import { createBarcodeTemplate, previewBarcodeTemplate } from '../../redux/actions/barcodeTemplates';

import TemplateLineOrderContainer from './TemplateLineOrderContainer';

import { Button, Text, H2, H3, H4, Panel, Input, FormControlLabel, Switch, FormGroup, Radio, Checkbox, Small, Table, Toggle } from '@bigcommerce/big-design';

const NewTemplateForm = ({ payload, newBarcodeTemplate, barcodeTemplates, newTemplate, loadPreview }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  let navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);
  const [validTemplate, setValidTemplate] = useState(true);
  const [validationErrors, setValidationErrors] = useState([]);

  const [templateSaved, setTemplateSaved] = useState(true);
  const [templateForm, setTemplateForm] = useState({
    line_settings: [
      {
        font_size: 12,
        alignment: 'left',
        position: 0
      },
      {
        font_size: 12,
        alignment: 'left',
        position: 1
      },
      {
        font_size: 12,
        alignment: 'left',
        position: 2
      },
      {
        font_size: 12,
        alignment: 'left',
        position: 3
      }
    ],

    page_definition: {
      page_width: 8.5,
      page_length: 11,
      page_x_margin: 0.25,
      page_y_margin: 0.25,
      rows: 15,
      columns: 3,
      label_x_padding: 0.1,
      label_y_padding: 0.1
    },

    barcode_enabled: true,
    barcode_line: 0,
    barcode_height_percent: 50,
    barcode_symbol_enabled: false,
    barcode_symbol_line: 3,
    barcode_symbol_priority: 7,
    barcode_symbol_label_enabled: false,
    barcode_symbol_label_override: '',
    product_name_enabled: false,
    product_name_line: 1,
    product_name_priority: 3,
    product_name_label_enabled: false,
    product_nameLabelOverride: '',
    option_1_enabled: false,
    option_1_line: 2,
    option_1_priority: 4,
    option_1_label_enabled: false,
    option_1_label_override: '',
    option_2_enabled: false,
    option_2_line: 2,
    option_2_priority: 5,
    option_2_label_enabled: false,
    option_2_label_override: '',
    option_3_enabled: false,
    option_3_line: 2,
    option_3_priority: 6,
    option_3_label_enabled: false,
    option_3_label_override: '',
    price_enabled: false,
    price_line: 2,
    price_priority: 2,
    price_label_enabled: false,
    price_label_override: '',
    brand_enabled: false,
    brand_line: 1,
    brand_priority: 0,
    brand_label_enabled: false,
    brand_label_override: '',
    rotate: false,
    print_type: 'roll',
    length: 1,
    width: 3,
    size_measurement: 'in',

    store_logo_enabled: false,
    store_logo_line: 0,
    store_logo_height_percent: 50,

    arbitrary_text_enabled: false,
    arbitrary_text_line: 0,
    arbitrary_text_priority: 8,
    arbitrary_text_label_enabled: false,
    arbitrary_text_label_override: '',
  });

  const [templateName, setTemplateName] = useState('');
  const [rotate, setRotate] = useState(false);
  const [printType, setPrintType] = useState('roll');
  const [length, setLength] = useState(1);
  const [width, setWidth] = useState(3);
  const [sizeMeasurement, setSizeMeasurement] = useState('in');

  const [lineCount, setLineCount] = useState(4);
  const [lineSettings, setLineSettings] = useState([
    {
      font_size: 12,
      alignment: 'left',
      position: 0
    },
    {
      font_size: 12,
      alignment: 'left',
      position: 1
    },
    {
      font_size: 12,
      alignment: 'left',
      position: 2
    },
    {
      font_size: 12,
      alignment: 'left',
      position: 3
    }
  ]);

  const [pageWidth, setPageWidth] = useState(8.5);
  const [pageLength, setPageLength] = useState(11);
  const [pageXMargin, setPageXMargin] = useState(0.25);
  const [pageYMargin, setPageYMargin] = useState(0.25);
  const [pageRows, setPageRows] = useState(15);
  const [pageColumns, setPageColumns] = useState(3);
  const [pageLabelXPadding, setPageLabelXPadding] = useState(0.1);
  const [pageLabelYPadding, setPageLabelYPadding] = useState(0.1);

  const [barcodeEnabled, setBarcodeEnabled] = useState(true);
  const [barcodeLine, setBarcodeLine] = useState(0);
  const [barcodeHeightPercent, setBarcodeHeightPercent] = useState(50);

  const [storeLogoEnabled, setStoreLogoEnabled] = useState(false);
  const [storeLogoLine, setStoreLogoLine] = useState(0);
  const [storeLogoHeightPercent, setStoreLogoHeightPercent] = useState(50);

  const [barcodeSymbolEnabled, setBarcodeSymbolEnabled] = useState(false);
  const [barcodeSymbolLine, setBarcodeSymbolLine] = useState(3);
  const [barcodeSymbolPriority, setBarcodeSymbolPriority] = useState(7);
  const [barcodeSymbolLabelEnabled, setBarcodeSymbolLabelEnabled] = useState(false);
  const [barcodeSymbolLabelOverride, setBarcodeSymbolLabelOverride] = useState('');

  const [productNameEnabled, setProductNameEnabled] = useState(false);
  const [productNameLine, setProductNameLine] = useState(1);
  const [productNamePriority, setProductNamePriority] = useState(3);
  const [productNameLabelEnabled, setProductNameLabelEnabled] = useState(false);
  const [productNameLabelOverride, setProductNameLabelOverride] = useState('');

  const [optionOneEnabled, setOptionOneEnabled] = useState(false);
  const [optionOneLine, setOptionOneLine] = useState(2);
  const [optionOnePriority, setOptionOnePriority] = useState(4);
  const [optionOneLabelEnabled, setOptionOneLabelEnabled] = useState(false);
  const [optionOneLabelOverride, setOptionOneLabelOverride] = useState('');

  const [optionTwoEnabled, setOptionTwoEnabled] = useState(false);
  const [optionTwoLine, setOptionTwoLine] = useState(2);
  const [optionTwoPriority, setOptionTwoPriority] = useState(5);
  const [optionTwoLabelEnabled, setOptionTwoLabelEnabled] = useState(false);
  const [optionTwoLabelOverride, setOptionTwoLabelOverride] = useState('');

  const [optionThreeEnabled, setOptionThreeEnabled] = useState(false);
  const [optionThreeLine, setOptionThreeLine] = useState(2);
  const [optionThreePriority, setOptionThreePriority] = useState(6);
  const [optionThreeLabelEnabled, setOptionThreeLabelEnabled] = useState(false);
  const [optionThreeLabelOverride, setOptionThreeLabelOverride] = useState('');

  const [priceEnabled, setPriceEnabled] = useState(false);
  const [priceLine, setPriceLine] = useState(2);
  const [pricePriority, setPricePriority] = useState(2);
  const [priceLabelEnabled, setPriceLabelEnabled] = useState(false);
  const [priceLabelOverride, setPriceLabelOverride] = useState('');

  const [brandEnabled, setBrandEnabled] = useState(false);
  const [brandLine, setBrandLine] = useState(1);
  const [brandPriority, setBrandPriority] = useState(0);
  const [brandLabelEnabled, setBrandLabelEnabled] = useState(false);
  const [brandLabelOverride, setBrandLabelOverride] = useState('');

  const [arbitraryTextEnabled, setArbitraryTextEnabled] = useState(false);
  const [arbitraryTextLine, setArbitraryTextLine] = useState(3);
  const [arbitraryTextPriority, setArbitraryTextPriority] = useState(8);
  const [arbitraryTextLabelEnabled, setArbitraryTextLabelEnabled] = useState(true);
  const [arbitraryTextLabelOverride, setArbitraryTextLabelOverride] = useState('');

  const [activePreviewuri, setActivePreviewUri] = useState(null);

  const [previewGenerating, setPreviewGenerating] = useState(false);
  const [enqueuePreviewGeneration, setEnqueuePreviewGeneration] = useState(null);

  useEffect(() => {
    handlePreview(templateForm, null);
  }, []);

  useEffect(() => {
    if(newTemplate) { navigate(-1) };
  }, [newTemplate]);

  useEffect(() => {
    let updatedTemplate = templateForm;
    updatedTemplate['line_settings'] = lineSettings;

    handlePreview(updatedTemplate, null);
  }, [lineSettings]);

  const changeLineCount = (event) => {
    let updatedTemplate = templateForm;
    let updatedLineSettings = lineSettings;

    let newLineCount = parseInt(event);

    let isIncrease = newLineCount > (lineCount || 0);

    if(isIncrease) {
      let difference = newLineCount - (lineCount || 0);

      for(let i = 0; i < difference; i++){
        updatedLineSettings.push({
          font_size: 12,
          alignment: 'left',
          position: (lineCount || 0) + i
        })
      }
    } else {
      let difference = (lineCount || 0) - newLineCount;

      for(let i = 0; i < difference; i++){
        updatedLineSettings.pop();
      }
    }

    setLineCount(newLineCount);

    updatedTemplate['line_count'] = newLineCount;
    updatedTemplate['line_settings'] = updatedLineSettings;

    setTemplateSaved(false);
    setTemplateForm(updatedTemplate);

    handlePreview(updatedTemplate, null);
  }

  const setNumeric = (setField, event) => {
    let value = event.target.value;
    let updatedTemplate = templateForm;
    let valueNumeric = value.replace(/[^\d.-]+/g, '');

    if(valueNumeric > 999.9) {
      valueNumeric = 999.9
    }

    setField(valueNumeric);
    updatedTemplate[event.target.id] = valueNumeric;

    setTemplateSaved(false);
    setTemplateForm(updatedTemplate);

    if (value) {
      event.target.parentElement.classList.add("field--filled");
    } else {
      event.target.parentElement.classList.remove("field--filled");
    }

    handlePreview(updatedTemplate, null);
  }

  const setInteger = (setField, event) => {
    let value = event.target.value;
    let updatedTemplate = templateForm;
    let valueNumeric = parseInt(value) || 0;

    if(valueNumeric > 99) {
      valueNumeric = 99
    }

    setField(valueNumeric);
    updatedTemplate[event.target.id] = valueNumeric;

    setTemplateSaved(false);
    setTemplateForm(updatedTemplate);

    if (value) {
      event.target.parentElement.classList.add("field--filled");
    } else {
      event.target.parentElement.classList.remove("field--filled");
    }

    handlePreview(updatedTemplate, null);
  }

  const setPageValue = (setField, event) => {
    let value = event.target.value;
    let updatedTemplate = templateForm;
    let valueNumeric = value.replace(/[^\d.-]+/g, '') || 0.0;

    if(valueNumeric > 999.9) {
      valueNumeric = 999.9
    }

    let valueInteger = parseInt(value) || 0;

    if(['rows', 'columns'].includes(event.target.id)) {
      setField(valueInteger);
      updatedTemplate['page_definition'][event.target.id] = valueInteger;
    } else {
      setField(valueNumeric);
      updatedTemplate['page_definition'][event.target.id] = parseFloat(valueNumeric);
    }

    setTemplateSaved(false);
    setTemplateForm(updatedTemplate);

    validateTemplate(updatedTemplate);

    if (value) {
      event.target.parentElement.classList.add("field--filled");
    } else {
      event.target.parentElement.classList.remove("field--filled");
    }
  }

  const switchBoolean = (setField, field, key) => {
    let updatedTemplate = templateForm;
    updatedTemplate[key] = !field;

    setField(!field);
    setTemplateSaved(false);
    setTemplateForm(updatedTemplate);

    handlePreview(updatedTemplate, null);
  }

  const handleSwitchRotate = () => {
    let updatedTemplate = templateForm;
    updatedTemplate['rotate'] = !rotate;

    setRotate(!rotate);
    setTemplateForm(updatedTemplate);
    setTemplateSaved(false);
    
    handlePreview(updatedTemplate, null);
  }

  const handleSwitchPageType = (newType) => {
    let updatedTemplate = templateForm;

    updatedTemplate['print_type'] = newType;
    setPrintType(newType);

    if (newType === 'sheet') {
      setRotate(false);
      updatedTemplate['rotate'] = false;
    }

    setTemplateForm(updatedTemplate);
    setTemplateSaved(false);

    handlePreview(updatedTemplate, null);
  }

  const setMeasurements = (event) => {
    let measurement = event.target.value;

    let updatedTemplate = templateForm;
    updatedTemplate['size_measurement'] = measurement;

    setTemplateForm(updatedTemplate);

    setSizeMeasurement(measurement);

    handlePreview(updatedTemplate, null);
    setTemplateSaved(false);
  }

  const setFieldValue = (setField, event) => {
    let value = event.target.value;

    let updatedTemplate = templateForm;
    updatedTemplate[event.target.id] = value;
    setTemplateForm(updatedTemplate);
    setField(value);
    setTemplateSaved(false);

    handlePreview(updatedTemplate, null);
  }

  const setPriority = (setFunction, value, key) => {
    let updatedTemplate = templateForm;

    setFunction(value);

    updatedTemplate[key] = value;

    setTemplateForm(updatedTemplate);
    setTemplateSaved(false);

    handlePreview(updatedTemplate, null);
  }

  const handleSubmit = () => {
    setSubmitted(true);
    newBarcodeTemplate(payload, templateForm);
    setTemplateSaved(true);
  }

  const handlePreview = (templateForm, values) => {
    validateTemplate(templateForm);

    if(!previewGenerating) {
      setPreviewGenerating(true);
      setEnqueuePreviewGeneration(null);
      loadPreview(payload, templateForm, values);
    } else {
      setEnqueuePreviewGeneration(templateForm);
    }
  }

  const readyPreview = () => {
    setPreviewGenerating(false);
    if(enqueuePreviewGeneration) {
      handlePreview(enqueuePreviewGeneration, null);
    }
  }

  const validateTemplate = (templateForm) => {
    let valid = true;
    let updatedValidationErrors = [];

    // Must have a template name
    if (!templateForm['template_name']) {
      valid = false;
      updatedValidationErrors.push('Template name missing');
    }

    // Must have label lengths and widths
    if (!templateForm['width'] ||  !templateForm['length']) {
      valid = false;
      updatedValidationErrors.push('Templates must have defined label length and width');
    }

    // If it's a sheet, it must have page length, page width, rows and columns
    if (templateForm['print_type'] === 'sheet') {
      if (!templateForm.page_definition.page_length || !templateForm.page_definition.page_width || !templateForm.page_definition.columns || !templateForm.page_definition.rows) {
        valid = false;
        updatedValidationErrors.push('Sheets must have defined page length, page width, columns and rows.');
      }
    }

    setValidationErrors(updatedValidationErrors);
    setValidTemplate(valid);
  }

  const setFontSize = (position, e) => {
    let updatedTemplate = templateForm;

    setLineSettings(
      lineSettings.map((setting) =>
        setting.position === position ? { ...setting, font_size: e.target.value.replace(/[^\d-]+/g, '') } : { ...setting }
      )
    )

    updatedTemplate['line_settings'] = lineSettings;

    setTemplateForm(updatedTemplate);
    setTemplateSaved(false);
  }

  const setAlignment = (position, e) => {
    let updatedTemplate = templateForm;

    setLineSettings(
      lineSettings.map((setting) =>
        setting.position === position ? { ...setting, alignment: e } : { ...setting }
      )
    )

    updatedTemplate['line_settings'] = lineSettings;

    setTemplateForm(updatedTemplate);
    setTemplateSaved(false);
  }

  const increasePriority = (element, lineElements) => {
    let elementIndex = lineElements.findIndex((el) => el === element);

    if(elementIndex === 0) { return; }

    let el = lineElements[elementIndex - 1];
    let elementNewPriority = lineElements[elementIndex - 1].priority;
    let elementOldPriority = element.priority;

    if(element.element === 'Barcode Symbol') { setPriority(setBarcodeSymbolPriority, elementNewPriority, 'barcode_symbol_priority') }
    if(element.element === 'Product Name') { setPriority(setProductNamePriority, elementNewPriority, 'product_name_priority') }
    if(element.element === 'Variation Option 1') { setPriority(setOptionOnePriority, elementNewPriority, 'option_1_priority') }
    if(element.element === 'Variation Option 2') { setPriority(setOptionTwoPriority, elementNewPriority, 'option_2_priority') }
    if(element.element === 'Variation Option 3') { setPriority(setOptionThreePriority, elementNewPriority, 'option_3_priority') }
    if(element.element === 'Price') { setPriority(setPricePriority, elementNewPriority, 'price_priority') }
    if(element.element === 'Brand') { setPriority(setBrandPriority, elementNewPriority, 'brand_priority') }
    if(element.element === 'Branding Text') { setPriority(setArbitraryTextPriority, elementNewPriority, 'arbitrary_text_priority') }

    if(el.element === 'Barcode Symbol') { setPriority(setBarcodeSymbolPriority, elementOldPriority, 'barcode_symbol_priority') }
    if(el.element === 'Product Name') { setPriority(setProductNamePriority, elementOldPriority, 'product_name_priority') }
    if(el.element === 'Variation Option 1') { setPriority(setOptionOnePriority, elementOldPriority, 'option_1_priority') }
    if(el.element === 'Variation Option 2') { setPriority(setOptionTwoPriority, elementOldPriority, 'option_2_priority') }
    if(el.element === 'Variation Option 3') { setPriority(setOptionThreePriority, elementOldPriority, 'option_3_priority') }
    if(el.element === 'Price') { setPriority(setPricePriority, elementOldPriority, 'price_priority') }
    if(el.element === 'Brand') { setPriority(setBrandPriority, elementOldPriority, 'brand_priority') }
    if(el.element === 'Branding Text') { setPriority(setArbitraryTextPriority, elementOldPriority, 'arbitrary_text_priority') }

    let movedElement = document.querySelector(`#line-element-${elementNewPriority}`);

    movedElement.classList.add("blink-action");

    setTimeout(() => {
      movedElement.classList.remove("blink-action");
    }, 1000);
  }
  
  const decreasePriority = (element, lineElements) => {
    let elementIndex = lineElements.findIndex((el) => el === element);

    if(elementIndex === (lineElements.length - 1)) { return; }

    let el = lineElements[elementIndex + 1];
    let elementNewPriority = lineElements[elementIndex + 1].priority;
    let elementOldPriority = element.priority;

    if(element.element === 'Barcode Symbol') { setPriority(setBarcodeSymbolPriority, elementNewPriority, 'barcode_symbol_priority') }
    if(element.element === 'Product Name') { setPriority(setProductNamePriority, elementNewPriority, 'product_name_priority') }
    if(element.element === 'Variation Option 1') { setPriority(setOptionOnePriority, elementNewPriority, 'option_1_priority') }
    if(element.element === 'Variation Option 2') { setPriority(setOptionTwoPriority, elementNewPriority, 'option_2_priority') }
    if(element.element === 'Variation Option 3') { setPriority(setOptionThreePriority, elementNewPriority, 'option_3_priority') }
    if(element.element === 'Price') { setPriority(setPricePriority, elementNewPriority, 'price_priority') }
    if(element.element === 'Brand') { setPriority(setBrandPriority, elementNewPriority, 'brand_priority') }
    if(element.element === 'Branding Text') { setPriority(setArbitraryTextPriority, elementNewPriority, 'arbitrary_text_priority') }

    if(el.element === 'Barcode Symbol') { setPriority(setBarcodeSymbolPriority, elementOldPriority, 'barcode_symbol_priority') }
    if(el.element === 'Product Name') { setPriority(setProductNamePriority, elementOldPriority, 'product_name_priority') }
    if(el.element === 'Variation Option 1') { setPriority(setOptionOnePriority, elementOldPriority, 'option_1_priority') }
    if(el.element === 'Variation Option 2') { setPriority(setOptionTwoPriority, elementOldPriority, 'option_2_priority') }
    if(el.element === 'Variation Option 3') { setPriority(setOptionThreePriority, elementOldPriority, 'option_3_priority') }
    if(el.element === 'Price') { setPriority(setPricePriority, elementOldPriority, 'price_priority') }
    if(el.element === 'Brand') { setPriority(setBrandPriority, elementOldPriority, 'brand_priority') }
    if(el.element === 'Branding Text') { setPriority(setArbitraryTextPriority, elementOldPriority, 'arbitrary_text_priority') }

    let movedElement = document.querySelector(`#line-element-${elementNewPriority}`);

    movedElement.classList.add("blink-action");

    setTimeout(() => {
      movedElement.classList.remove("blink-action");
    }, 1000);

  }

  const description = () => {
    return(
      <>
        <Text>Name the new template, and define the label's size and included information with the options here. As changes are made, a preview of the template will appear on the right.</Text>
        <Text>To see a template with a given product or variant, save the template, find the product in the "Labels + Print" section's Catalog Search, then click the item's "Preview Labels" button.</Text>
        <Text>Note: if text extends beyond the edge of a label, it will not be printed. If you need the text to appear, consider shrinking the font size, or changing labels.</Text>
        <Small>Have questions or need some help? Contact us at <a href="mailto:support@athousandapps.com">support@athousandapps.com</a></Small>
        <Panel>
          <div className="info-background">
          <H3>Preview</H3>
          { barcodeTemplates.previewUri &&
            <Document className="pdf-document" file={{ url: barcodeTemplates.previewUri }} loading={<LoadingSpinner size="xxSmall" statusText="Loading Preview" />} onLoadSuccess={() => readyPreview()}>
              <Page
                className="pdf-page"
                pageNumber={1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>
          }
          { barcodeTemplates.errors &&
            <Text color="danger40">
              Failed to load preview
            </Text>
          }
          </div>
        </Panel>
      </>
    )
  }

  const items = [
    { value: 'roll', label: 'Roll' },
    { value: 'sheet', label: 'Sheet' }
  ];

  return(
    <>
      <PageHeader stripeBackground='#5bc6cc' helpLink="bigcommerce/manage-barcode-label-templates-2/" />
      <SettingsPage title="New Label Template" description={description()}>
        <Panel>
          <div className="flex-container">
            <div className="flex-3">
              <Panel>
                <H2>New Template</H2>

                <H3>Label Template Options</H3>
                  { !submitted && <Input required label="Template Name" placeholder="Template Name" id="template_name" type="text" value={templateName} onChange={(e) => setFieldValue(setTemplateName, e)} /> }
                  { submitted && <Input required label="Template Name" placeholder="Template Name" id="template_name" type="text" value={templateName} onChange={(e) => setFieldValue(setTemplateName, e)} disabled /> }                                      

                <hr/>

                <H3>Print Type</H3>
                <Toggle
                  id="action-type"
                  items={items}
                  onChange={(e) => handleSwitchPageType(e)}
                  value={printType}
                />

                { printType === 'sheet' &&
                  <>
                    <H3>Page Options</H3>
                    <div className="flex-container padding-15 a-card__darken">
                      <div className="flex-1">
                        <H4>Use Template Preset</H4>
                        <PresetSection
                          setTemplateForm={setTemplateForm}
                          templateForm={templateForm}
                          setLength={setLength}
                          setWidth={setWidth}
                          setPageWidth={setPageWidth}
                          setPageLength={setPageLength}
                          setPageXMargin={setPageXMargin}
                          setPageYMargin={setPageYMargin}
                          setPageRows={setPageRows}
                          setPageColumns={setPageColumns}
                          setPageLabelXPadding={setPageLabelXPadding}
                          setPageLabelYPadding={setPageLabelYPadding}
                          setSizeMeasurement={setSizeMeasurement}
                          handlePreview={handlePreview}
                        />
                      </div>
                      <div className="flex-1">
                        { !submitted && <Input required label="Page Width" placeholder="Page Width" id="page_width" type="text" value={pageWidth} onChange={(e) => setPageValue(setPageWidth, e)} /> }
                        { !submitted && <Input required label="Page Length" placeholder="Page Length" id="page_length" type="text" value={pageLength} onChange={(e) => setPageValue(setPageLength, e)} /> }
                        
                        { !submitted && <Input required label="X Margin" placeholder="Page X Margin" id="page_x_margin" type="text" value={pageXMargin} onChange={(e) => setPageValue(setPageXMargin, e)} /> }
                        { !submitted && <Input required label="Y Margin" placeholder="Page Y Margin" id="page_y_margin" type="text" value={pageYMargin} onChange={(e) => setPageValue(setPageYMargin, e)} /> }

                        { !submitted && <Input required label="Page Rows" placeholder="Page Rows" id="rows" type="text" value={pageRows} onChange={(e) => setPageValue(setPageRows, e)} /> }
                        { !submitted && <Input required label="Page Columns" placeholder="Page Columns" id="columns" type="text" value={pageColumns} onChange={(e) => setPageValue(setPageColumns, e)} /> }

                        { !submitted && <Input required label="Label X Padding" placeholder="Page Label X Padding" id="label_x_padding" type="text" value={pageLabelXPadding} onChange={(e) => setPageValue(setPageLabelXPadding, e)} /> }
                        { !submitted && <Input required label="Label Y Padding" placeholder="Page Label Y Padding" id="label_y_padding" type="text" value={pageLabelYPadding} onChange={(e) => setPageValue(setPageLabelYPadding, e)} /> }
                      </div>
                    </div>
                  </>
                }

                <hr/>

                <H4>Label Dimensions</H4>
                <div className="flex-container padding-15 a-card__darken">
                  <div className="flex-1">
                    { !submitted && <Input required label="Length" placeholder="Length" id="length" type="text" value={length} onChange={(e) => setNumeric(setLength, e)} /> }
                    { submitted && <Input required label="Length" placeholder="Length" id="length" type="text" value={length} disabled /> }

                    { !submitted && <Input required label="Width" placeholder="Width" id="width" type="text" value={width} onChange={(e) => setNumeric(setWidth, e)} /> }
                    { submitted && <Input required label="Width" placeholder="Width" id="width" type="text" value={width} disabled /> }
                  </div>
                  <div className="flex-1 padding-15">
                    <FormGroup>
                      <Radio checked={sizeMeasurement === "in"} value="in" label="in" onChange={(e) => setMeasurements(e)} />
                      <Radio checked={sizeMeasurement === "cm"} value="cm" label="cm" onChange={(e) => setMeasurements(e)} />
                      <Radio checked={sizeMeasurement === "mm"} value="mm" label="mm" onChange={(e) => setMeasurements(e)} />
                    </FormGroup>
                  </div>
                </div>

                
                <div className="flex-container padding-15">
                  <div className="flex-1">
                    { !submitted && <Input required label="Line Count" placeholder="Line Count" id="line_count" type="text" value={lineCount || ''} onChange={(e) => setInteger(changeLineCount, e)} /> }
                    { submitted && <Input required label="Line Count" placeholder="Line Count" id="line_count" type="text" value={lineCount || ''} disabled /> }
                  </div>
                  
                  <div className="flex-1 padding-15">
                    { printType === 'roll' &&
                      <>
                        <FormControlLabel renderOptional={false}>Rotate Barcode</FormControlLabel>
                        <Switch checked={rotate} onChange={() => handleSwitchRotate()} />
                      </>
                    }
                  </div>
                </div>

                <hr/>

                <H3>Barcode Row</H3>
                <div className="padding-15">
                  <table className="full-width">
                    <tr>
                      <th className="width-33 table-header">Enabled</th>
                      <th className="width-33 table-header">Line</th>
                      <th className="width-35 table-header">Height % of label</th>
                    </tr>
                    <tr>
                      <td className="text-center table-cell">
                        <input checked={barcodeEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setBarcodeEnabled, barcodeEnabled, 'barcode_enabled')} />
                      </td>
                      <td className="text-center table-cell">
                        <select value={barcodeLine} id="barcode_line" onChange={(e) => setInteger(setBarcodeLine, e)} className="field__select">
                          { lineSettings.map((_lineSetting, index) =>
                            <option value={index} key={index}>{index + 1}</option>
                          )}
                        </select>
                      </td>
                      <td className="text-center left-15 right-15 table-cell">
                        { !submitted && <Input required label="" placeholder="Barcode height %" id="barcode_height_percent" type="text" value={barcodeHeightPercent || ''} onChange={(e) => setInteger(setBarcodeHeightPercent, e)} /> }
                        { submitted && <Input required label="" placeholder="Barcode height %" id="barcode_height_percent" type="text" value={barcodeHeightPercent || ''}  /> }
                      </td>
                    </tr>
                  </table>
                </div>

                <H3>Label Parts</H3>
                <div className="padding-15">
                  <table className="full-width">
                    <tr>
                      <th className="width-12 table-header">Enabled</th>
                      <th className="width-30 table-header">Label Part</th>
                      <th className="width-12 table-header">Line</th>
                      <th className="width-12 table-header">Include Name</th>
                      <th className="width-34 table-header">Name Override</th>
                    </tr>
                    <tr>
                      <td className="text-center table-cell">
                        <input checked={barcodeSymbolEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setBarcodeSymbolEnabled, barcodeSymbolEnabled, 'barcode_symbol_enabled')} />
                      </td>
                      <td className="text-center table-cell">
                        <Text>Barcode Symbol</Text>
                      </td>
                      <td className="text-center table-cell">
                        <select value={barcodeSymbolLine} id="barcode_symbol_line" onChange={(e) => setInteger(setBarcodeSymbolLine, e)}>
                          { lineSettings.map((_lineSetting, index) =>
                            <option value={index} key={index}>{index + 1}</option>
                          )}
                        </select>
                      </td>
                      <td className="text-center table-cell">
                        <input checked={barcodeSymbolLabelEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setBarcodeSymbolLabelEnabled, barcodeSymbolLabelEnabled, 'barcode_symbol_label_enabled')} />
                      </td>
                      <td className="text-center left-15 right-15 table-cell">
                        { !submitted && <Input placeholder="Name Override" id="barcode_symbol_label_override" type="text" value={barcodeSymbolLabelOverride || ''} onChange={(e) => setFieldValue(setBarcodeSymbolLabelOverride, e)} /> }
                        { submitted && <Input placeholder="Name Override" id="barcode_symbol_label_override" type="text" value={barcodeSymbolLabelOverride || ''}  /> }
                      </td>
                    </tr>

                    <tr>
                      <td className="text-center table-cell">
                        <input checked={productNameEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setProductNameEnabled, productNameEnabled, 'product_name_enabled')} />
                      </td>
                      <td className="text-center table-cell">
                        <Text>Product Name</Text>
                      </td>
                      <td className="text-center table-cell">
                        <select value={productNameLine} id="product_name_line" onChange={(e) => setInteger(setProductNameLine, e)}>
                          { lineSettings.map((_lineSetting, index) =>
                            <option value={index} key={index}>{index + 1}</option>
                          )}
                        </select>
                      </td>
                      <td className="text-center table-cell">
                        <input checked={productNameLabelEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setProductNameLabelEnabled, productNameLabelEnabled, 'product_name_label_enabled')} />
                      </td>
                      <td className="text-center left-15 right-15 table-cell">
                        { !submitted && <Input placeholder="Name Override" id="product_name_label_override" type="text" value={productNameLabelOverride || ''} onChange={(e) => setFieldValue(setProductNameLabelOverride, e)} /> }
                        { submitted && <Input placeholder="Name Override" id="product_name_label_override" type="text" value={productNameLabelOverride || ''}  /> }
                      </td>
                    </tr>

                    <tr>
                      <td className="text-center table-cell">
                        <input checked={optionOneEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setOptionOneEnabled, optionOneEnabled, 'option_1_enabled')} />
                      </td>
                      <td className="text-center table-cell">
                        <Text>Variant Option 1</Text>
                      </td>
                      <td className="text-center table-cell">
                        <select value={optionOneLine} id="option_1_line" onChange={(e) => setInteger(setOptionOneLine, e)}>
                          { lineSettings.map((_lineSetting, index) =>
                            <option value={index} key={index}>{index + 1}</option>
                          )}
                        </select>
                      </td>
                      <td className="text-center table-cell">
                        <input checked={optionOneLabelEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setOptionOneLabelEnabled, optionOneLabelEnabled, 'option_1_label_enabled')} />
                      </td>
                      <td className="text-center left-15 right-15 table-cell">
                        { !submitted && <Input placeholder="Name Override" id="option_1_label_override" type="text" value={optionOneLabelOverride || ''} onChange={(e) => setFieldValue(setOptionOneLabelOverride, e)} /> }
                        { submitted && <Input placeholder="Name Override" id="option_1_label_override" type="text" value={optionOneLabelOverride || ''}  /> }
                      </td>
                    </tr>

                    <tr>
                      <td className="text-center table-cell">
                        <input checked={optionTwoEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setOptionTwoEnabled, optionTwoEnabled, 'option_2_enabled')} />
                      </td>
                      <td className="text-center table-cell">
                        <Text>Variant Option 2</Text>
                      </td>
                      <td className="text-center table-cell">
                        <select value={optionTwoLine} id="option_2_line" onChange={(e) => setInteger(setOptionTwoLine, e)}>
                          { lineSettings.map((_lineSetting, index) =>
                            <option value={index} key={index}>{index + 1}</option>
                          )}
                        </select>
                      </td>
                      <td className="text-center table-cell">
                        <input checked={optionTwoLabelEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setOptionTwoLabelEnabled, optionTwoLabelEnabled, 'option_2_label_enabled')} />
                      </td>
                      <td className="text-center left-15 right-15 table-cell">
                        { !submitted && <Input placeholder="Name Override" id="option_2_label_override" type="text" value={optionTwoLabelOverride || ''} onChange={(e) => setFieldValue(setOptionTwoLabelOverride, e)} /> }
                        { submitted && <Input placeholder="Name Override" id="option_2_label_override" type="text" value={optionTwoLabelOverride || ''}  /> }
                      </td>
                    </tr>

                    <tr>
                      <td className="text-center table-cell">
                        <input checked={optionThreeEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setOptionThreeEnabled, optionThreeEnabled, 'option_3_enabled')} />
                      </td>
                      <td className="text-center table-cell">
                        <Text>Variant Option 3</Text>
                      </td>
                      <td className="text-center table-cell">
                        <select value={optionThreeLine} id="option_3_line" onChange={(e) => setInteger(setOptionThreeLine, e)}>
                          { lineSettings.map((_lineSetting, index) =>
                            <option value={index} key={index}>{index + 1}</option>
                          )}
                        </select>
                      </td>
                      <td className="text-center table-cell">
                        <input checked={optionThreeLabelEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setOptionThreeLabelEnabled, optionThreeLabelEnabled, 'option_3_label_enabled')} />
                      </td>
                      <td className="text-center left-15 right-15 table-cell">
                        { !submitted && <Input placeholder="Name Override" id="option_3_label_override" type="text" value={optionThreeLabelOverride || ''} onChange={(e) => setFieldValue(setOptionThreeLabelOverride, e)} /> }
                        { submitted && <Input placeholder="Name Override" id="option_3_label_override" type="text" value={optionThreeLabelOverride || ''}  /> }
                      </td>
                    </tr>

                    <tr>
                      <td className="text-center table-cell">
                        <input checked={priceEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setPriceEnabled, priceEnabled, 'price_enabled')} />
                      </td>
                      <td className="text-center table-cell">
                        <Text>Price</Text>
                      </td>
                      <td className="text-center table-cell">
                        <select value={priceLine} id="price_line" onChange={(e) => setInteger(setPriceLine, e)}>
                          { lineSettings.map((_lineSetting, index) =>
                            <option value={index} key={index}>{index + 1}</option>
                          )}
                        </select>
                      </td>
                      <td className="text-center table-cell">
                        <input checked={priceLabelEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setPriceLabelEnabled, priceLabelEnabled, 'price_label_enabled')} />
                      </td>
                      <td className="text-center left-15 right-15 table-cell">
                        { !submitted && <Input placeholder="Name Override" id="price_label_override" type="text" value={priceLabelOverride || ''} onChange={(e) => setFieldValue(setPriceLabelOverride, e)} /> }
                        { submitted && <Input placeholder="Name Override" id="price_label_override" type="text" value={priceLabelOverride || ''}  /> }
                      </td>
                    </tr>

                    <tr>
                      <td className="text-center table-cell">
                        <input checked={brandEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setBrandEnabled, brandEnabled, 'brand_enabled')} />
                      </td>
                      <td className="text-center table-cell">
                        <Text>Brand</Text>
                      </td>
                      <td className="text-center table-cell">
                        <select value={brandLine} id="brand_line" onChange={(e) => setInteger(setBrandLine, e)}>
                          { lineSettings.map((_lineSetting, index) =>
                            <option value={index} key={index}>{index + 1}</option>
                          )}
                        </select>
                      </td>
                      <td className="text-center table-cell">
                        <input checked={brandLabelEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setBrandLabelEnabled, brandLabelEnabled, 'brand_label_enabled')} />
                      </td>
                      <td className="text-center left-15 right-15 table-cell">
                        { !submitted && <Input placeholder="Name Override" id="brand_label_override" type="text" value={brandLabelOverride || ''} onChange={(e) => setFieldValue(setBrandLabelOverride, e)} /> }
                        { submitted && <Input placeholder="Name Override" id="brand_label_override" type="text" value={brandLabelOverride || ''}  /> }
                      </td>
                    </tr>

                  </table>
                </div>

                <hr/>
                <div className="padding-15">
                  <H3>Store Branding</H3>
                  <Small>Set your store logo in <Link to="/store_settings">app settings</Link>.</Small>
                  
                  <H4>Store Logo Row</H4>
                  <table className="full-width">
                    <tr>
                      <th className="width-33 table-header">Enabled</th>
                      <th className="width-33 table-header">Line</th>
                      <th className="width-33 table-header">Height % of Label</th>
                    </tr>
                    <tr>
                      <td className="text-center table-cell">
                        <input checked={storeLogoEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setStoreLogoEnabled, storeLogoEnabled, 'store_logo_enabled')} />
                      </td>
                      <td className="text-center table-cell">
                        <select value={storeLogoLine} id="store_logo_line" onChange={(e) => setInteger(setStoreLogoLine, e)} className="field__select">
                          { lineSettings.map((_lineSetting, index) =>
                            <option value={index} key={index}>{index + 1}</option>
                          )}
                        </select>
                      </td>
                      <td className="text-center left-15 right-15 table-cell">
                        { !submitted && <Input required label="" placeholder="Store Logo height %" id="store_logo_height_percent" type="text" value={storeLogoHeightPercent || ''} onChange={(e) => setInteger(setStoreLogoHeightPercent, e)} /> }
                        { submitted && <Input required label="" placeholder="Store Logo height %" id="store_logo_height_percent" type="text" value={storeLogoHeightPercent || ''}  /> }
                      </td>
                    </tr>
                  </table>

                  <H4>Branding Text</H4>
                  <table className="full-width">
                    <tr>
                      <th className="width-33 table-header">Enabled</th>
                      <th className="width-33 table-header">Line</th>
                      <th className="width-33 table-header">Text</th>
                    </tr>
                    <tr>
                      <td className="text-center table-cell">
                        <input checked={arbitraryTextEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setArbitraryTextEnabled, arbitraryTextEnabled, 'arbitrary_text_enabled')} />
                      </td>
                      <td className="text-center table-cell">
                        <select value={arbitraryTextLine} id="arbitrary_text_line" onChange={(e) => setInteger(setArbitraryTextLine, e)} className="field__select">
                          { lineSettings.map((_lineSetting, index) =>
                            <option value={index} key={index}>{index + 1}</option>
                          )}
                        </select>
                      </td>
                      <td className="text-center left-15 right-15 table-cell">
                        { !submitted && <Input placeholder="Branding Text" id="arbitrary_text_label_override" type="text" value={arbitraryTextLabelOverride || ''} onChange={(e) => setFieldValue(setArbitraryTextLabelOverride, e)} /> }
                        { submitted && <Input placeholder="Branding Text" id="arbitrary_text_label_override" type="text" value={arbitraryTextLabelOverride || ''}  /> }
                      </td>
                    </tr>
                  </table>
                </div>

                <hr/>

                <H3>Line Settings</H3>
                <div className="flex-container padding-15">
                  <div className="flex-1">
                    <SlTabGroup placement="start">
                      <SlTab slot="nav" panel="disabled" disabled>
                        Select a Line Number
                      </SlTab>

                      { lineSettings.map((lineSetting, index) => 
                        <SlTab slot="nav" panel={`panel-${index}`}>
                          {index + 1}
                        </SlTab>
                      )}

                      <SlTabPanel name="disabled">Select a line number to modify the order that selected label parts appear in.</SlTabPanel>
                      { lineSettings.map((lineSetting, index) => 
                        <SlTabPanel name={`panel-${index}`}>
                          <div className="bottom-15">
                            <H4>Placement</H4>

                            <Toggle
                              id="toggle-example"
                              items={[
                                {
                                  value: 'left',
                                  label: 'Align Left'
                                },
                                {
                                  value: 'center',
                                  label: 'Align Center'
                                },
                                {
                                  value: 'right',
                                  label: 'Align Right'
                                }
                              ]}
                              label="Text Alignment"
                              onChange={(e) => setAlignment(lineSetting.position, e)}
                              value={lineSetting.alignment}
                            />

                            { !submitted && <Input id={`setting_font_size_${index}`} required label="Font Size" placeholder="Font Size" type="text" value={lineSetting.font_size || ''} onChange={(e) => setFontSize(lineSetting.position, e)} /> }
                            { submitted && <Input id={`setting_font_size_${index}`} required label="Font Size" placeholder="Font Size" type="text" value={lineSetting.font_size || ''} disabled /> }

                            <H4>Order</H4>
                            <TemplateLineOrderContainer
                              barcode={barcodeEnabled && (index === barcodeLine)}
                              storeLogo={storeLogoEnabled && (index === storeLogoLine)}

                              barcodeSymbol={barcodeSymbolEnabled && (index === barcodeSymbolLine)}
                              productName={productNameEnabled && (index === productNameLine)}
                              optionOne={optionOneEnabled && (index === optionOneLine)}
                              optionTwo={optionTwoEnabled && (index === optionTwoLine)}
                              optionThree={optionThreeEnabled && (index === optionThreeLine)}
                              price={priceEnabled && (index === priceLine)}
                              brand={brandEnabled && (index === brandLine)}
                              arbitraryText={arbitraryTextEnabled && (index === arbitraryTextLine)}

                              barcodeSymbolPriority={barcodeSymbolPriority}
                              productNamePriority={productNamePriority}
                              optionOnePriority={optionOnePriority}
                              optionTwoPriority={optionTwoPriority}
                              optionThreePriority={optionThreePriority}
                              pricePriority={pricePriority}
                              brandPriority={brandPriority}
                              arbitraryTextPriority={arbitraryTextPriority}

                              increasePriority={increasePriority}
                              decreasePriority={decreasePriority}
                            />

                          </div>
                        </SlTabPanel>
                      )}
                    </SlTabGroup>
                  </div>
                </div>

              </Panel>
            </div>
          </div>
          <hr/>
          { validTemplate && <Button isLoading={submitted} onClick={!submitted ? () => handleSubmit() : () => null}>Save Template</Button> }
          { !validTemplate && <Button isLoading={submitted} onClick={!submitted ? () => handleSubmit() : () => null} disabled>Save Template</Button> }
          { (validationErrors.length > 0) &&
            <>
              { validationErrors.map((error) =>
                <Text color="danger40">
                  {error} 
                </Text>
              )}
              <br/>
            </>
          }
        </Panel>
      </SettingsPage>
    </>
  )
}

const mapStateToProps = state => ({
  barcodeTemplates: state.barcodeTemplates,
  newTemplate: state.barcodeTemplates.newTemplate,
});

const mapDispatchToProps = (dispatch) => ({
  newBarcodeTemplate: (payload, options) => dispatch(createBarcodeTemplate(payload, options)),
  loadPreview: (payload, options, values) => dispatch(previewBarcodeTemplate(payload, options, values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewTemplateForm);
