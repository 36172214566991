import { useNavigate, Link } from "react-router-dom";

import SlTab from '@shoelace-style/shoelace/dist/react/tab';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';

import CancelledSubscriptionHeader from './CancelledSubscriptionHeader';

const PageHeader = ({ stripeBackground, helpLink }) => {
  let navigate = useNavigate();
  const location = window.location.href;

  const headerLink = (url, text, icon) => {
    return(
      <div className="flex-1">
        <Link to={url}>
          <SlTab>
            <SlIcon name={icon} className="header-icon-spacing"></SlIcon>
            {text}
          </SlTab>
        </Link>
      </div>
    )
  }

  const disabledLink = (text, icon) => {
    return(
      <div className="flex-1">
        <SlTab disabled>
          <strong>
            <SlIcon name={icon} className="header-icon-spacing"></SlIcon> 
            {text}
          </strong>
        </SlTab>
      </div>
    )
  }

  return(
    <>
      <div className="hide-mobile">
        <div className="flex-container" style={{ 'padding-left': '40px', 'border-bottom': `2px solid ${stripeBackground}` }}>
          { headerLink('/', 'Home', 'upc-scan') }
          { location.endsWith('/manage') ? disabledLink('Labels + Print', 'printer') : headerLink('/manage', 'Labels + Print', 'printer') }
          
          { location.endsWith('/order') ? disabledLink('Create Order', 'bag-check') : headerLink('/order', 'Create Order', 'bag-check') }
          { location.endsWith('/pick') ? disabledLink('Pick + Verify', 'box-seam') : headerLink('/pick', 'Pick + Verify', 'box-seam') }


          { location.endsWith('/inventory/live') ? disabledLink('Live Modify', 'lightning-charge') : headerLink('/inventory/live', 'Live Modify', 'lightning-charge') }
          { location.endsWith('/inventory/add') ? disabledLink('Add Inventory', 'plus') : headerLink('/inventory/add', 'Add Inventory', 'plus') }

          { location.endsWith('/inventory/update') ? disabledLink('Update Inventory', 'pencil') : headerLink('/inventory/update', 'Update Inventory', 'pencil') }
          { location.endsWith('/inventory/remove') ? disabledLink('Reduce Inventory', 'dash') : headerLink('/inventory/remove', 'Reduce Inventory', 'dash') }

          <div className="flex-1">
            <SlTab onClick={() => navigate(-1)}>
              <SlIcon name="arrow-left" className="header-icon-spacing"></SlIcon>
              Back
            </SlTab>
          </div>
        </div>

        <div className="flex-container">
          <div className="flex-1">
            <a href={`https://athousandapps.com/${helpLink}`} target="_blank">See more details on using this page here.</a>
          </div>
        </div>

        <CancelledSubscriptionHeader />
      </div>

      <div className="only-mobile">
        <div className="flex-container" style={{ 'padding-left': '40px', 'border-bottom': `2px solid ${stripeBackground}` }}>
          { headerLink('/', 'Home', 'upc-scan') }
          <div className="flex-1">
            <SlTab onClick={() => navigate(-1)}>
              <SlIcon name="arrow-left" className="header-icon-spacing"></SlIcon>
              Back
            </SlTab>
          </div>
        </div>
      </div>
    </>
  )
}

export default PageHeader;
