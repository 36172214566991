import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Text, H3, H4, Button, Panel } from '@bigcommerce/big-design';
import { uploadLogo } from '../../redux/actions/settings';

const UploadStoreLogo = ({ payload, settings, uploadLogo }) => {
  const [submitted, setSubmitted] = useState(false);
  const [file, setFile] = useState();
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    if(e.target.files[0]) {
      setSubmitted(false);

      if(e.target.files[0].size <= 1048576) {
        setError(null);
        setFile(e.target.files[0]);
      } else {
        setError('File size is too large (max size 1MB).');
        setFile(null);
      }

    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (file !== undefined && !file.cancelled && file.name) {
      uploadLogo(payload, file);
    }
  }

  return(
    <Panel>
      <H3>Store Logo</H3>
      <H4>Current Logo:</H4>
      { settings.settings.logo_location &&
        <Text>
          {settings.settings.logo_location}
        </Text> 
      }
      { !settings.settings.logo_location &&
        <Text className="warning">No store logo set.</Text>
      }
      <br/>
      <br/>
      <H4>Upload New Logo:</H4>
      <div className="muted">
        Supported file types: jpg, jpeg, png
      </div>
      <form onChange={ (e) => handleChange(e) } onSubmit={ (e) => handleSubmit(e) }>
        <input type="file" name="file" accept="image/png, image/jpeg" />
        <br/>
        <br/>
        <Button disabled={submitted || !file}>
          Upload Logo
        </Button>
      </form>
      { error && 
        <Text className="error">{error}</Text>
      }
    </Panel>
  )
}

const mapStateToProps = state => ({
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  uploadLogo: (payload, file) => dispatch(uploadLogo(payload, file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadStoreLogo);
