import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Button, Text, H2, Table, TableFigure, Small } from '@bigcommerce/big-design';
import { AddIcon } from '@bigcommerce/big-design-icons';

import PageHeader from '../partials/PageHeader';
import DeleteDialog from './DeleteDialog';
import LoadingPlaceholder from '../partials/LoadingPlaceholder';
import SettingsPage from '../partials/SettingsPage';

import { indexBarcodeTemplates, createBarcodeTemplate, updateBarcodeTemplate, previewBarcodeTemplate, deleteBarcodeTemplate } from '../../redux/actions/barcodeTemplates';

const BarcodeTemplates = ({ payload, barcodeTemplates, getTemplates, deleteTemplate }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [activeUuid, setActiveUuid] = useState(false);

  useEffect(() => {
    getTemplates(payload);
  }, []);

  const removeTemplate = (uuid) => {
    deleteTemplate(payload, uuid);
    setShowDeleteDialog(false);
    // getTemplates(payload);
  }

  const deleteDialog = (uuid) => {
    setActiveUuid(uuid);
    setShowDeleteDialog(true);
  }

  const description = () => {
    return(
      <>
        <Text>Label templates are used for printing labels, usually with barcodes, and selected additional information.</Text>
        <Text>From this page, you can add new templates, edit existing templates or delete templates that should no longer be used.</Text>
        <Small>Have questions or need some help? Contact us at <a href="mailto:support@athousandapps.com">support@athousandapps.com</a></Small>
      </>
    )
  }

  return(
    <>
      { barcodeTemplates.status === 'success' && !barcodeTemplates.isFetching && !barcodeTemplates.isDeleting &&
        <>
          <PageHeader stripeBackground='#5bc6cc' helpLink="bigcommerce/manage-barcode-label-templates-2/" />
          <SettingsPage title="Barcode Templates" description={description()}>
            <H2>Existing Templates</H2>
            <TableFigure>
              <Table
                columns={[
                  { header: 'Template Name', hash: 'template_name', render: ({ template_name }) => template_name },
                  { header: 'Template Options', hash: 'template_options', render: ({ width, 
                    size_measurement, 
                    length, 
                    rotate }) =>
                    <>
                      <strong>Size</strong>: {width || 3}{size_measurement} x {length || 1}{size_measurement}
                      <br/>
                      <strong>Rotate</strong>: {rotate && "Rotated"}{!rotate && "Not rotated"}
                    </>
                  },
                  { header: 'Actions', hash: 'stock', render: ({ uuid }) => 
                    <>
                      <Link to={`/templates/edit/${uuid}`}>
                        <span className="left-15 bottom-15"><Button>Edit</Button></span>
                      </Link>
                      <span className="left-15 bottom-15"><Button actionType="destructive" onClick={() => deleteDialog(uuid)}>Delete</Button></span>
                    </> },
                ]}
                items={barcodeTemplates.templates}
              >
              </Table>
              <Link to="/templates/new">
                <Button iconLeft={<AddIcon />}>New Template</Button>
              </Link>
            </TableFigure>
          </SettingsPage>

          { showDeleteDialog && 
            <DeleteDialog
              payload={payload}
              setShowDeleteDialog={setShowDeleteDialog}
              removeTemplate={removeTemplate}
              activeUuid={activeUuid}
              showDeleteDialog={showDeleteDialog}
            />
          }
        </>
      }

      { (barcodeTemplates.status !== 'success' || barcodeTemplates.isFetching || barcodeTemplates.isDeleting) &&
        <>
          <PageHeader stripeBackground='#5bc6cc' helpLink="bigcommerce/manage-barcode-label-templates-2/" />
          <SettingsPage title="Barcode Templates" description={description()}>
            <LoadingPlaceholder title="Barcode Templates" />
          </SettingsPage>
        </>
      }
    </>
  )
}

const mapStateToProps = state => ({
  barcodeTemplates: state.barcodeTemplates,
});

const mapDispatchToProps = (dispatch) => ({
  getTemplates: (payload) => dispatch(indexBarcodeTemplates(payload)),
  deleteTemplate: (payload, templateId) => dispatch(deleteBarcodeTemplate(payload, templateId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BarcodeTemplates);
