import LoadingSpinner from '../partials/LoadingSpinner';

import { Text, H3, H4 } from '@bigcommerce/big-design';

const ModifyRow = ({ event, type }) => {
  return(
    <div className="has-hover sku-line flex-container">
      <div className="flex-1">
        { (event.primary_image || event.image_url) && <img className="live-row-image" src={event.image_url || event.primary_image.url_thumbnail} /> }
        { !(event.primary_image || event.image_url) && <svg className="live-row-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123 123"><g stroke="#c2cbd3" stroke-miterlimit="10"><path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M77.46 22.7H48.28l2.02-7.04h25.15zm0 43.26H48.28l2.02 7.05h25.15z"/><path fill="#edeff2" d="M52.63 7.59h20.48v74.29H52.63z"/><path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M91.903 39.907v8.59h-3.82v-8.59z"/><ellipse cx="63.08" cy="44.21" fill="#fff" stroke-linecap="round" stroke-linejoin="round" rx="25.76" ry="25.53"/><g fill="#fff" stroke-linecap="round" stroke-linejoin="round"><ellipse cx="63.08" cy="44.13" rx="21.8" ry="21.6"/><path d="M51.34 33.05l11.91 11.24 7.47-3.93"/><ellipse cx="63.08" cy="44.13" rx="1.78" ry="1.76"/></g></g></svg> }
      </div>

      <div className="flex-2">
        <H4>{type}: {event.name}</H4>
        <br />
        <Text>
          <b>SKU</b>: {event.sku}
          <br/>
          <b>UPC/EAN</b>: {event.upc}
          <br/>
          <b>Starting Quantity</b>: {event.starting_inventory}
          <br/>
          <b>New Quantity</b>: {event.updatedQuantity}
        </Text>
      </div>
      <div className="flex-2">
        <ul>
          { event.option_values && event.option_values.map(option =>
              <li>
                <Text><b>{ option.option_display_name }</b>: { option.label }</Text>
              </li>
            )
          }
          { ((event.option_values && event.option_values.length === 0) || (!event.option_values)) &&
            <Text color="warning60">
              Base Product
            </Text>
          }
        </ul>
      </div>

      <div className="flex-1">
        <H4>Quantity</H4>
        <H3>{ event.updatedQuantity }</H3>
      </div>
    </div>
  )
}

export default ModifyRow;
