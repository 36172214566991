import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  PAYMENT_REQUESTED,
  PAYMENT_SUCCESS,
  PAYMENT_FAILURE,
  PAYMENT_SESSION_REQUESTED,
  PAYMENT_SESSION_SUCCESS,
  PAYMENT_SESSION_FAILURE,
  PAYMENT_VERIFICATION_REQUESTED,
  PAYMENT_VERIFICATION_SUCCESS,
  PAYMENT_VERIFICATION_FAILURE,
} from '../types';

export const paymentRequested = () => {
  return {
    type: PAYMENT_REQUESTED
  }
};

export const paymentSuccess = (payment) => {
  return {
    type: PAYMENT_SUCCESS,
    payload: payment
  }
};

export const paymentFailure = () => {
  return {
    type: PAYMENT_FAILURE
  }
};

export const paymentSessionRequested = () => {
  return {
    type: PAYMENT_SESSION_REQUESTED
  }
};

export const paymentSessionSuccess = (session) => {
  return {
    type: PAYMENT_SESSION_SUCCESS,
    payload: session
  }
};

export const paymentSessionFailure = () => {
  return {
    type: PAYMENT_SESSION_FAILURE
  }
};

export const paymentVerificationRequested = () => {
  return {
    type: PAYMENT_VERIFICATION_REQUESTED
  }
};

export const paymentVerificationSuccess = (verification) => {
  return {
    type: PAYMENT_VERIFICATION_SUCCESS,
    payload: verification
  }
};

export const paymentVerificationFailure = () => {
  return {
    type: PAYMENT_VERIFICATION_FAILURE
  }
};

export const findPayment = (signedPayload) => {
  return (dispatch) => {
    dispatch(paymentRequested());

    let endPoint = Config.baseUrl + Config.payment;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(paymentSuccess(response.data));
      })
      .catch((error) => {
        dispatch(paymentFailure());
      });
  }
}

export const paymentSession = (signedPayload) => {
  return (dispatch) => {
    dispatch(paymentSessionRequested());

    let endPoint = Config.baseUrl + Config.payment + Config.session;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(paymentSessionSuccess(response.data));
      })
      .catch((error) => {
        dispatch(paymentSessionFailure());
      });
  }
}

export const verifySession = (signedPayload, checkoutSessionId) => {
  return (dispatch) => {
    dispatch(paymentVerificationRequested());

    let endPoint = Config.baseUrl + Config.payment + Config.verify;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.post(endPoint, { 'checkoutSessionId': checkoutSessionId }, { headers: headers })
      .then((response) => {
        dispatch(paymentVerificationSuccess(response.data));
      })
      .catch((error) => {
        dispatch(paymentVerificationFailure());
      });
  }
}
