import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import LoadingPlaceholder from '../partials/LoadingPlaceholder';
import LoadingSpinner from '../partials/LoadingSpinner';
import SettingsPage from '../partials/SettingsPage';
import PageHeader from '../partials/PageHeader';
import LiveSession from "./LiveSession";
import { Text, Toggle, Panel, Input, Button, Dropdown, H4, Small } from '@bigcommerce/big-design';
import { ArrowDropDownIcon, FileDownloadIcon, DeleteIcon } from '@bigcommerce/big-design-icons';

import { addInventory, subtractInventory, setInventory, scanInventory, addPlaceholderAction, transferInventory, clearActions, dismissInventoryScan } from '../../redux/actions/inventorySessions';
import { locationsIndex } from '../../redux/actions/locations';

const LiveModify = ({ payload, locations, dismissScan, postAddInventory, inventorySession, postSubtractInventory, postSetInventory, postScanInventory, postTransferInventory, addAction, clearSession, downloadSessionData, loadLocations }) => {
  const [submitted, setSubmitted] = useState(false);
  const [actionType, setActionType] = useState('scan');
  const [actionQuantity, setActionQuantity] = useState(null);
  const [scanTerm, setScanTerm] = useState('');
  const [activeLocation, setActiveLocation] = useState(null);
  const [activeLabel, setActiveLabel] = useState(null);

  const [fromLocation, setFromLocation] = useState(null);
  const [toLocation, setToLocation] = useState(null);

  useEffect(() => {
    loadLocations(payload);
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(scanTerm !== ''){
        addAction(scanTerm, actionType);
        submitAction();
        setScanTerm('');
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [scanTerm, 500]);

  useEffect(() => {
    setActiveLocation(locations?.locations[0]);
    setActiveLabel(locations?.locations[0]?.label);
    flashScanBar();
  }, [locations]);

  const downloadActions = () => {
    let rows = [['Name', 'Options', 'SKU', 'UPC/EAN', 'Price', 'Action Type', 'Action Quantity', 'Quantity', 'Updated Quantity']];

    inventorySession.events.toReversed().map(event =>
      appendAction(rows, event)
    )

    let csvContent = "data:text/csv;charset=utf-8," 
        + rows.map(e => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  const appendAction = (rowsArray, event) => {
    let options = [];

    event.option_values?.map(option => 
      options.push(`${option.option_display_name} -- ${option.label}`)
    )

    let row = [event.name, options.join(' | '), event.sku, event.upc, event.price, event.actionType, event.actionQuantity, event.inventory_level, event.updatedQuantity]

    rowsArray.push(row);
  }

  const updateScan = (event) => {
    let value = event.target.value;
    setScanTerm(value);
  }

  const validateQuantity = (event) => {
    let value = event.target.value;
    setActionQuantity(value.replace(/\D/g,''));
  }

  const submitAction = () => {
    if((!actionQuantity && actionType !== 'scan') || (!activeLocation && actionType !== 'transfer')) { dismissScan(inventorySession.events.length) }

    if (actionQuantity && activeLocation && actionType === 'add') { postAddInventory(payload, scanTerm, inventorySession.events.length, actionQuantity, activeLocation) }
    if (actionQuantity && activeLocation && actionType === 'subtract') { postSubtractInventory(payload, scanTerm, inventorySession.events.length, actionQuantity, activeLocation) }
    if (actionQuantity && activeLocation && actionType === 'set') { postSetInventory(payload, scanTerm, inventorySession.events.length, actionQuantity, activeLocation) }
    if (activeLocation && actionType === 'scan') { postScanInventory(payload, scanTerm, inventorySession.events.length, activeLocation) }
    if (actionQuantity && fromLocation && toLocation && actionType === 'transfer') { postTransferInventory(payload, scanTerm, inventorySession.events.length, actionQuantity, fromLocation, toLocation) }
  }

  const actionChange = (e) => {
    setActionType(e);
  }

  const description = () => {
    return(
      <>
        <Text>Select a location that you're looking to view or modify inventory at from the locations dropdown to get started.</Text>
        <Text>Select an action to perform from the radio buttons.
        Select "Add Inventory" to add the "Change by amount" to the inventory of a scanned product or variant.
        Select "Subtract Inventory" to subtract the "Change by amount" from the inventory of a scanned product or variant.
        Select "Set Inventory" to set the inventory of a scanned product or variant to exactly the "Change by amount".
        Select "View Inventory" to see the inventory of a scanned product or variant.</Text>
        <Text>Select the "Scan a barcode" bar and scan a generated Code 128 SKU (the generated barcodes from the "Labels + Print" section of this app, or similar) with a barcode scanner. This scan should also work with UPCs, EANs or ISBN values if they have been set in products or variants.</Text>
        <Small>Have questions or need some help? Contact us at <a href="mailto:support@athousandapps.com">support@athousandapps.com</a></Small>
      </>
    )
  }

  const sessionDescription = () => {
    return(
      <>
        <Text>See the results of scan actions in this session section. You can clear the session, or download the actions taken by clicking the "Clear session" or "Download session information" buttons on the right.</Text>
        <Text>Individual actions results can be seen below. The results should have SKUs, item information as well as action types and results displayed.</Text>
      </>
    )
  }

  const items = [
    { value: 'add', label: 'Add Inventory' },
    { value: 'subtract', label: 'Subtract Inventory' },
    { value: 'set', label: 'Set Inventory' },
    { value: 'scan', label: 'View Inventory' },
  ];

  const switchLocation = (event, setter) => {
    let location = locations.locations.find(location => location.id === event.hash);
    setter(location);
    setActiveLabel(location.label);
    flashScanBar();
  }

  const locationItems = () => {
    let items = [];

    locations.locations.map(location => 
      items.push(
        {
          content: location.label,
          onItemClick: (event) => switchLocation(event, setActiveLocation),
          hash: location.id
        }
      )
    )

    return items;
  }

  const flashScanBar = () => {
    let scanBarElement = document.querySelector("#scan-bar");

    setTimeout(() => {
      scanBarElement?.classList?.add("blink-action");
    }, 500);

    setTimeout(() => {
      scanBarElement?.classList?.remove("blink-action");
    }, 1500);
  }

  return(
    <>
      <PageHeader stripeBackground='#c66628' helpLink="bigcommerce/live-inventory-modification/" />
      <SettingsPage title="Live Inventory" description={description()}>
        <Panel>
          <H4>Action Type</H4>
          <Toggle
            id="action-type"
            items={items}
            onChange={(e) => actionChange(e)}
            value={actionType}
          />

          <H4>Locations</H4>
          { locations.status !== 'success' &&
            <LoadingSpinner size="xxSmall" statusText="Loading Locations" />
          }

          { (locations.status === 'success') && (actionType !== 'transfer') &&
            <Dropdown
              items={locationItems()}
              maxHeight={250}
              placement="bottom-start"
              toggle={<Button iconRight={<ArrowDropDownIcon />}>{activeLabel || "Select Location"}</Button>}
            />
          }
          <br/>
          { !submitted && (actionType !== 'scan') &&
            <Input
              required
              label="Change by amount"
              onChange={validateQuantity}
              placeholder="Change by amount"
              type="text"
              value={actionQuantity}
            />
          }
          { (submitted || actionType === 'scan') && 
            <Input
              required
              label="Change by amount"
              placeholder="Change by amount"
              type="text"
              value={actionQuantity}
              disabled
            />
          }
          
          <hr/>
          <div className="flex-container">
            <svg width="1.25rem" width="150" height="80" fill="#189dee" viewBox="0 0 32 32" id="svg5" version="1.1">
              <defs id="defs2"/>
              <g id="layer1" transform="translate(-108,-100)">
                <path d="m 111,106 a 1.0001,1.0001 0 0 0 -1,1 v 3 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" id="path11698" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 134,106 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -3 a 1.0001,1.0001 0 0 0 -1,-1 z" id="path11700" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 137,121 a 1,1 0 0 0 -1,1 v 2 h -2 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3 a 1.0001,1.0001 0 0 0 1,-1 v -3 a 1,1 0 0 0 -1,-1 z" id="path11702" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 111,121 a 1,1 0 0 0 -1,1 v 3 a 1.0001,1.0001 0 0 0 1,1 h 3 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 h -2 v -2 a 1,1 0 0 0 -1,-1 z" id="path11704" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 115,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11706" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 118,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11708" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 121,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11710" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 124,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11712" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 127,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11714" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 130,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11716" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 133,110 a 1,1 0 0 0 -1,1 v 5.20703 1.31445 V 121 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 117.52148 116.20703 111 a 1,1 0 0 0 -1,-1 z" id="path11720" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
              </g>
            </svg>
            <div className="flex-1">
              <Input
                id="scan-bar"
                required
                label="Scan Barcode (SKU, UPC, EAN)"
                onChange={updateScan}
                placeholder="Click here to begin scanning"
                type="text"
                value={scanTerm}
                disabled={submitted || !activeLocation}
              />
            </div>
          </div>
        </Panel>
      </SettingsPage>
      <SettingsPage title="" description={sessionDescription()}>
        <Panel>
          { inventorySession.status === 'success' &&
            <>
              <Button iconRight={<DeleteIcon />} onClick={() => clearSession()}>Clear session</Button>
              <Button iconRight={<FileDownloadIcon />} className="left-15" onClick={() => downloadActions()}>Download session information</Button>
            </>
          }
          { inventorySession.status !== 'success' &&
            <>
              <Button iconRight={<DeleteIcon />} onClick={() => clearSession()} disabled>Clear session</Button>
              <Button iconRight={<FileDownloadIcon />} className="left-15" onClick={() => downloadActions()} disabled>Download session information</Button>
            </>
          }

          <hr/>

          { inventorySession.events.toReversed().map(event =>
            <LiveSession
              event={event}
            />
          )}
        </Panel>
      </SettingsPage>
    </>
  )
}

const mapStateToProps = state => ({
  inventorySession: state.inventorySessions,
  locations: state.locations,
});

const mapDispatchToProps = (dispatch) => ({
  postAddInventory: (payload, barcode, actionId, actionQuantity, location) => dispatch(addInventory(payload, barcode, actionId, actionQuantity, location)),
  postSubtractInventory: (payload, barcode, actionId, actionQuantity, location) => dispatch(subtractInventory(payload, barcode, actionId, actionQuantity, location)),
  postSetInventory: (payload, barcode, actionId, actionQuantity, location) => dispatch(setInventory(payload, barcode, actionId, actionQuantity, location)),
  postScanInventory: (payload, barcode, actionId, location) => dispatch(scanInventory(payload, barcode, actionId, location)),
  postTransferInventory: (payload, barcode, actionId, actionQuantity, fromLocation, toLocation) => dispatch(transferInventory(payload, barcode, actionId, actionQuantity, fromLocation, toLocation)),
  dismissScan: (actionId) => dispatch(dismissInventoryScan(actionId)),
  addAction: (barcode, actionType) => dispatch(addPlaceholderAction(barcode, actionType)),
  clearSession: () => dispatch(clearActions()),
  loadLocations: (payload) => dispatch(locationsIndex(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveModify);


                