import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  CATEGORIES_REQUESTED,
  CATEGORIES_SUCCESS,
  CATEGORIES_FAILURE,
} from '../types';

export const categoriesRequested = () => {
  return {
    type: CATEGORIES_REQUESTED
  }
};

export const categoriesSuccess = (settings) => {
  return {
    type: CATEGORIES_SUCCESS,
    payload: settings
  }
};

export const categoriesFailure = () => {
  return {
    type: CATEGORIES_FAILURE
  }
};

export const getCategories = (signedPayload) => {
  return (dispatch) => {
    dispatch(categoriesRequested());

    let endPoint = Config.baseUrl + Config.categories;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(categoriesSuccess(response.data));
      })
  }
}
