import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  SEARCH_PRODUCT_REQUESTED,
  SEARCH_PRODUCT_SUCCESS,
  SEARCH_PRODUCT_FAILURE,
  UPDATE_SELECTED_ITEMS,
  ASSIGN_BARCODE_REQUESTED,
  ASSIGN_BARCODE_SUCCESS,
  ASSIGN_BARCODE_FAILURE,
  SEARCH_PRODUCT_BARCODE_VALUE_REQUESTED,
  SEARCH_PRODUCT_BARCODE_VALUE_SUCCESS,
  SEARCH_PRODUCT_BARCODE_VALUE_FAILURE,
  REPLACE_PRODUCT_SKU_REQUESTED,
  REPLACE_PRODUCT_SKU_SUCCESS,
  REPLACE_PRODUCT_SKU_FAILURE,
  DELETE_PRODUCT_SKU_REQUESTED,
  DELETE_PRODUCT_SKU_SUCCESS,
  DELETE_PRODUCT_SKU_FAILURE,
  DOWNLOAD_PREVIEW_REQUESTED,
  DOWNLOAD_PREVIEW_SUCCESS,
  DOWNLOAD_PREVIEW_FAILURE,
  FIND_BARCODE_VALUE_IMAGE_REQUESTED,
  FIND_BARCODE_VALUE_IMAGE_SUCCESS
} from '../types';

export const searchProductsRequested = () => {
  return {
    type: SEARCH_PRODUCT_REQUESTED
  }
};

export const searchProductsSuccess = (products) => {
  return {
    type: SEARCH_PRODUCT_SUCCESS,
    payload: products
  }
};

export const searchProductsFailure = () => {
  return {
    type: SEARCH_PRODUCT_FAILURE
  }
};

export const setSelectedItems = (items) => {
  return {
    type: UPDATE_SELECTED_ITEMS,
    payload: items
  }
}


export const searchProductBarcodeValueRequested = () => {
  return {
    type: SEARCH_PRODUCT_BARCODE_VALUE_REQUESTED
  }
};

export const searchProductBarcodeValueSuccess = (products) => {
  return {
    type: SEARCH_PRODUCT_BARCODE_VALUE_SUCCESS,
    payload: products
  }
};

export const searchProductSkuFailure = () => {
  return {
    type: SEARCH_PRODUCT_BARCODE_VALUE_FAILURE
  }
};

// export const replaceProductSkuRequested = () => {
//   return {
//     type: REPLACE_PRODUCT_SKU_REQUESTED
//   }
// };

// export const replaceProductSkuSuccess = (item) => {
//   return {
//     type: REPLACE_PRODUCT_SKU_SUCCESS,
//     payload: item
//   }
// };

// export const replaceProductSkuFailure = () => {
//   return {
//     type: REPLACE_PRODUCT_SKU_FAILURE
//   }
// };

// export const deleteProductSkuRequested = () => {
//   return {
//     type: DELETE_PRODUCT_SKU_REQUESTED
//   }
// };

// export const deleteProductSkuSuccess = (item) => {
//   return {
//     type: DELETE_PRODUCT_SKU_SUCCESS,
//     payload: item
//   }
// };

// export const deleteProductSkuFailure = () => {
//   return {
//     type: DELETE_PRODUCT_SKU_FAILURE
//   }
// };

export const downloadPreviewRequested = () => {
  return {
    type: DOWNLOAD_PREVIEW_REQUESTED
  }
};

export const downloadPreviewSuccess = (previewUrl) => {
  return {
    type: DOWNLOAD_PREVIEW_SUCCESS,
    payload: previewUrl
  }
};

export const downloadPreviewFailure = () => {
  return {
    type: DOWNLOAD_PREVIEW_FAILURE
  }
};

export const findBarcodeValueImageRequested = (barcodeValue) => {
  return {
    type: FIND_BARCODE_VALUE_IMAGE_REQUESTED,
    payload: barcodeValue
  }
}

export const findBarcodeValueImageSuccess = (result) => {
  return {
    type: FIND_BARCODE_VALUE_IMAGE_SUCCESS,
    payload: result
  }
}

export const searchProducts = (signedPayload, searchTerm, inStockFilter, purchasingDisabledFilter, categoryFilter, page) => {
  return (dispatch) => {
    dispatch(searchProductsRequested());

    let endPoint = Config.baseUrl + Config.productSearch;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    let body = {
      'term': searchTerm,
      'in_stock': inStockFilter,
      'disabled': purchasingDisabledFilter,
      'category': categoryFilter,
      'page': page
    }

    return Axios.post(endPoint, body, { headers: headers })
      .then((response) => {
        dispatch(searchProductsSuccess(response.data));
      });
  }
}

export const updateSelectedItems = (items) => {
  return (dispatch) => {
    dispatch(setSelectedItems(items));
  }
}

export const searchProductBarcodeValue = (signedPayload, barcode) => {
  return (dispatch) => {
    dispatch(searchProductBarcodeValueRequested());

    let endPoint = Config.baseUrl + Config.sku + Config.search;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.post(endPoint, { 'barcode': barcode } , { headers: headers })
      .then((response) => {
        dispatch(searchProductBarcodeValueSuccess(response.data));
      });
  }
}

// export const replaceProductSku = (signedPayload, sku, replacement) => {
//   return (dispatch) => {
//     dispatch(replaceProductSkuRequested());

//     let endPoint = Config.baseUrl + Config.sku + Config.replace;
//     let headers = Object.assign(Config.headers);
//     headers.payload = signedPayload;

//     return Axios.post(endPoint, { 'sku': sku, 'replacement': replacement } , { headers: headers })
//       .then((response) => {
//         dispatch(replaceProductSkuSuccess(response.data));
//       });
//   }
// }

// export const deleteProductSku = (signedPayload, sku) => {
//   return (dispatch) => {
//     dispatch(deleteProductSkuRequested());

//     let endPoint = Config.baseUrl + Config.sku + Config.delete;
//     let headers = Object.assign(Config.headers);
//     headers.payload = signedPayload;

//     return Axios.post(endPoint, { 'sku': sku } , { headers: headers })
//       .then((response) => {
//         dispatch(deleteProductSkuSuccess(response.data));
//       });
//   }
// }

export const downloadBarcodePreview = (signedPayload) => {
  return (dispatch) => {
    dispatch(downloadPreviewRequested());

    let endPoint = Config.baseUrl + Config.sku + Config.download;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(downloadPreviewSuccess(response.data));
      });
  }
}

export const findBarcodeValueImage = (signedPayload, itemId, barcodeValue) => {
  return (dispatch) => {
    dispatch(findBarcodeValueImageRequested(barcodeValue));

    let endPoint = Config.baseUrl + Config.barcode + Config.imageCheck + '?barcode=' + barcodeValue + '&itemId=' + itemId;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(findBarcodeValueImageSuccess(response.data));
      });
  }

}