import React from 'react';

import SettingsPage from '../partials/SettingsPage';
import { Text, H2, Panel } from '@bigcommerce/big-design';

const MainError = () => {
  return(
    <SettingsPage title="Barcode Inventory Management" description="">
      <Panel>
        <H2>Failed to load payment</H2>
        <Text>Unable to resolve your store's subscription. If you're seeing this message, please contact support at <a href="mailto:support@athousandapps.com">support@athousandapps.com</a>.</Text>
        <Text>Sorry for the inconvenience.</Text>
      </Panel>
    </SettingsPage>
  )
}

export default MainError;
