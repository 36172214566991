import {
  ADD_INVENTORY_REQUESTED,
  ADD_INVENTORY_SUCCESS,
  ADD_INVENTORY_FAILURE,
  SUBTRACT_INVENTORY_REQUESTED,
  SUBTRACT_INVENTORY_SUCCESS,
  SUBTRACT_INVENTORY_FAILURE,
  SET_INVENTORY_REQUESTED,
  SET_INVENTORY_SUCCESS,
  SET_INVENTORY_FAILURE,
  SCAN_INVENTORY_REQUESTED,
  SCAN_INVENTORY_SUCCESS,
  SCAN_INVENTORY_FAILURE,
  ADD_PLACEHOLDER_ACTION,
  TRANSFER_INVENTORY_REQUESTED,
  TRANSFER_INVENTORY_SUCCESS,
  TRANSFER_INVENTORY_FAILURE,
  CLEAR_SESSION,
  CLEAR_ACTION,
} from '../types';


const INITIAL_STATE = {
  isFetching: false,
  events: [],
  status: 'waiting',
  errors: null
};

const inventorySessions = (state = INITIAL_STATE, action) => {
  const newState = { ...state };

  switch(action.type) {
    case ADD_PLACEHOLDER_ACTION:
      return Object.assign({}, state, {
        events: [...state.events, { actionType: action.actionType, sku: action.barcode, placeholder: true }]
      });

    case ADD_INVENTORY_SUCCESS:
    case SCAN_INVENTORY_SUCCESS:
    case SUBTRACT_INVENTORY_SUCCESS:
    case SET_INVENTORY_SUCCESS:
    case TRANSFER_INVENTORY_SUCCESS:
      newState.isFetching = false;
      newState.status = 'success';
      newState.errors = null;
      if(newState.events[action.payload.actionId]){
        newState.events[action.payload.actionId] = action.payload.item;
      }
      return newState;

    case ADD_INVENTORY_FAILURE:
    case SET_INVENTORY_FAILURE:
    case SCAN_INVENTORY_FAILURE:
    case SUBTRACT_INVENTORY_FAILURE:
    case TRANSFER_INVENTORY_FAILURE:
      newState.isFetching = false;
      newState.status = 'waiting';
      newState.errors = action.error.error;
      if(newState.events[action.error.actionId]){
        newState.events[action.error.actionId] = action.error;
      }
      return newState;

    case ADD_INVENTORY_REQUESTED:
    case SUBTRACT_INVENTORY_REQUESTED:
    case SET_INVENTORY_REQUESTED:
    case SCAN_INVENTORY_REQUESTED:
    case TRANSFER_INVENTORY_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        status: 'requesting',
        errors: null,
      });

    case CLEAR_SESSION:
      return INITIAL_STATE;

    case CLEAR_ACTION:
      newState.errors = 'No value set';
      newState.events[action.actionId] = 'No value set';
      return newState;

    default:
      return state;
  }
}

export default inventorySessions;