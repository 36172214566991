import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { Text, Input, Dropdown, Panel, H4, Button, Textarea } from '@bigcommerce/big-design';
import { FileDownloadIcon } from '@bigcommerce/big-design-icons';

const SubmittedMessage = () => {
  return(
    <Panel>
      <H4>Changes Submitted</H4>
      <Text>Your changes have been submitted. Click the View Results button to see the results of these changes.</Text>
      <Link to='../../update_results' relative="path">
        <Button iconRight={<FileDownloadIcon />}>View Results</Button>
      </Link>
    </Panel>
  )
}

const mapStateToProps = state => ({
  bulkInventorySession: state.bulkInventorySessions,
});

const mapDispatchToProps = (dispatch) => ({

});


export default connect(mapStateToProps, mapDispatchToProps)(SubmittedMessage);