import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Message } from '@bigcommerce/big-design';

const GeneratingBarcodesNotice = ({ notice }) => {
  const noticeCard = (header, message) => {
    return(
      <Message
        header={header}
        marginVertical="medium"
        messages={[{ text: message }]}
        type="info"
      />
    )
  }

  if(notice.product_barcode_values) {
    return(noticeCard('Generating barcodes', `Generating ${notice.product_barcode_values.length} product barcodes, ${notice.variation_barcode_values.length} variant barcodes.`))
  }

  return(noticeCard('Generating barcodes', 'Generating all product and variant barcodes.'))
}

export default GeneratingBarcodesNotice;
