import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  ADD_BULK_PLACEHOLDER_ACTION,
  CLEAR_BULK_SESSION,
  BULK_INVENTORY_CHANGE_REQUESTED,
  BULK_INVENTORY_CHANGE_SUCCESS,
  BULK_INVENTORY_CHANGE_FAILURE,
  BATCH_DETAILS_REQUESTED,
  BATCH_DETAILS_SUCCESS,
  BATCH_DETAILS_FAILURE,
} from '../types';

export const bulkInventoryChangeRequested = () => {
  return {
    type: BULK_INVENTORY_CHANGE_REQUESTED
  }
};

export const bulkInventoryChangeSuccess = (payload) => {
  return {
    type: BULK_INVENTORY_CHANGE_SUCCESS,
    payload: payload
  }
};

export const bulkInventoryChangeFailure = (error) => {
  return {
    type: BULK_INVENTORY_CHANGE_FAILURE,
    error: error
  }
};

export const batchDetailsRequested = () => {
  return {
    type: BATCH_DETAILS_REQUESTED
  }
};

export const batchDetailsSuccess = (payload) => {
  return {
    type: BATCH_DETAILS_SUCCESS,
    payload: payload
  }
};

export const batchDetailsFailure = (error) => {
  return {
    type: BATCH_DETAILS_FAILURE,
    error: error
  }
};


export const addBulkPlaceholder = (barcode) => {
  return {
    type: ADD_BULK_PLACEHOLDER_ACTION,
    barcode: barcode
  }
}

export const clearBulkSessionActions = () => {
  return {
    type: CLEAR_BULK_SESSION
  }
}

export const addBulkPlaceholderAction = (barcode) => {
  return (dispatch) => {
    dispatch(addBulkPlaceholder(barcode));
  }
}

export const clearBulkActions = () => {
  return (dispatch) => {
    dispatch(clearBulkSessionActions());
  }
}

export const publishBulkActions = (signedPayload, type, actions, comments, location) => {
  return (dispatch) => {
    dispatch(bulkInventoryChangeRequested());

    let endPoint = Config.baseUrl + Config.bulkInventory;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.post(endPoint, { type: type, actions: actions, comments: comments, location: location }, { headers: headers })
      .then((response) => {
        dispatch(bulkInventoryChangeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(bulkInventoryChangeFailure(error.response.data));
      });
  }
}

export const getBatchDetails = (signedPayload, batchId) => {
  return (dispatch) => {
    dispatch(batchDetailsRequested());

    let endPoint = Config.baseUrl + Config.bulkInventory + Config.batchResults;
    let headers = Object.assign(Config.headers);

    return Axios.post(endPoint, { batchId: batchId }, { headers: headers })
      .then((response) => {
        dispatch(batchDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(batchDetailsFailure(error.response.data));
      });
  }
}
