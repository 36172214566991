import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { Text, Panel, H3, H4, Button, Select } from '@bigcommerce/big-design';
import { OpenInNewIcon, AddIcon, ReceiptIcon, CheckIcon } from '@bigcommerce/big-design-icons';
import { indexFulfillmentStatuses, updateFulfillmentStatus } from '../../redux/actions/pickOrder';

import LoadingSpinner from '../partials/LoadingSpinner';

const SubmittedMessage = ({ payload, account, formatPriceValue, orderSession, resetSession, stores, pickOrder, loadFulfillmentStatuses, updateStatus, orderStatuses, orderFulfillmentMessage }) => {
  const [statusList, setStatusList] = useState();
  const [selectedStatus, setSelectedStatus] = useState('');

  useEffect(() => {
    loadFulfillmentStatuses(payload);
  }, []);

  useEffect(() => {
    let statuses = [];

    orderStatuses.forEach((status) => {
      statuses.push({ value: status.id, content: status.name })
    });

    setStatusList(statuses);
  }, [orderStatuses]);

  const updateOrderStatus = () => {
    updateStatus(payload, orderSession.orderId, selectedStatus);
  }

  return (
    <Panel>
      { orderSession.orderId &&
        <>
          <div className="flex-container">

            <div className="flex-1 border-right">
              <H3>Order Actions</H3>
              <H4>Update Order Status</H4>
              <div className="bottom-5">
                { pickOrder.orderStatuses.length > 0 && !pickOrder.orderFulfillmentFetching &&
                  <>
                    <Select
                      maxHeight={300}
                      onOptionChange={setSelectedStatus}
                      options={statusList}
                      placeholder="Order Status"
                      value={selectedStatus}
                    />
                    <div className="top-5">
                      <Button variant="secondary" onClick={() => updateOrderStatus()} disabled={pickOrder.status === "requesting"}>Update Order Status</Button>
                      {orderFulfillmentMessage && <Text color="primary40">{orderFulfillmentMessage}</Text>}
                    </div>
                  </>
                }
                { pickOrder.orderStatuses.length === 0 && pickOrder.orderFulfillmentFetching &&
                  <LoadingSpinner
                    statusText='Loading fulfillment statuses'
                  />
                }
              </div>

              <hr/>
              <H4>Next Steps</H4>
              <div className="bottom-5">
                <a target="_blank" href={`${stores.settings.control_panel_base_url}/admin/index.php?ToDo=printOrderInvoice&orderId=${orderSession.orderId}`}>
                  <Button variant="secondary" iconRight={<ReceiptIcon />}>Print Invoice</Button>
                </a>
              </div>

              <div className="bottom-5">
                <a target="_blank" href={`${stores.settings.control_panel_base_url}/admin/index.php?ToDo=printShipmentPackingSlips&orderId=${orderSession.orderId}`}>
                  <Button variant="secondary" iconRight={<ReceiptIcon />}>Print Packing Slip</Button>
                </a>
              </div>

              <div className="bottom-5">
                <Link to={`/pick/${orderSession.orderId}`}>
                  <Button variant="secondary" iconRight={<OpenInNewIcon />}>Pick and Verify Order</Button>
                </Link>
              </div>
            </div>

            <div className="flex-1 left-15">
              <H4>Totals</H4>
              <table>
                <tr>
                  <td>Subtotal:</td>
                  <td className="align-right">{formatPriceValue(orderSession.order.subtotal_ex_tax)}</td>
                </tr>
                <tr>
                  <td>Shipping:</td>
                  <td className="align-right">{formatPriceValue(orderSession.order.shipping_cost_ex_tax)}</td>
                </tr>
                <tr>
                  <td>Tax:</td>
                  <td className="align-right">{formatPriceValue(orderSession.order.total_tax)}</td>
                </tr>
                <tr>
                  <td><strong>Grand Total:</strong></td>
                  <td className="align-right">{formatPriceValue(orderSession.order.total_inc_tax)}</td>
                </tr>
              </table>

              <hr/>

              <H4>Other Actions</H4>

              <div className="bottom-5">
                <a target="_blank" href={`${stores.settings.control_panel_base_url}/manage/orders/${orderSession.orderId}`}>
                  <Button variant="secondary" iconRight={<OpenInNewIcon />}>View Order {`${orderSession.orderId}`}</Button>
                </a>
              </div>
              <div className="bottom-5">
                <Button onClick={() => resetSession()} iconRight={<AddIcon />}>New Order</Button>
              </div>
            </div>
          </div>
        </>
      }
      { !orderSession.orderId &&
        <LoadingSpinner size="xxSmall" statusText="Creating Order" />
      }
    </Panel>
  )
}

const mapStateToProps = state => ({
  stores: state.stores,
  orderSession: state.orderSessions,
  pickOrder: state.pickOrder,
  orderStatuses: state.pickOrder.orderStatuses,
  orderFulfillmentMessage: state.pickOrder.orderFulfillmentMessage,
});

const mapDispatchToProps = (dispatch) => ({
  loadFulfillmentStatuses: (payload) => dispatch(indexFulfillmentStatuses(payload)),
  updateStatus: (payload, orderId, status) => dispatch(updateFulfillmentStatus(payload, orderId, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmittedMessage);


// control_panel_base_url is available on the store information, switch to that