import { Link } from "react-router-dom";

import { Button, Text, H2, Box } from '@bigcommerce/big-design';

const NoLabelTemplatesBox = ({ }) => {
  return(
    <Box
      backgroundColor="warning20"
      border="box"
      borderRadius="normal"
      padding="medium"
      shadow="raised"
    >
      <H2>Create Label Template</H2>
      <Text bold>
        You don't have any label templates saved.
      </Text>
      <a target="_blank" href="https://athousandapps.com/bigcommerce/manage-barcode-label-templates-2/">
        Read about label templates here
      </a>
      <br/>
      <br/>
      <Link to='/templates/new'>
        <Button variant="primary">Create a label template</Button>
      </Link>
    </Box>
  )
}

export default NoLabelTemplatesBox;
