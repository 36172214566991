import { Text, H3, H4, Input, Button } from '@bigcommerce/big-design';

import { DeleteIcon } from '@bigcommerce/big-design-icons';

const LineItem = ({ lineEvent, lineItems, lineItemValues, setValue, actionId, submitted, removeItem, formatPriceValue }) => {
  const validateValue = (event) => {
    let value = event.target.value.replace(/\D/g,'');
    setValue(value, lineEvent);
  }

  if(lineEvent.error) {
    return(
      <div className="has-hover sku-line flex-container">
        <div className="flex-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="175" height="50" fill="none" viewBox="0 0 18 18"><circle cx="9" cy="9" r="8" stroke="#F43C36" fill="none" stroke-width="2"/><path fill="#F43C36" d="M8.998 3.904a.908.908 0 0 0-.384.08.985.985 0 0 0-.325.237 1.098 1.098 0 0 0-.216.358c-.05.134-.075.278-.073.422v4.802c0 .29.105.57.292.776a.955.955 0 0 0 .706.321.955.955 0 0 0 .706-.321c.187-.206.293-.485.293-.776V5a1.108 1.108 0 0 0-.053-.434 1.032 1.032 0 0 0-.215-.37.918.918 0 0 0-.336-.235.847.847 0 0 0-.395-.058ZM9 14.1a1.1 1.1 0 1 0 0-2.2 1.1 1.1 0 0 0 0 2.2Z"/></svg>
        </div>

        <div className="flex-2">
          <H3>Error</H3>
          <Text>Error description: { lineEvent.error }</Text>
        </div>

        <div className="flex-2">
        </div>

        <div className="flex-1">
        </div>
      </div>
    )
  }

  return (
    <div className="has-hover sku-line flex-container">
      <div className="flex-1">
        { (lineEvent.primary_image || lineEvent.image_url) && <img className="live-row-image" src={lineEvent.image_url || lineEvent.primary_image?.url_thumbnail} /> }
        { !(lineEvent.primary_image || lineEvent.image_url) && <svg className="live-row-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123 123"><g stroke="#c2cbd3" stroke-miterlimit="10"><path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M77.46 22.7H48.28l2.02-7.04h25.15zm0 43.26H48.28l2.02 7.05h25.15z"/><path fill="#edeff2" d="M52.63 7.59h20.48v74.29H52.63z"/><path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M91.903 39.907v8.59h-3.82v-8.59z"/><ellipse cx="63.08" cy="44.21" fill="#fff" stroke-linecap="round" stroke-linejoin="round" rx="25.76" ry="25.53"/><g fill="#fff" stroke-linecap="round" stroke-linejoin="round"><ellipse cx="63.08" cy="44.13" rx="21.8" ry="21.6"/><path d="M51.34 33.05l11.91 11.24 7.47-3.93"/><ellipse cx="63.08" cy="44.13" rx="1.78" ry="1.76"/></g></g></svg> }
      </div>

      <div className="flex-2">
        <Button variant="subtle" actionType="destructive" onClick={() => removeItem(lineEvent, actionId)} iconOnly={<DeleteIcon title="remove" />} disabled={submitted}/>
        <Text>
          <b>SKU</b>: {lineEvent.sku}
          <br/>
          <b>Quantity In Stock</b>: {lineEvent.starting_inventory}
        </Text>
      </div>
      <div className="flex-2">
        <ul>
          { lineEvent.option_values && lineEvent.option_values.map(option =>
              <li>
                <Text><b>{ option.option_display_name }</b>: { option.label }</Text>
              </li>
            )
          }

          <li>
            <Text><b>Price</b>: { formatPriceValue(lineEvent.price || lineEvent.calculated_price || 0.0) }</Text>
          </li>
          
          { ((lineEvent.option_values && lineEvent.option_values.length === 0) || (!lineEvent.option_values)) &&
            <Text color="warning60">
              Base Product
            </Text>
          }
        </ul>
      </div>

      <div className="flex-1">
        { !lineEvent.placeholder &&
          <>
            { !submitted && 
              <Input
                label="Order Quantity"
                onChange={validateValue}
                placeholder="Modify Quantity"
                type="text"
                required
                value={lineItemValues[lineEvent.sku] || ''}
              />
            }
            { submitted &&
              <Input
                label="Order Quantity"
                placeholder="Modify Quantity"
                type="text"
                required
                value={lineItemValues[lineEvent.sku] || ''}
                disabled
              />
            }
          </>
        }
      </div>
    </div>
  )
}

export default LineItem;