import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Button, Text, Panel, Switch, H3, Fieldset, FormGroup, Radio } from '@bigcommerce/big-design';

import { updateSettings } from '../../redux/actions/settings';
import PageHeader from '../partials/PageHeader';
import SettingsPage from '../partials/SettingsPage';

import UploadStoreLogo from './UploadStoreLogo';

const StoreSettings = ({ payload, settings, submitSettings }) => {
  const [submitted, setSubmitted] = useState(false);
  const [settingsSaved, setSettingsSaved] = useState(true);

  const [settingsForm, setSettingsForm] = useState(settings);
  const [allowQuantityUpdates, setAllowQuantityUpdates] = useState(settings['allow_quantity_updates']);

  const [barcodeImageGenerationField, setBarcodeImageGenerationField] = useState(settings['barcode_image_generation_field']);
  const [barcodeImageSymbolType, setBarcodeImageSymbolType] = useState(settings['barcode_image_symbol_type']);
  const [barcodeSymbolType, setBarcodeSymbolType] = useState(settings['barcode_symbol_field']);

  useEffect(() => {
    setSubmitted(false);
  }, [settings]);

  const toggleAllowQuantitySwitch = () => {
    let updatedSettings = settingsForm;
    updatedSettings['allow_quantity_updates'] = !allowQuantityUpdates;

    setAllowQuantityUpdates(!allowQuantityUpdates);
    setSettingsForm(updatedSettings);
    setSettingsSaved(false);
  }

  const handleImageGenerationField = (event) => {
    let updatedSettings = settingsForm;
    updatedSettings['barcode_image_generation_field'] = event.target.value;

    setBarcodeImageGenerationField(event.target.value);
    setSettingsForm(updatedSettings);
    setSettingsSaved(false);
  }

  const handleImageSymbolType = (event) => {
    let updatedSettings = settingsForm;
    updatedSettings['barcode_image_symbol_type'] = event.target.value;

    setBarcodeImageSymbolType(event.target.value);
    setSettingsForm(updatedSettings);
    setSettingsSaved(false);
  }

  const handleSymbolType = (event) => {
    let updatedSettings = settingsForm;
    updatedSettings['barcode_symbol_field'] = event.target.value;

    setBarcodeSymbolType(event.target.value);
    setSettingsForm(updatedSettings);
    setSettingsSaved(false);
  }


  const handleSubmit = () => {
    setSubmitted(true);
    console.log(settingsForm);

    submitSettings(payload, settingsForm);
    setSettingsSaved(true);
  }

  return(
    <>
      <PageHeader stripeBackground='#ef8f2f' helpLink="bigcommerce/" />
      <SettingsPage title="App Settings" description={""}>
        <UploadStoreLogo 
          payload={payload}
        />
        <hr/>
        <Panel>
          <H3>Allow Pick Order Quantity Updates</H3>
          <Switch checked={allowQuantityUpdates} onChange={toggleAllowQuantitySwitch} />
        </Panel>
        <Panel>
          <H3>Barcode Settings</H3>

          <Fieldset legend="Barcode Image Generation Field">
            <FormGroup>
              <Radio
                checked={!barcodeImageGenerationField || barcodeImageGenerationField === 'sku'}
                label="SKU"
                onChange={handleImageGenerationField}
                value="sku"
              />
              <Radio
                checked={barcodeImageGenerationField === 'upc'}
                label="UPC/EAN"
                onChange={handleImageGenerationField}
                value="upc"
              />
            </FormGroup>
          </Fieldset>
          <Fieldset legend="Barcode Image Type">
            <FormGroup>
              <Radio
                checked={!barcodeImageSymbolType || barcodeImageSymbolType === 'code128'}
                label="Code 128"
                onChange={handleImageSymbolType}
                value="code128"
              />
              <Radio
                checked={barcodeImageSymbolType === 'upca'}
                label="UPCA"
                onChange={handleImageSymbolType}
                value="upca"
              />
              <Radio
                checked={barcodeImageSymbolType === 'ean13'}
                label="EAN13"
                onChange={handleImageSymbolType}
                value="ean13"
              />
              <Radio
                checked={barcodeImageSymbolType === 'ean8'}
                label="EAN8"
                onChange={handleImageSymbolType}
                value="ean8"
              />
            </FormGroup>
          </Fieldset>
          <Fieldset legend="Barcode Symbol Field">
            <FormGroup>
              <Radio
                checked={!barcodeSymbolType || barcodeSymbolType === 'sku'}
                label="SKU"
                onChange={handleSymbolType}
                value="sku"
              />
              <Radio
                checked={barcodeSymbolType === 'upc'}
                label="UPC/EAN"
                onChange={handleSymbolType}
                value="upc"
              />
            </FormGroup>
          </Fieldset>
        </Panel>
        <Panel>
          <H3>Payment Settings and Invoices</H3>
          <a href="https://billing.stripe.com/p/login/8wM8z1fy45M0bfi8ww" target="_blank">View Stripe Customer Portal</a>
        </Panel>
        <Panel>
          <Button onClick={() => handleSubmit()} disabled={submitted || settingsSaved}>Save Settings</Button>
          { !settingsSaved &&
            <Text color="warning50">Settings changes have not been saved</Text>
          }
        </Panel>
      </SettingsPage>
    </>
  )
}

const mapStateToProps = state => ({
  stores: state.stores,
  settings: state.settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
  submitSettings: (payload, newSettings) => dispatch(updateSettings(payload, newSettings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreSettings);