import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  CREATE_ORDER_ACTION,
  DELETE_ORDER_ACTION,
  CLEAR_ORDER_ACTIONS,
  PUBLISH_ORDER_REQUESTED,
  PUBLISH_ORDER_SUCCESS,
  PUBLISH_ORDER_FAILURE,
} from '../types';

export const addLineItemPlaceholder = (barcode, actionId) => {
  return {
    type: CREATE_ORDER_ACTION,
    barcode: barcode,
    actionId: actionId
  }
}

export const deleteItem = (actionId) => {
  return {
    type: DELETE_ORDER_ACTION,
    actionId: actionId
  }
}

export const clearOrderActions = () => {
  return {
    type: CLEAR_ORDER_ACTIONS
  }
}

export const publishOrderRequested = () => {
  return {
    type: PUBLISH_ORDER_REQUESTED
  }
};

export const publishOrderSuccess = (payload) => {
  return {
    type: PUBLISH_ORDER_SUCCESS,
    payload: payload
  }
};

export const publishOrderFailure = (payload) => {
  return {
    type: PUBLISH_ORDER_FAILURE,
    error: payload.error
  }
};

export const addLineItem = (barcode, actionId) => {
  return (dispatch) => {
    dispatch(addLineItemPlaceholder(barcode, actionId));
  }
}

export const deleteLineItem = (actionId) => {
  return (dispatch) => {
    dispatch(deleteItem(actionId));
  }
}

export const clearOrderSession = () => {
  return (dispatch) => {
    dispatch(clearOrderActions());
  }
}

export const publishNewOrder = (signedPayload, publishCounts, location) => {
  return (dispatch) => {
    dispatch(publishOrderRequested());

    let endPoint = Config.baseUrl + Config.order;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.post(endPoint, { counts: publishCounts, location: location } , { headers: headers })
      .then((response) => {
        dispatch(publishOrderSuccess(response.data));
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
        dispatch(publishOrderFailure(error.response.data));
      });
  }
}
