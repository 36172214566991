import {
  PRINT_CREATE_REQUESTED,
  PRINT_CREATE_SUCCESS,
  PRINT_CREATE_FAILURE,
} from '../types';

const INITIAL_STATE = {
  isFetching: false,
  downloadUrl: '',
  status: 'waiting',
  errors: null
};


const prints = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case PRINT_CREATE_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        downloadUrl: null,
        status: 'requesting',
        errors: null,
      });

    case PRINT_CREATE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'success',
        downloadUrl: action.payload.downloadUrl,
        errors: null
      });

    case PRINT_CREATE_FAILURE:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'waiting',
        downloadUrl: null,
        errors: 'error here'
      });

    default:
      return state;
  }
}

export default prints;