import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

import SettingsPage from '../partials/SettingsPage';
import LoadingSpinner from '../partials/LoadingSpinner';
import PageHeader from '../partials/PageHeader';
import { Button, Text, Panel, Input, Small, H2, H3, Search, Select, H4, FormControlLabel, Switch } from '@bigcommerce/big-design';
import { ReceiptIcon } from '@bigcommerce/big-design-icons';

import LineItem from "./LineItem";

import CorrectScanDialog from "./CorrectScanDialog";
import IncorrectScanDialog from "./IncorrectScanDialog";
import RepeatScanDialog from "./RepeatScanDialog";
import ConfirmOrderUpdateDialog from "./ConfirmOrderUpdateDialog";

import { readOrder, setCheckedList, setUnpickableList, clearOrder, indexFulfillmentStatuses, updateFulfillmentStatus, updateLineItemQuantity } from '../../redux/actions/pickOrder';

const PickOrder = ({ payload, pickOrder, lineItems, fulfillmentStatus, searchOrder, pushCheckedList, pushUnpickableList, resetOrder, loadFulfillmentStatuses, updateStatus, orderStatuses, updateItemQuantity, stores }) => {
  let { orderId } = useParams();

  const [searchSubmitted, setSearchSubmitted] = useState(false);

  const [displayCorrect, setDisplayCorrect] = useState(false);
  const [displayIncorrect, setDisplayIncorrect] = useState(false);
  const [displayRepeat, setDisplayRepeat] = useState(false);

  const [dialogQuantity, setDialogQuantity] = useState(0);
  const [dialogLineItemId, setDialogLineItemId] = useState();
  const [displayConfirmOrderUpdate, setDisplayConfirmOrderUpdate] = useState(false);

  const [orderScan, setOrderScan] = useState('');
  const [scanTerm, setScanTerm] = useState('');

  const [selectedStatus, setSelectedStatus] = useState('');

  const [statusList, setStatusList] = useState();

  const [hidePickedItems, setHidePickedItems] = useState(true);

  const updateOrder = (event) => {
    let value = event.target.value;
    setOrderScan(value);
  }

  const updateScan = (event) => {
    let value = event.target.value;
    setScanTerm(value);
  }

  const submitSearch = () => {
    searchOrder(payload, orderScan);
    setSearchSubmitted(true);
  }

  const toggleChecked = (lineItemId) => {
    let newChecked = pickOrder.checkedItems;

    if(pickOrder.checkedItems.includes(lineItemId)) {
      newChecked.splice(pickOrder.checkedItems.indexOf(lineItemId), 1);
    } else {
      newChecked.push(lineItemId);
    }

    pushCheckedList(newChecked);
  }

  const switchUnpickable = (lineItemId) => {
    let newUnpickable = pickOrder.unpickableItems;

    if(pickOrder.unpickableItems.includes(lineItemId)) {
      newUnpickable.splice(pickOrder.unpickableItems.indexOf(lineItemId), 1);
    } else {
      newUnpickable.push(lineItemId);
    }

    pushUnpickableList(newUnpickable);
  }

  const focusScanArea = () => {
    let area = document.getElementById('scan-bar');
    area.focus();
  }

  const reset = () => {
    resetOrder();
    setOrderScan('');
    setSearchSubmitted(false);
  }

  const updateOrderStatus = () => {
    updateStatus(payload, pickOrder.orderId, selectedStatus);
    reset();
  }

  useEffect(() => {
    if(pickOrder.orderId){
      flashScanBar();
    }
  }, [pickOrder]);

  useEffect(() => {
    if(orderId) {
      searchOrder(payload, orderId);
      setSearchSubmitted(true);
    }

    loadFulfillmentStatuses(payload);
  }, []);

  useEffect(() => {
    console.log(fulfillmentStatus);
    setSelectedStatus(fulfillmentStatus);
  }, [fulfillmentStatus]);

  useEffect(() => {
    let statuses = [];

    orderStatuses.forEach((status) => {
      statuses.push({ value: status.id, content: status.name })
    });

    setStatusList(statuses);
  }, [orderStatuses]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(scanTerm !== ''){
        let matchingLine = lineItems.find(lineItem => { return ((lineItem.sku === scanTerm) || (lineItem.upc === scanTerm)); });
        let confirmId = '';

        if(!matchingLine) {
          setDisplayIncorrect(true);
        } else {
          setDialogQuantity(matchingLine.quantity);


          if(pickOrder.checkedItems.includes(matchingLine.line_item_id)) {
            setDisplayRepeat(true);
          } else {
            setDialogLineItemId(matchingLine.line_item_id);
            setDisplayCorrect(true);
            toggleChecked(matchingLine.line_item_id);
          }
        }

        setScanTerm('');
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [scanTerm, 500]);

  const flashScanBar = () => {
    let scanBarElement = document.querySelector("#scan-bar");

    setTimeout(() => {
      scanBarElement?.classList?.add("blink-action");
    }, 500);

    setTimeout(() => {
      scanBarElement?.classList?.remove("blink-action");
    }, 1500);
  }

  const description = () => {
    return(
      <> 
        <Text>Enter an order number or scan an order barcode to load the order.</Text>
        <Small>Have questions or need some help? Contact us at <a href="mailto:support@athousandapps.com">support@athousandapps.com</a></Small>
      </>
    )
  }

  const pickDescription = () => {
    return(
      <> 
        <Text>Select the "Scan a barcode" bar and scan a generated Code 128 SKU (the generated barcodes from the "Labels + Print" section of this app, or similar) with a barcode scanner. This scan should also work with UPCs, EANs or ISBN values if they have been set in products or variations.</Text>
        <Small>Have questions or need some help? Contact us at <a href="mailto:support@athousandapps.com">support@athousandapps.com</a></Small>
      </>
    )
  }

  if(!pickOrder.orderId) {
    return(
      <>
        <PageHeader stripeBackground='#070808' helpLink="bigcommerce/pick-and-verify-orders-with-bigcommerce/" />
        <SettingsPage title="Pick and Verify Orders" description={description()}>
          <Panel>
            <H2>Order Number</H2>
            <div className="flex-container">
              <div className="flex-1">
                <Search
                  onChange={updateOrder}
                  onSubmit={() => submitSearch()}
                  value={orderScan}
                  disabled={pickOrder.isFetching}
                />
              </div>
            </div>
            { pickOrder.errors &&
              <Text color="danger50">
                { pickOrder.errors }
              </Text>
            }
          </Panel>
        </SettingsPage>
      </>
    )
  }

  return(
    <>
      <PageHeader stripeBackground='#070808' helpLink="bigcommerce/pick-and-verify-orders-with-bigcommerce/" />
      <SettingsPage title="Pick and Verify Orders" description={pickDescription()}>
        <Panel>
          <div className="flex-container">
            <svg width="1.25rem" width="150" height="80" fill="#189dee" viewBox="0 0 32 32" id="svg5" version="1.1">
              <defs id="defs2"/>
              <g id="layer1" transform="translate(-108,-100)">
                <path d="m 111,106 a 1.0001,1.0001 0 0 0 -1,1 v 3 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" id="path11698" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 134,106 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -3 a 1.0001,1.0001 0 0 0 -1,-1 z" id="path11700" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 137,121 a 1,1 0 0 0 -1,1 v 2 h -2 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3 a 1.0001,1.0001 0 0 0 1,-1 v -3 a 1,1 0 0 0 -1,-1 z" id="path11702" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 111,121 a 1,1 0 0 0 -1,1 v 3 a 1.0001,1.0001 0 0 0 1,1 h 3 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 h -2 v -2 a 1,1 0 0 0 -1,-1 z" id="path11704" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 115,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11706" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 118,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11708" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 121,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11710" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 124,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11712" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 127,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11714" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 130,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11716" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 133,110 a 1,1 0 0 0 -1,1 v 5.20703 1.31445 V 121 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 117.52148 116.20703 111 a 1,1 0 0 0 -1,-1 z" id="path11720" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
              </g>
            </svg>
            <div className="flex-1">
              <Input
                id="scan-bar"
                required
                label="Scan Barcode (SKU, UPC, EAN)"
                onChange={updateScan}
                placeholder="Click here to begin scanning"
                type="text"
                value={scanTerm}
                inputMode='none'
              />
              <div className="top-5">
                <Button variant="secondary" onClick={() => reset()}>
                  Clear Order
                </Button>
              </div>

              <br/>

              <FormControlLabel renderOptional={false}>Hide Picked Items</FormControlLabel>
              <Switch checked={hidePickedItems} onChange={() => setHidePickedItems(!hidePickedItems)} />
              { pickOrder.checkedItems.length > 0 &&
                <Text><strong>{pickOrder.checkedItems.length}</strong> Items Picked</Text>
              }
            </div>
          </div>
        </Panel>

        { lineItems.length > 0 && lineItems.length === pickOrder.checkedItems.length && pickOrder.unpickableItems.length === 0 &&
          <Panel>
            <H3>Picking Complete</H3>

            <H4>Next Steps</H4>
            <div className="bottom-5">
              <a target="_blank" href={`${stores.settings.control_panel_base_url}/admin/index.php?ToDo=printOrderInvoice&orderId=${pickOrder.orderId}`}>
                <Button variant="secondary" iconRight={<ReceiptIcon />}>Print Invoice</Button>
              </a>
            </div>

            <div className="bottom-5">
              <a target="_blank" href={`${stores.settings.control_panel_base_url}/admin/index.php?ToDo=printShipmentPackingSlips&orderId=${pickOrder.orderId}`}>
                <Button variant="secondary" iconRight={<ReceiptIcon />}>Print Packing Slip</Button>
              </a>
            </div>

            <hr/>

            <Text>Update the order's status if needed, and clear the order to pick and verify another order.</Text>
            { pickOrder.orderStatuses.length > 0 && !pickOrder.orderFulfillmentFetching &&
              <>
                <Select
                  maxHeight={300}
                  onOptionChange={setSelectedStatus}
                  options={statusList}
                  placeholder="Order Status"
                  value={selectedStatus}
                  inputMode='none'
                />
                <div className="top-5">
                  <Button onClick={() => updateOrderStatus()}>Update Status and Clear</Button>
                </div>
              </>
            }
            { pickOrder.orderStatuses.length === 0 && pickOrder.orderFulfillmentFetching &&
              <LoadingSpinner
                statusText='Loading fulfillment statuses'
              />
            }
          </Panel>
        }

        { pickOrder.unpickableItems.length > 0 &&
          <Panel>
            <H3 color="danger50">Could Not Pick</H3>
            <Text>Update the order's status if needed, and clear the order to pick and verify another order.</Text>
            { pickOrder.orderStatuses.length > 0 && !pickOrder.orderFulfillmentFetching &&
              <>
                <Select
                  maxHeight={300}
                  onOptionChange={setSelectedStatus}
                  options={statusList}
                  placeholder="Order Status"
                  value={selectedStatus}
                />
                <div className="top-5">
                  <Button onClick={() => updateOrderStatus()}>Update Status and Clear</Button>
                </div>
              </>
            }
            { pickOrder.orderStatuses.length === 0 && pickOrder.orderFulfillmentFetching &&
              <LoadingSpinner
                statusText='Loading fulfillment statuses'
              />
            }
          </Panel>
        }

        <Panel>
          { lineItems.map((lineItem, index) =>
            <LineItem
              lineItem={lineItem}
              toggleChecked={toggleChecked}
              setDialogLineItemId={setDialogLineItemId}
              setDisplayConfirmOrderUpdate={setDisplayConfirmOrderUpdate}
              switchUnpickable={switchUnpickable}
              hidePickedItems={hidePickedItems}
            />
          )}
        </Panel>

        { displayCorrect && 
          <CorrectScanDialog
            setShowCorrectScanDialog={setDisplayCorrect}
            focusScanArea={focusScanArea}
            quantity={dialogQuantity}
            switchUnpickable={switchUnpickable}
            dialogLineItemId={dialogLineItemId}
          />
        }
        { displayIncorrect && 
          <IncorrectScanDialog
            setShowIncorrectScanDialog={setDisplayIncorrect}
            focusScanArea={focusScanArea}
          />
        }
        { displayRepeat && 
          <RepeatScanDialog
            setShowRepeatScanDialog={setDisplayRepeat}
            focusScanArea={focusScanArea}
            quantity={dialogQuantity}
          />
        }
        { displayConfirmOrderUpdate &&
          <ConfirmOrderUpdateDialog
            setDisplayConfirmOrderUpdate={setDisplayConfirmOrderUpdate}
            updateItemQuantity={updateItemQuantity}
            dialogLineItemId={dialogLineItemId}
            payload={payload}
            orderId={pickOrder.orderId}
            focusScanArea={focusScanArea}
          />
        }
      </SettingsPage>
    </>
  )

}

const mapStateToProps = state => ({
  stores: state.stores,
  pickOrder: state.pickOrder,
  lineItems: state.pickOrder.lineItems,
  fulfillmentStatus: state.pickOrder.fulfillmentStatus,
  orderStatuses: state.pickOrder.orderStatuses,
});

const mapDispatchToProps = (dispatch) => ({
  searchOrder: (payload, orderId) => dispatch(readOrder(payload, orderId)),
  pushCheckedList: (skuList) => dispatch(setCheckedList(skuList)),
  pushUnpickableList: (skuList) => dispatch(setUnpickableList(skuList)),
  resetOrder: () => dispatch(clearOrder()),
  loadFulfillmentStatuses: (payload) => dispatch(indexFulfillmentStatuses(payload)),
  updateStatus: (payload, orderId, status) => dispatch(updateFulfillmentStatus(payload, orderId, status)),
  updateItemQuantity: (payload, orderId, lineItemId, quantity) => dispatch(updateLineItemQuantity(payload, orderId, lineItemId, quantity))
});

export default connect(mapStateToProps, mapDispatchToProps)(PickOrder);