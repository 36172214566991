import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  LOCATIONS_INDEX_REQUESTED,
  LOCATIONS_INDEX_SUCCESS,
  LOCATIONS_INDEX_FAILURE,
} from '../types';

export const locationsIndexRequested = () => {
  return {
    type: LOCATIONS_INDEX_REQUESTED
  }
};

export const locationsIndexSuccess = (locations) => {
  return {
    type: LOCATIONS_INDEX_SUCCESS,
    payload: locations
  }
};

export const locationsIndexFailure = () => {
  return {
    type: LOCATIONS_INDEX_FAILURE
  }
};


export const locationsIndex = (signedPayload, template, printCounts) => {
  return (dispatch) => {
    dispatch(locationsIndexRequested());

    let endPoint = Config.baseUrl + Config.locations;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(locationsIndexSuccess(response.data));
      })
      .catch((error) => {
        dispatch(locationsIndexFailure());
      });
  }
}