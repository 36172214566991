import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { Text, H4, Button } from '@bigcommerce/big-design';

const PrintLine = ({ id, itemId, item, type, printCount, setModifyItem, setModifyType, setModifyCount, setShowPrintCountDialog, setModifyItemId, missingBarcode }) => {
  const setAndShowDialog = () => {
    setModifyCount(printCount);
    setModifyType(type);
    setModifyItem(item);
    setModifyItemId(itemId);
    setShowPrintCountDialog(true);
  }

  return(
    <div onClick={() => setAndShowDialog()} className="flex-container has-hover has-hover-action sku-line">
      <div className={`flex-1 ${type === 'variation' && 'left-15'}`}>
        <div className="flex-container">
          { item.imageUrl && <img src={item.imageUrl} /> }
          { !item.imageUrl && <Text>No image</Text>}
        </div>
      </div>
      <div className="flex-4">
        <div className="flex-container">
          <H4>{item.name}</H4>
          { item.sku && <Text color="secondary60"> - {item.sku}</Text> }
          { !item.sku && <Text color="danger40"> - No SKU found, barcode will not generate.</Text> }
        </div>
        { missingBarcode && <Text color="warning40">
          Missing Barcode
        </Text>
        }
        <ul>
          { item.options && item.options.map(option =>
              <li>
                <strong>{ option.option_display_name }</strong>: { option.label }
              </li>
            )
          }
          { item.quantity && 
            <li>Default Location Stock: { item.quantity }</li>
          }
          { !item.options &&
            <Text color="warning40">
              Base Product
            </Text>
          }
        </ul>

      </div>
      <div className="flex-1">
        <Text>Print Count: {printCount}</Text>
        <Button>Edit Count</Button>
      </div>
    </div>
  )
}

export default PrintLine;
