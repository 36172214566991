import { Children } from 'react';
import { Text, H2 } from '@bigcommerce/big-design';

const SettingsPage = ({ children, title, description }) => {
  return (
    <>
      <div className="hide-mobile">
        <div className="flex-container settings-page">
          <div className="flex-1">
            <H2>{title}</H2>
            {description}
          </div>
          <div className="page-content flex-4">
            {Children.map(children, child =>
              <>
                {child}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="only-mobile">
        <div className="settings-page">
          <div className="page-content">
            {Children.map(children, child =>
              <>
                {child}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SettingsPage;