import { connect } from 'react-redux';

import { Message } from '@bigcommerce/big-design';

const CancelledSubscriptionHeader = ({ payments }) => {
  if (!payments.cancelAtPeriodEnd) {
    return(<></>)
  }

  return(
    <Message
      type="warning"
      header="Expiring Subscription"
      messages={[
        {
          text: `Your subscription is set to expire at ${payments.cancelAt}.`,
          link: {
            text: 'Click here to update your subscription.',
            href: payments.paymentPortalUrl,
            target: '_blank'
          },
        },
      ]}
      onClose={() => null}
    />
  )
}

const mapStateToProps = state => ({
  payments: state.payments,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CancelledSubscriptionHeader);

