import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { generateAllBarcodes } from '../../redux/actions/barcodes';

import { Modal, Table, Input } from '@bigcommerce/big-design';

const ModifyCountDialog = ({ showPrintCountDialog, setShowPrintCountDialog, modifyItemId, modifyItem, modifyType, setPrintCount }) => {
  const [replace, setReplace] = useState(null);

  const updateQuantity = () => {
    setPrintCount(modifyType, modifyItemId, modifyItem, replace);
    setShowPrintCountDialog(false);
  }

  const validateQuantity = (event) => {
    let value = event.target.value;
    setReplace(value.replace(/\D/g,''));
    if (value) {
      event.target.parentElement.classList.add("field--filled");
    } else {
      event.target.parentElement.classList.remove("field--filled");
    }
  }

  const tableItems = () => {
    let items = [];

    items.push({ property: 'Product Name', value: modifyItem.name });
    items.push({ property: 'SKU', value: modifyItem.sku });
    items.push({ property: 'Default Location Stock', value: modifyItem.quantity });
    modifyItem.option_values?.map(option =>
      items.push({ property: option.option_display_name, value: option.label })
    )

    return items;
  }

  return(
    <Modal
      actions={[
        {
          text: 'Cancel',
          variant: 'subtle',
          onClick: () => setShowPrintCountDialog(false),
        },
        { text: 'Update Quantity', onClick: () => updateQuantity() },
      ]}
      closeOnClickOutside={true}
      closeOnEscKey={true}
      header="Update Quantity"
      isOpen={showPrintCountDialog}
      onClose={() => setShowPrintCountDialog(false)}
    >
      <Table
        columns={[
          { header: 'Item Property', hash: 'property', render: ({ property }) => property },
          { header: 'Value', hash: 'value', render: ({ value }) => value }
        ]}
        items={tableItems()}
      />

      <Input required label="Update Quantity" placeholder="Update Quantity" id="update_quantity" type="text" value={replace} onChange={(e) => validateQuantity(e)} />
    </Modal>
  )
}

export default ModifyCountDialog;
