import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';

import { Text, Panel } from '@bigcommerce/big-design';
import SettingsPage from '../partials/SettingsPage';
import LoadingSpinner from '../partials/LoadingSpinner';

import { verifySession } from '../../redux/actions/payments';

const Session = ({ payload, payments, verifyCheckoutSession }) => {
  let { checkoutSessionId } = useParams();

  useEffect(() => {
    if (payments.verificationStatus !== 'success' && !payments.isFetching) {
      verifyCheckoutSession(payload, checkoutSessionId);
    }
  }, []);

  const description = () => {
    return(
      <>
        <Text>Verifying Payment</Text>
      </>
    )
  }

  if (payments.verificationStatus === 'success') {
    return (
      <Navigate to="/" />
    )
  }

  return (
    <>
      <SettingsPage title="Verifying Payment" description={description()}>
        <Panel>
          { payments.verificationStatus !== 'success' &&
            <LoadingSpinner size="large" statusText="Verifying Payment" />
          }
        </Panel>
      </SettingsPage>
    </>
  )
}

const mapStateToProps = state => ({
  payments: state.payments,
});

const mapDispatchToProps = (dispatch) => ({
  verifyCheckoutSession: (payload, checkoutSessionId) => dispatch(verifySession(payload, checkoutSessionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Session);
