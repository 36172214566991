import LoadingSpinner from '../partials/LoadingSpinner';

import { Text, H3, H4 } from '@bigcommerce/big-design';

const RequestRow = ({ event }) => {
  return(
    <div className="has-hover sku-line flex-container">
      <div className="flex-1">
        <LoadingSpinner size="small" statusText="Searching" />
      </div>

      <div className="flex-2">
        <H3>{ event.actionType }</H3>
      </div>

      <div className="flex-2">
        <H4>Scan Value:</H4>
        <Text>{ event.sku }</Text>
      </div>

      <div className="flex-1">
      </div>
    </div>
  )
}

export default RequestRow;
