import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import LoadingSpinner from '../partials/LoadingSpinner';
import PageHeader from '../partials/PageHeader';
import SettingsPage from '../partials/SettingsPage';

import { Text, Toggle, Panel, Input, Button, Dropdown, H4, Small, InlineMessage } from '@bigcommerce/big-design';
import { ArrowDropDownIcon } from '@bigcommerce/big-design-icons';

import LineItem from "./LineItem";
import SubmittedMessage from "./SubmittedMessage";

import { addLineItem, clearOrderSession, publishNewOrder, deleteLineItem } from '../../redux/actions/orderSessions';
import { scanInventory } from '../../redux/actions/inventorySessions';
import { locationsIndex } from '../../redux/actions/locations';

const OrderCreation = ({ payload, settings, orderSession, orderError, lineItems, accountUnits, postScanInventory, addAction, clearSession, submitOrder, removeLineItem, locations, loadLocations }) => {
  const [submitted, setSubmitted] = useState(false);
  const [scanTerm, setScanTerm] = useState('');
  const [changeNotes, setChangeNotes] = useState('');
  const [lineItemValues, setLineItemValues] = useState({});
  const [lineItemTotals, setLineItemTotals] = useState({});
  const [totalChangeValues, setTotalChangeValues] = useState(0);
  const [totalPriceValue, setTotalPriceValue] = useState(0.0);
  const [activeLocation, setActiveLocation] = useState(null);
  const [activeLabel, setActiveLabel] = useState(null);

  const switchLocation = (event, setter) => {
    let location = locations.locations.find(location => location.id === event.hash);
    setter(location);
    setActiveLabel(location.label);
    flashScanBar();
  }

  const flashScanBar = () => {
    let scanBarElement = document.querySelector("#scan-bar");

    setTimeout(() => {
      scanBarElement?.classList?.add("blink-action");
    }, 500);

    setTimeout(() => {
      scanBarElement?.classList?.remove("blink-action");
    }, 1500);
  }

  const publishOrderSession = () => {
    let submitCounts = [];

    lineItems.map((event, index) => {
      if(!(event.error)){
        submitCounts.push(
          {
            product_id: event.product_id,
            variant_id: event.variant_id,
            quantity: lineItemValues[event.sku]
          }
        );
      }
    })

    let clearButton = document.getElementById("clear-button");
    let submitButton = document.getElementById("submit-button");

    clearButton.disabled = "true";
    submitButton.disabled = "true";

    submitOrder(payload, submitCounts, activeLocation);
    setSubmitted(true);
  }

  const updateScan = (event) => {
    let value = event.target.value;
    setScanTerm(value);
    if (value) {
      event.target.parentElement.classList.add("field--filled");
    } else {
      event.target.parentElement.classList.remove("field--filled");
    }
  }

  const setValue = (value, lineEvent) => {
    setLineItemTotals(lineItemTotals=>({
      ...lineItemTotals,
      [lineEvent.sku]: ((lineEvent?.price || lineEvent?.calculated_price || 0.0) * parseInt(value))
    }));

    setLineItemValues(lineItemValues=>({
      ...lineItemValues,
      [lineEvent.sku]: parseInt(value)
    }));
  }

  const setTotals = () => {
    let tempValue = 0;
    let tempTotal = 0.0;

    for (const [key, value] of Object.entries(lineItemValues)) {
      tempValue += (parseInt(value) || 0);
    }

    for (const [key, value] of Object.entries(lineItemTotals)) {
      tempTotal += (parseFloat(value) || 0);
    }

    setTotalChangeValues(tempValue);
    setTotalPriceValue(formatPriceValue(tempTotal));
  }

  const formatPriceValue = (value) => {
    if(settings?.language && settings?.country_code) {
      let currencySettings = {
        style: 'currency',
        currency: settings.currency
      }

      let locale = `${settings.language}-${settings.country_code}`;

      return Intl.NumberFormat(locale, currencySettings).format(value);
    }

    return value;
  }

  const lineItemsLoaded = () => {
    let firstLoading = lineItems.find((lineItem) => {
      return lineItem.placeholder
    });

    return !firstLoading;
  }

  const resetSession = () => {
    setSubmitted(false);
    clearSession();
    setLineItemValues({});
    setLineItemTotals({});
    setTotalChangeValues(0);
    setTotalPriceValue(formatPriceValue(0.0));
  }

  const removeItem = (lineItem, actionId) => {
    setValue(0, lineItem);
    setTotals();
    removeLineItem(actionId);
  }

  const description = () => {
    return(
      <>
        <Text>From the locations dropdown, select a location that you're looking to take inventory from to create this order.</Text>
        <Text>Select the "Scan barcode" bar and scan a generated Code 128 SKU (the generated barcodes from the "Labels + Print" section of this app, or similar) with a barcode scanner. This scan should also work with UPCs, EANs or ISBN values if they have been set in products or variations.</Text>
        <Small>Have questions or need some help? Contact us at <a href="mailto:support@athousandapps.com">support@athousandapps.com</a></Small>
      </>
    )
  }

  const lineItemDescription = () => {
    return(
      <>
        <Text>When items are scanned, they should appear below. Add a value to the "Quantity" field for each item. When submitted, the quantity associated with the line item will be added to the created order.</Text>
        <Text>Scanning duplicate barcodes will increment the count of listed items.</Text>
        <Text>Click the "Submit Order" button to create the order. A link will appear to view the order once it has been created.</Text>
        <Text>Orders will be created with your store's tax settings based on the selected location, and without shipping settings or a customer set.</Text>
        <Text>Inventory will be removed <b>from the selected location</b> for the scanned items when an order is created.</Text>
      </>
    )
  }

  const locationItems = () => {
    let items = [];

    locations.locations.map(location => 
      items.push(
        {
          content: location.label,
          onItemClick: (event) => switchLocation(event, setActiveLocation),
          hash: location.id
        }
      )
    )

    return items;
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(scanTerm !== ''){
        let matchingLine = lineItems.find(lineItem => { return (lineItem.sku === scanTerm) || (lineItem?.attributes?.find(attr => attr.name = 'UPC')?.value === scanTerm); });

        if(!matchingLine) {
          addAction(scanTerm, lineItems.length);
          postScanInventory(payload, scanTerm, lineItems.length, activeLocation);
        } else {
          setValue(lineItemValues[matchingLine.sku] + 1, matchingLine);
        }

        setScanTerm('');
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [scanTerm, 500]);

  useEffect(() => {
    loadLocations(payload);
    setSubmitted(false);
    clearSession();
  }, []);

  useEffect(() => {
    setTotals();
  }, [lineItemValues]);

  useEffect(() => {
    setActiveLocation(locations?.locations[0]);
    setActiveLabel(locations?.locations[0]?.label);

    if(locations.status === 'success') {
      flashScanBar();
    }

  }, [locations]);

  useEffect(() => {  
    if(orderSession.status === "success") {
      lineItems.map((lineItem) => {
        setValue(lineItemValues[lineItem.sku] || 1, lineItem);
      });
    }

    if (orderSession.status === "waiting" && !orderSession.errors) {
      setLineItemValues({});
      setLineItemTotals({});
      setTotalChangeValues(0);
      setTotalPriceValue(formatPriceValue(0.0));
      setTotals();
    }
  }, [orderSession]);

  useEffect(() => {
    if(orderError) {
      setSubmitted(false);
    }
  }, [orderError]);

  return(
    <>
      <PageHeader stripeBackground='#39a0a0' helpLink="bigcommerce/create-pickup-orders-with-bigcommerce/" />
      <SettingsPage title="Create Order" description={description()}>
        <Panel>
          <H4>Locations</H4>
          { locations.status !== 'success' &&
            <LoadingSpinner size="xxSmall" statusText="Loading Locations" />
          }

          { locations.status === 'success' &&
            <Dropdown
              items={locationItems()}
              maxHeight={250}
              placement="bottom-start"
              toggle={<Button iconRight={<ArrowDropDownIcon />}>{activeLabel || "Select Location"}</Button>}
            />
          }
          <InlineMessage
            marginVertical="medium"
            messages={[{
              text: 'To create an order, the selected location needs to have a pickup method.',  
              link: {
                text: 'Read more about pickup methods.',
                href: 'https://support.bigcommerce.com/s/article/Multi-Location#pickup-methods',
                target: '_blank'
              }
            }]}
            type="info"
          />

          <hr/>

          <div className="flex-container">
            <svg width="1.25rem" width="150" height="80" fill="#189dee" viewBox="0 0 32 32" id="svg5" version="1.1">
              <defs id="defs2"/>
              <g id="layer1" transform="translate(-108,-100)">
                <path d="m 111,106 a 1.0001,1.0001 0 0 0 -1,1 v 3 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" id="path11698" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 134,106 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -3 a 1.0001,1.0001 0 0 0 -1,-1 z" id="path11700" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 137,121 a 1,1 0 0 0 -1,1 v 2 h -2 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3 a 1.0001,1.0001 0 0 0 1,-1 v -3 a 1,1 0 0 0 -1,-1 z" id="path11702" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 111,121 a 1,1 0 0 0 -1,1 v 3 a 1.0001,1.0001 0 0 0 1,1 h 3 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 h -2 v -2 a 1,1 0 0 0 -1,-1 z" id="path11704" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 115,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11706" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 118,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11708" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 121,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11710" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 124,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11712" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 127,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11714" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 130,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11716" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                <path d="m 133,110 a 1,1 0 0 0 -1,1 v 5.20703 1.31445 V 121 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 117.52148 116.20703 111 a 1,1 0 0 0 -1,-1 z" id="path11720" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
              </g>
            </svg>
            <div className="flex-1">
              <Input
                id="scan-bar"
                required
                label="Scan Barcode (SKU, UPC, EAN)"
                onChange={updateScan}
                placeholder="Click here to begin scanning"
                type="text"
                value={scanTerm}
                disabled={submitted || !activeLocation}
              />
            </div>
          </div>

        </Panel>
      </SettingsPage>

      <SettingsPage title="Line Items" description={lineItemDescription()}>
        <Panel>
          { submitted && 
            <SubmittedMessage
              payload={payload}
              resetSession={resetSession}
              formatPriceValue={formatPriceValue}
            />
          }

          { orderError &&
            <Text color="danger50">
              { orderError }
            </Text>
          }

          { settings && 
            <>
              <Text as="span" bold>Subtotal</Text>
              <Text as="span">: {totalPriceValue}</Text>
            </>
          }
          <div className="flex-container flex-end flex-1 left-15">
            { !submitted && !orderSession.isFetching && <Button actionType="destructive" variant="secondary" id="clear-button" className="btn btn-default btn--destructive btn-medium flex-1 fit-content" onClick={() => resetSession()}>Clear session</Button> }
            { !submitted && orderSession.isFetching && <Button actionType="destructive" variant="secondary" id="clear-button" className="btn btn-default btn--destructive btn-medium flex-1 fit-content" disabled>Clear session</Button> }
            { submitted && <Button actionType="destructive" variant="secondary" id="clear-button" className="btn btn-default btn--destructive btn-medium flex-1 fit-content" disabled>Clear session</Button> }

            { !submitted && !orderSession.isFetching && (totalChangeValues > 0) && lineItemsLoaded() && <Button id="submit-button" className="btn btn-primary btn-medium flex-1 fit-content left-15" onClick={() => publishOrderSession()}>Submit Order</Button> }
            { !submitted && (orderSession.isFetching || (totalChangeValues === 0) || !lineItemsLoaded()) && <Button id="submit-button" className="btn btn-primary btn-medium flex-1 fit-content left-15" disabled>Submit Order</Button> }
            { submitted && !orderSession.orderId &&
              <LoadingSpinner size="xxSmall" statusText="Creating Order" />
            }
            { submitted && orderSession.orderId &&
              <div className="text-default muted left-15">Order Created</div>
            }
          </div>

          <br/>

          { lineItems.toReversed().map((event, index) =>
            <LineItem
              lineItemValues={lineItemValues}
              setValue={setValue}
              actionId={lineItems.length - 1 - index}
              submitted={submitted}
              lineEvent={event}
              removeItem={removeItem}
              formatPriceValue={formatPriceValue}
            />
          )}

        </Panel>
      </SettingsPage>
    </>
  )
}

const mapStateToProps = state => ({
  settings: state.stores.settings,
  orderSession: state.orderSessions,
  orderError: state.orderSessions.error,
  lineItems: state.orderSessions.lineItems,
  locations: state.locations,
});

const mapDispatchToProps = (dispatch) => ({
  postScanInventory: (payload, barcode, actionId, activeLocation) => dispatch(scanInventory(payload, barcode, actionId, activeLocation)),
  addAction: (barcode, actionId) => dispatch(addLineItem(barcode, actionId)),
  clearSession: () => dispatch(clearOrderSession()),
  submitOrder: (payload, actions, location) => dispatch(publishNewOrder(payload, actions, location)),
  removeLineItem: (actionId) => dispatch(deleteLineItem(actionId)),
  loadLocations: (payload) => dispatch(locationsIndex(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderCreation);
