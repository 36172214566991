import React from 'react';
import { Button, Text, H4, Box } from '@bigcommerce/big-design';
import { ArrowUpwardIcon, ArrowDownwardIcon } from '@bigcommerce/big-design-icons';

const TemplateLineOrderContainer = ({
  barcode,
  storeLogo,
  barcodeSymbol,
  productName,
  optionOne,
  optionTwo,
  optionThree,
  price,
  brand,
  arbitraryText,
  barcodeSymbolPriority,
  productNamePriority,
  optionOnePriority,
  optionTwoPriority,
  optionThreePriority,
  pricePriority,
  brandPriority,
  arbitraryTextPriority,
  increasePriority,
  decreasePriority
}) => {

  let lineElements = [];

  if (barcodeSymbol) {
    lineElements.push({
      element: 'Barcode Symbol',
      priority: barcodeSymbolPriority
    })
  }

  if (productName) {
    lineElements.push({
      element: 'Product Name',
      priority: productNamePriority
    })
  }

  if (optionOne) {
    lineElements.push({
      element: 'Variation Option 1',
      priority: optionOnePriority
    })
  }

  if (optionTwo) {
    lineElements.push({
      element: 'Variation Option 2',
      priority: optionTwoPriority
    })
  }

  if (optionThree) {
    lineElements.push({
      element: 'Variation Option 3',
      priority: optionThreePriority
    })
  }

  if (price) {
    lineElements.push({
      element: 'Price',
      priority: pricePriority
    })
  }

  if (brand) {
    lineElements.push({
      element: 'Brand',
      priority: brandPriority
    })
  }

  if (arbitraryText) {
    lineElements.push({
      element: 'Branding Text',
      priority: arbitraryTextPriority
    })
  }

  lineElements.sort((a,b) => a.priority - b.priority)

  if(barcode) {
    return(
      <Box
        backgroundColor="primary20"
        border="box"
        borderRadius="normal"
        padding="medium"
      >
        <H4>Barcode Line</H4>
        <Text>Only the barcode appears on barcode lines. Text elements on this line will not appear.</Text>
      </Box>
    )
  }

  if(storeLogo) {
    return(
      <Box
        backgroundColor="primary20"
        border="box"
        borderRadius="normal"
        padding="medium"
      >
        <H4>Store Logo Line</H4>
        <Text>Only the logo appears on store logo lines. Text elements on this line will not appear.</Text>
      </Box>
    )
  }

  if(lineElements.length === 0) {
    return (
      <Box
        backgroundColor="warning20"
        border="box"
        borderRadius="normal"
        padding="medium"
      >
        <H4>Empty Line</H4>
        <Text>No text elements selected for this line. Enable an element and select this line number from its dropdown in the "Label Parts" section to add to this line.</Text>
      </Box>
    )
  }

  return (
    <div>
      {lineElements.map(element => (
        <div id={`line-element-${element.priority}`} className="flex-container template-line-row">
          <div className="flex-4 text-center">
            <Text>{element.element}</Text>
          </div>
          <div className="flex-1">
            <div className="flex-container">
              <Button iconOnly={<ArrowUpwardIcon title="promote" />} onClick={() => increasePriority(element, lineElements)} />
              <Button iconOnly={<ArrowDownwardIcon title="demote" />} onClick={() => decreasePriority(element, lineElements)} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )

}

export default TemplateLineOrderContainer;