import { Text, H3, H4 } from '@bigcommerce/big-design';

const ErrorRow = ({ event }) => {
  return(
    <div className="has-hover sku-line flex-container">
      <div className="flex-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="175" height="50" fill="none" viewBox="0 0 18 18"><circle cx="9" cy="9" r="8" stroke="#F43C36" fill="none" stroke-width="2"/><path fill="#F43C36" d="M8.998 3.904a.908.908 0 0 0-.384.08.985.985 0 0 0-.325.237 1.098 1.098 0 0 0-.216.358c-.05.134-.075.278-.073.422v4.802c0 .29.105.57.292.776a.955.955 0 0 0 .706.321.955.955 0 0 0 .706-.321c.187-.206.293-.485.293-.776V5a1.108 1.108 0 0 0-.053-.434 1.032 1.032 0 0 0-.215-.37.918.918 0 0 0-.336-.235.847.847 0 0 0-.395-.058ZM9 14.1a1.1 1.1 0 1 0 0-2.2 1.1 1.1 0 0 0 0 2.2Z"/></svg>
      </div>

      <div className="flex-2">
        <H3>Error</H3>
        <Text>Error description: { event.error }</Text>
      </div>

      <div className="flex-2">
      </div>

      <div className="flex-1">
      </div>
    </div>
  )
}

export default ErrorRow;
