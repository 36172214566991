import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  CREATE_BARCODES_REQUESTED,
  CREATE_BARCODES_SUCCESS,
  CREATE_BARCODES_FAILURE,
  CREATE_ALL_BARCODES_REQUESTED,
  CREATE_ALL_BARCODES_SUCCESS,
  CREATE_ALL_BARCODES_FAILURE,
  REFRESH_BATCHES_REQUESTED,
  REFRESH_BATCHES_SUCCESS,
  REFRESH_BATCHES_FAILURE,
  DOWNLOAD_BARCODE_IMAGE_REQUESTED,
  DOWNLOAD_BARCODE_IMAGE_SUCCESS,
  DOWNLOAD_BARCODE_IMAGE_FAILURE,
} from '../types';

export const generateBarcodesRequested = () => {
  return {
    type: CREATE_BARCODES_REQUESTED
  }
};

// Watch this watchID to check if barcodes have been generated
export const generateBarcodesSuccess = (payload) => {
  return {
    type: CREATE_BARCODES_SUCCESS,
    payload: payload
  }
};

export const generateBarcodesFailure = () => {
  return {
    type: CREATE_BARCODES_FAILURE
  }
};

export const generateAllBarcodesRequested = () => {
  return {
    type: CREATE_ALL_BARCODES_REQUESTED
  }
};

// Watch this watchID to check if barcodes have been generated
export const generateAllBarcodesSuccess = (payload) => {
  return {
    type: CREATE_ALL_BARCODES_SUCCESS,
    payload: payload
  }
};

export const generateAllBarcodesFailure = () => {
  return {
    type: CREATE_ALL_BARCODES_FAILURE
  }
};

export const refreshBatchesRequested = () => {
  return {
    type: REFRESH_BATCHES_REQUESTED
  }
};

// Watch this watchID to check if barcodes have been generated
export const refreshBatchesSuccess = (payload) => {
  return {
    type: REFRESH_BATCHES_SUCCESS,
    payload: payload
  }
};

export const refreshBatchesFailure = () => {
  return {
    type: REFRESH_BATCHES_FAILURE
  }
};


export const barcodeDownloadRequested = () => {
  return {
    type: DOWNLOAD_BARCODE_IMAGE_REQUESTED
  }
};

// Watch this watchID to check if barcodes have been generated
export const barcodeDownloadSuccess = (payload) => {
  return {
    type: DOWNLOAD_BARCODE_IMAGE_SUCCESS,
    payload: payload
  }
};

export const barcodeDownloadFailure = () => {
  return {
    type: DOWNLOAD_BARCODE_IMAGE_FAILURE
  }
};

export const generateBarcodes = (signedPayload, barcodes, replace) => {
  return (dispatch) => {
    dispatch(generateBarcodesRequested());

    let endPoint = Config.baseUrl + Config.barcode + Config.generate;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.post(endPoint, { 'term': barcodes, 'replace': replace } , { headers: headers })
      .then((response) => {
        dispatch(generateBarcodesSuccess(response.data));
      });
  }
}

export const generateAllBarcodes = (signedPayload) => {
  return (dispatch) => {
    dispatch(generateAllBarcodesRequested());

    let endPoint = Config.baseUrl + Config.barcode + Config.generateAll;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.post(endPoint, {} , { headers: headers })
      .then((response) => {
        dispatch(generateAllBarcodesSuccess(response.data));
      });
  }
}

export const refreshBatches = (signedPayload) => {
  return (dispatch) => {
    dispatch(refreshBatchesRequested());

    let endPoint = Config.baseUrl + Config.barcode + Config.batches;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.post(endPoint, {} , { headers: headers })
      .then((response) => {
        dispatch(refreshBatchesSuccess(response.data));
      });
  }
}

export const downloadBarcodeImage = (signedPayload, itemId, barcode) => {
  return (dispatch) => {
    dispatch(barcodeDownloadRequested());

    console.log(signedPayload);

    let endPoint = Config.baseUrl + Config.barcode + Config.download + '?itemId=' + itemId + '&barcode=' + barcode;
    let headers = Object.assign(Config.headers);
    headers.payload = signedPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(barcodeDownloadSuccess(response.data));
      })
      .catch((error) => {
        dispatch(barcodeDownloadFailure());
      });

  }
}
