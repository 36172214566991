import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { GlobalStyles } from '@bigcommerce/big-design';

import store from './redux/store';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let payload = urlParams.get('signed_payload');
let checkoutSessionId = urlParams.get('session_id');

const root = ReactDOM.createRoot(document.getElementById('root'));

if(!payload) {
  root.render(
    <React.StrictMode>
      <h1>Failed to load indentification</h1>
      <p>
        App failed to load the payload identification for this page. Please refresh the page or reload the app.
      </p>
    </React.StrictMode>
  );

}

if (payload[payload.length - 1] === '/') { 
  payload = payload.slice(0,-1);
}

if (checkoutSessionId && checkoutSessionId[checkoutSessionId.length - 1] === '/') { 
  checkoutSessionId = checkoutSessionId.slice(0,-1);
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App payload={payload} checkoutSessionId={checkoutSessionId}>
        <GlobalStyles />
      </App>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
