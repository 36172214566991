import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { generateBarcodes } from '../../redux/actions/barcodes';

import { Modal, Text } from '@bigcommerce/big-design';

const ConfirmGenerationDialog = ({ payload, setShowGenerateDialog, showGenerateDialog, selectedProductList, generateImages, barcodes }) => {
  return(
    <Modal
      actions={[
        {
          text: 'Cancel',
          variant: 'subtle',
          onClick: () => setShowGenerateDialog(false),
        },
        { 
          text: 'Generate Barcodes', 
          isLoading: (barcodes.status === "requesting"),
          onClick: () => generate(payload, selectedProductList, barcodes.status, generateImages)
        },
      ]}
      closeOnClickOutside={true}
      closeOnEscKey={true}
      header="Generate Barcodes"
      isOpen={showGenerateDialog}
      onClose={() => setShowGenerateDialog(false)}
    >
      <Text color="warning40">
        Generate barcodes for selected products/variants. Existing barcodes will be deleted and replaced. This action can't be undone.
      </Text>
    </Modal>
  )
}

const generate = (payload, selectedProductList, status, generateImages) => {
  if(status !== "requesting") {
    generateImages(payload, selectedProductList);
  }
}

const mapStateToProps = state => ({
  selectedProductList: state.products.selectedProductList,
  barcodes: state.barcodes,
});

const mapDispatchToProps = (dispatch) => ({
  generateImages: (payload, barcodes) => dispatch(generateBarcodes(payload, barcodes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmGenerationDialog);
