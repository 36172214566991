import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { generateAllBarcodes } from '../../redux/actions/barcodes';

import { Modal, Text, Box, H4 } from '@bigcommerce/big-design';

const ConfirmGenerateAllDialog = ({ payload, showGenerateAllDialog, setShowGenerateAllDialog, generateAllImages, barcodes }) => {
  return(
    <Modal
      actions={[
        {
          text: 'Cancel',
          variant: 'subtle',
          onClick: () => setShowGenerateAllDialog(false),
        },
        { 
          text: 'Generate All Barcodes', 
          isLoading: (barcodes.status === "requesting"),
          onClick: () => generate(payload, barcodes.status, generateAllImages)
        },
      ]}
      closeOnClickOutside={true}
      closeOnEscKey={true}
      header="Generate All Barcodes"
      isOpen={showGenerateAllDialog}
      onClose={() => setShowGenerateAllDialog(false)}
    >
      <Box
        backgroundColor="warning20"
        border="box"
        borderRadius="normal"
        padding="medium"
      >
        <H4>Performance warning</H4>
        <Text>
          Depending on how many products and variants your store has, generating all barcodes may take a substantial amount of time.
        </Text>
      </Box>
      <Text color="warning40">
        Existing barcodes will be deleted and replaced. This action can't be undone.
      </Text>
    </Modal>
  )
}

const generate = (payload, status, generateAllImages) => {
  if(status !== "requesting") {
    generateAllImages(payload);
  }
}

const mapStateToProps = state => ({
  selectedProductList: state.products.selectedProductList,
  barcodes: state.barcodes,
});

const mapDispatchToProps = (dispatch) => ({
  generateAllImages: (payload) => dispatch(generateAllBarcodes(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmGenerateAllDialog);
