import React, { useState, useEffect } from 'react';
import { generateBarcodes } from '../../redux/actions/barcodes';

import { Modal, Text, H2 } from '@bigcommerce/big-design';

const CorrectScanDialog = ({ quantity, setShowCorrectScanDialog, focusScanArea, switchUnpickable, dialogLineItemId}) => {
  const close = () => {
    setShowCorrectScanDialog(false);
    focusScanArea();
  }

  const setUnpickable = () => {
    switchUnpickable(dialogLineItemId);
    close();
  }

  return(
    <div className="main-overlay main-overlay__success">
      <Modal
        actions={[
          { 
            text: 'Confirm', 
            onClick: () => close()
          },
          {
            text: 'Could not pick',
            variant: 'secondary',
            actionType: 'destructive',
            onClick: () => setUnpickable(),
          }
        ]}
        closeOnClickOutside={true}
        closeOnEscKey={true}
        backdrop={false}
        isOpen={true}
        onClose={() => close()}
        variant="dialog"
      >
        <br/>
        <H2 color="success50">Correct Item</H2>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6508 2.7408c.4193.3594.4679.9907.1085 1.41l-9 10.5c-.3791.4423-1.0545.4682-1.4664.0563l-5-5c-.3905-.3905-.3905-1.0237 0-1.4142.3905-.3905 1.0237-.3905 1.4142 0l4.2364 4.2364 8.2972-9.68c.3595-.4194.9908-.468 1.4101-.1085z"/>
          </svg>
          <span className="left-15">The scanned item is in this order. Please pick this quantity:</span>
        </div>
        <p className="pick-quantity">
          { quantity }
        </p>
      </Modal>
    </div>
  )
}

export default CorrectScanDialog;
