import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import { Text, Panel, H2 } from '@bigcommerce/big-design';
import SettingsPage from '../partials/SettingsPage';
import LoadingPlaceholder from '../partials/LoadingPlaceholder';

import { paymentSession } from '../../redux/actions/payments';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE_KEY, {});

const Payment = ({ payload, payments, clientSecret, getPaymentClient }) => {
  const options = {clientSecret};

  useEffect(() => {
    getPaymentClient(payload);
  }, []);

  return (
    <>
      <div className="settings-page">
        <H2>Set Up Payments</H2>
        <div className="page-content">
            { payments.status === 'success' && payments.clientSecret &&
                <EmbeddedCheckoutProvider
                  stripe={stripePromise}
                  options={options}
                >
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>

            }
            { payments.status !== 'success' &&
              <LoadingPlaceholder />
            }
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  payments: state.payments,
  clientSecret: state.payments.clientSecret,
});

const mapDispatchToProps = (dispatch) => ({
  getPaymentClient: (payload) => dispatch(paymentSession(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
