import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import ItemLine from "./ItemLine";
import ConfirmGenerationDialog from './ConfirmGenerationDialog';
import ConfirmGenerateAllDialog from './ConfirmGenerateAllDialog';
import GeneratingBarcodesNotice from './GeneratingBarcodesNotice';

import FeatureCard from '../partials/FeatureCard';
import NoLabelTemplatesBox from '../partials/NoLabelTemplatesBox';
import LoadingPlaceholder from '../partials/LoadingPlaceholder';
import LoadingSpinner from '../partials/LoadingSpinner';
import SettingsPage from '../partials/SettingsPage';
import PageHeader from '../partials/PageHeader';

import { Text, Panel, Button, Box, Search, H2, Small, Collapse, Switch, Dropdown, H4, Pagination, Input } from '@bigcommerce/big-design';
import { ArrowDropDownIcon } from '@bigcommerce/big-design-icons';

import { updateSelectedItems, searchProducts } from '../../redux/actions/products';
import { refreshBatches } from '../../redux/actions/barcodes';

import { indexBarcodeTemplates } from '../../redux/actions/barcodeTemplates';
import { getCategories } from '../../redux/actions/categories';


const ManageBarcodes = ({ payload, stores, users, getTemplates, loadCategories, categories, barcodeTemplates, searchedProducts, searchedProductsPagination, searchedVariants, searchedBarcodes, setSelectedProductList, isSearching, submitSearch, selectedProductCount, selectedProductList, generateActions, batchRefresh, downloadUrl, brands }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [missingBarcodeCount, setMissingBarcodeCount] = useState(0);
  const [showGenerateDialog, setShowGenerateDialog] = useState(false);
  const [showGenerateAllDialog, setShowGenerateAllDialog] = useState(false);
  // const [barcodeActionsOptions, setBarcodeActionsOptions] = useState({}); // TODO: Check if these do anything
  // const [offsetY, setOffsetY] = useState(0); // TODO: Check if these do anything
  // const [scrollY, setScrollY] = useState(0); // TODO: Check if these do anything

  const [inStockFilter, setInStockFilter] = useState(false);
  const [purchasingDisabledFilter, setPurchasingDisabledFilter] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState();

  const [enqueueSearch, setEnqueueSearch] = useState(null);
  const [downloadSubmitted, setDownloadSubmitted] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getTemplates(payload);
    loadCategories(payload);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(searchTerm !== ''){
        setCurrentPage(1);
        handleSearch(searchTerm, 1);
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchTerm, 500]);

  const handleSearch = (searchTerm, page) => {
    if(!isSearching) {
      setEnqueueSearch(null);
      submitSearch(payload, searchTerm, inStockFilter, purchasingDisabledFilter, categoryFilter, page);
    } else {
      setEnqueueSearch(searchTerm);
    }
  }

  useEffect(() => {
    if(!isSearching) {
      readySearch();
    }
  }, [isSearching]);

  const readySearch = () => {
    if(enqueueSearch) {
      submitSearch(payload, enqueueSearch, inStockFilter, purchasingDisabledFilter, categoryFilter, currentPage);
      setEnqueueSearch(null);
    }
  }

  useEffect(() => {
    setShowGenerateDialog(false);
    setShowGenerateAllDialog(false);

    if(generateActions.length > 0){
      const timeoutId = setTimeout(() => {
        if(generateActions.length > 0){
          batchRefresh(payload);
        }
      }, 3000);
      return () => clearTimeout(timeoutId);

    }

    if(generateActions.length === 0 && searchTerm !== '') {
      setCurrentPage(1);
      handleSearch(searchTerm, 1);
    }
  }, [generateActions]);

  useEffect(() => {
    countMissingBarcodes();
  }, [selectedProductList]);

  useEffect(() => {
    recountMissingBarcodes();
  }, [searchedProducts]);


  useEffect(() => {
    if(downloadUrl && downloadSubmitted) {
      let win = window.open(downloadUrl, '_blank');
      win.focus();
      setDownloadSubmitted(false);
    }
  }, [downloadUrl]);

  const toggleInStockFilter = () => {
    setInStockFilter(!inStockFilter);
  }

  const togglePurchasingDisabledFilter = () => {
    setPurchasingDisabledFilter(!purchasingDisabledFilter);
  }

  const categoryItems = () => {
    let items = [];

    Object.entries(categories.categories).map(([key, name]) =>
      items.push(
        {
          content: name,
          onItemClick: (event) => switchCategoryFilter(event, setCategoryFilter),
          hash: key
        }
      )
    )

    return items;
  }

  const switchCategoryFilter = (event, setter) => {
    let categoryId = event.hash;
    setter(categoryId);
  }

  const setProductResultPage = (page) => {
    setCurrentPage(page);
    handleSearch(searchTerm, page);
  }

  const clearCategory = () => {
    setCategoryFilter(null);
  }

  const validateTerm = (event) => {
    let value = event.target.value;

    setSearchTerm(value);
  }

  const countMissingBarcodes = () => {
    let count = 0;

    Object.keys(selectedProductList.variations).map(itemId => {
      if(!selectedProductList.variations[itemId].hasBarcode) {
        count = count + 1;
      }
    })

    Object.keys(selectedProductList.products).map(itemId => {
      if(!selectedProductList.products[itemId].hasBarcode) {
        count = count + 1;
      }
    })

    setMissingBarcodeCount(count);
  }

  const recountMissingBarcodes = () => {
    let productSection = selectedProductList.products;
    let variationsSection = selectedProductList.variations;
    let count = 0;

    for (const [key, value] of Object.entries(productSection)) {
      if (value.hasBarcode === true) { continue; }

      if ((searchedBarcodes.indexOf(value.sku) > -1) || (searchedBarcodes.indexOf(value.upc) > -1)) {
        value.hasBarcode = true;
      } else {
        value.hasBarcode = false;
        count = count + 1
      }
    }

    for (const [key, value] of Object.entries(variationsSection)) {
      if (value.hasBarcode === true) { continue; }
      
      if ((searchedBarcodes.indexOf(value.sku) > -1) || (searchedBarcodes.indexOf(value.upc) > -1)) {
        value.hasBarcode = true;
      } else {
        value.hasBarcode = false;
        count = count + 1
      }
    }

    setMissingBarcodeCount(count);
    setSelectedProductList({ ...selectedProductList });
  }

  const selectAllSearched = () => {
    let productSection = selectedProductList.products;
    let variationsSection = selectedProductList.variations;

    searchedProducts.forEach((product) => {
      let productInList = Object.keys(productSection).indexOf(String(product.Id)) > -1;

      if (!productInList && ((product.inventory_tracking === 'product') || (product.inventory_tracking === 'none' && !(searchedVariants[product.id]?.length > 0)) ) ) {
        productSection[String(product.id)] = {
          sku: product.sku,
          upc: product.upc,
          imageUrl: product.primary_image?.url_tiny,
          options: false,
          name: product.name,
          hasBarcode: ((searchedBarcodes.indexOf(product.sku) > -1) || (searchedBarcodes.indexOf(product.upc) > -1)),
          quantity: product.inventory_level,
          price: product.price,
          binPickingNumber: product.bin_picking_number
        }
      }

      searchedVariants[product.id]?.forEach((variant) => {
        let variantInList = Object.keys(variationsSection).indexOf(String(variant.Id)) > -1;
        if (!variantInList) {
          variationsSection[String(variant.id)] = {
            sku: variant.sku,
            upc: variant.upc,
            imageUrl: product.primary_image?.url_tiny,
            options: variant.option_values,
            name: variant.name || product.name,
            hasBarcode: ((searchedBarcodes.indexOf(variant.sku) > -1) || (searchedBarcodes.indexOf(variant.upc) > -1)),
            quantity: variant.inventory_level,
            price: variant.price || product.price,
            binPickingNumber: variant.bin_picking_number
          }
        }
      })
    })

    setSelectedProductList({ ...selectedProductList });
  }

  const deselectAllSelected = () => {
    setSelectedProductList({ variations: {}, products: {} });
  }

  const validatePage = (event) => {
    let page = event.target.value;

    if (page < 1) {
      page = 1;
    }

    if (page > searchedProductsPagination.total_pages) {
      page = searchedProductsPagination.total_pages;
    }

    setCurrentPage(page);
  }

  const submitPage = () => {
    handleSearch(searchTerm, currentPage);
  }

  const description = () => {
    return (
      <>
        <Text>Click on the "Manage label templates" Button if you're looking to create or modify label templates for printing.</Text>
        <Text>Search for products or variants by the "Catalog Search" bar. From the results, select the items you're looking to take action with.</Text>
        <Text>After selecting your items, you can generate new barcodes for products missing or with incorrect barcodes by clicking the "Generate Barcodes" Button.</Text>
        <Text>If you have selected items that have barcodes, you can generate a print file for those barcodes with a label template by clicking the "Print Selected Barcodes" Button.</Text>
        <Text>You can also download generated barcodes by clicking the barcode icon on any searched item where a barcode has already been generated.</Text>
        <Text>To preview and download a barcode with a specific template, click the "Preview Labels" Button on any searched item.</Text>
        <Small>Have questions or need some help? Contact us at <a href="mailto:support@athousandapps.com">support@athousandapps.com</a></Small>
      </>
    )
  }

  return(
    <>
      <PageHeader stripeBackground='#1774bb' helpLink="bigcommerce/manage-barcode-label-templates-2/" />
      <SettingsPage title="Labels + Print" description={description()}>
        <Panel>
          <div className="flex-container">
            <div className="flex-3 no-style">
              { !(barcodeTemplates.status === 'success' && barcodeTemplates.templates.length === 0) && 
                <FeatureCard
                  title="My Label Templates"
                  description="Create and update label templates for different printers and label sizes."
                  buttonText="Manage label templates"
                  linkUrl="/templates"
                  showImage={false}
                />
              }

              { barcodeTemplates.status !== 'success' && 
                <LoadingSpinner size="xxSmall" statusText="Loading Label Templates" />
              }

              { barcodeTemplates.templates.length > 0 && barcodeTemplates.status === 'success' && <span>
                { barcodeTemplates.templates.length } Templates Available
              </span>
              }

              { barcodeTemplates.status === 'success' && barcodeTemplates.templates.length === 0 && 
                <NoLabelTemplatesBox />
              }
            </div>
            <div className="flex-1 left-15">
              <Panel>
                <div className="text-center"><strong>{ selectedProductCount }</strong> Items selected</div>

                <div className="bottom-5">
                  { selectedProductCount > 0 &&
                    <Button variant="primary" onClick={() => setShowGenerateDialog(true)}>
                      Generate Barcodes
                    </Button>
                  }
                  { selectedProductCount === 0 &&
                    <Button className="full-width" variant="primary" disabled>
                      Generate Barcodes
                    </Button>
                  }
                </div>

                { (selectedProductCount > 0) &&
                  <Link to={`/print`}>
                    <Button variant="secondary" className="full-width">Print Selected Labels</Button>
                  </Link>
                }
                { (selectedProductCount === 0) &&
                  <Button variant="secondary" className="full-width" disabled>Print Selected Labels</Button>
                }
                { (missingBarcodeCount > 0) &&
                  <>
                    <br/>
                    <Text color="warning40">
                      <strong>{ missingBarcodeCount }</strong> missing barcodes
                    </Text>
                  </>
                }
                <hr/>
                <Button variant="subtle" onClick={() => setShowGenerateAllDialog(true)}>
                  Generate All Barcodes
                </Button>
              </Panel>
            </div>
          </div>
          <hr/>
          <div className="full-width">
            <Box marginBottom="medium">
              <H2>Catalog Search</H2>
              <Search onChange={validateTerm} onSubmit={() => handleSearch(searchTerm)} value={searchTerm} />
              <Collapse title="Filters">
                <div className="background-darken padding-20 flex-container">
                  <div className="flex-1">
                    <H4>In Stock</H4>
                    <Switch checked={inStockFilter} onChange={toggleInStockFilter} />
                  </div>
                  <div className="flex-1">
                    <H4>Purchasing Disabled</H4>
                    <Switch checked={purchasingDisabledFilter} onChange={togglePurchasingDisabledFilter} />
                  </div>
                  <div className="flex-2">
                    <H4>Cagetory</H4>
                    { !categories.isFetching && categories.status === "success" &&
                      <>
                        <Dropdown
                          items={categoryItems()}
                          maxHeight={250}
                          placement="bottom-start"
                          toggle={<Button iconRight={<ArrowDropDownIcon />}>{categories.categories[categoryFilter] || "Select Category"}</Button>}
                        />
                        <Button variant="subtle" className="left-15 bottom-5" onClick={() => clearCategory()}>
                          Clear Category
                        </Button>
                      </>
                    }
                    { categories.isFetching &&
                      <LoadingSpinner size="xxSmall" statusText="Loading categories"/>
                    }
                  </div>
                </div>
                <hr/>
              </Collapse>
            </Box>

            { (searchedProducts || searchedVariants) && (searchedProducts.length > 0 || searchedVariants.length > 0) &&
              <Button className="top-5" onClick={() => selectAllSearched()}>Select All Searched Items</Button>
            }
            { ((!searchedProducts && !searchedVariants ) || (searchedProducts.length === 0 && searchedVariants.length === 0)) &&
              <Button className="top-5" disabled>Select All Searched Items</Button>
            }

            { (Object.keys(selectedProductList.products).length + Object.keys(selectedProductList.variations).length ) > 0 &&
              <Button className="top-5 left-15" onClick={() => deselectAllSelected()}>Deselect All Selected Items</Button>
            }
            { (Object.keys(selectedProductList.products).length + Object.keys(selectedProductList.variations).length ) === 0 &&
              <Button className="top-5 left-15" disabled>Deselect All Selected Items</Button>
            }

          </div>
        </Panel>

        { generateActions.map(notice =>
          <GeneratingBarcodesNotice 
            notice={notice}
          />
        )}

        <Panel>
          { !isSearching && searchedProducts === null && searchTerm === '' &&
            <Box
              backgroundColor="primary20"
              border="box"
              borderRadius="normal"
              padding="medium"
            >
              <H4>Search Results</H4>
              <Text>
                Search your store's catalog above to see live results here
              </Text>
            </Box>
          }

          { isSearching && <LoadingSpinner size="small" statusText="Searching products" /> }

          { !isSearching && searchedProducts !== null && 
            <div>
              { searchedProductsPagination.total_pages > 1 &&
                <div className="flex-container">
                  <div className="flex-1">
                    <Pagination
                      currentPage={searchedProductsPagination.current_page}
                      itemsPerPage={50}
                      itemsPerPageOptions={[]}
                      onItemsPerPageChange={null}
                      onPageChange={(newPage) => setProductResultPage(newPage)}
                      totalItems={searchedProductsPagination.total}
                    />
                  </div>
                  <div className="flex-1">
                    <div className="flex-container">
                      <div className="flex-1">
                        <div className="right-15">
                          <Input
                            required
                            onChange={validatePage}
                            placeholder="Page"
                            type="text"
                            value={currentPage}
                            type="number"
                          />
                        </div>
                      </div>

                      <div className="flex-8">
                        <Text as="span">
                          of {searchedProductsPagination.total_pages} pages &nbsp;
                        </Text>

                        <Button className="left-15" onClick={() => submitPage()}>Set Page</Button>
                      </div>
                    </div>
                  </div>
                </div>
              }

              { searchedProducts?.map(product => 
                <>
                  <ItemLine
                    payload={payload}
                    type={'product'}
                    itemId={product.id}
                    sku={product.sku}
                    upc={product.upc}
                    options={false}
                    name={product.name}
                    brand={brands[product.brand_id]}
                    imageUrl={product.primary_image?.url_tiny}
                    rowId={product.id}
                    price={product.price}
                    quantity={product.inventory_level}
                    inventoryTracking={product.inventory_tracking}
                    setDownloadSubmitted={setDownloadSubmitted}
                    hasVariants={searchedVariants[product.id]?.length > 0}
                    binPickingNumber={product.bin_picking_number}
                  />
                  { searchedVariants[product.id]?.map(variant =>
                    <ItemLine
                      payload={payload}
                      type={'variant'}
                      itemId={variant.id}
                      sku={variant.sku}
                      upc={variant.upc}
                      name={product.name}
                      brand={brands[product.brand_id]}
                      imageUrl={product.primary_image?.url_tiny}
                      options={variant.option_values}
                      price={variant.price || product.price}
                      quantity={variant.inventory_level}
                      inventoryTracking={false}
                      setDownloadSubmitted={setDownloadSubmitted}
                      hasVariants={false}
                      binPickingNumber={variant.bin_picking_number}
                    />
                  )}
                </>
              )}
            </div>
          }
        </Panel>

      </SettingsPage>
      <ConfirmGenerationDialog
        payload={payload}
        setShowGenerateDialog={setShowGenerateDialog}
        showGenerateDialog={showGenerateDialog}
      />
      <ConfirmGenerateAllDialog
        payload={payload}
        setShowGenerateAllDialog={setShowGenerateAllDialog}
        showGenerateAllDialog={showGenerateAllDialog}
      />
    </>
  )
}

const mapStateToProps = state => ({
  stores: state.stores,
  users: state.users,
  isSearching: state.products.isFetching,
  brands: state.products.searchedBrands,
  searchedProducts: state.products.searchedProducts,
  searchedProductsPagination: state.products.searchedProductsPagination,
  searchedVariants: state.products.searchedVariants,
  selectedProductList: state.products.selectedProductList,
  selectedProductCount: Object.keys(state.products.selectedProductList.variations).length + Object.keys(state.products.selectedProductList.products).length,
  generateActions: state.barcodes.generateActions,
  downloadUrl: state.barcodes.downloadUrl,
  barcodeTemplates: state.barcodeTemplates,
  searchedBarcodes: state.products.searchedBarcodes,
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({
  submitSearch: (payload, searchTerm, inStockFilter, purchasingDisabledFilter, categoryFilter, page) => dispatch(searchProducts(payload, searchTerm, inStockFilter, purchasingDisabledFilter, categoryFilter, page)),
  batchRefresh: (payload) => dispatch(refreshBatches(payload)),
  getTemplates: (payload) => dispatch(indexBarcodeTemplates(payload)),
  loadCategories: (payload) => dispatch(getCategories(payload)),

  setSelectedProductList: (items) => dispatch(updateSelectedItems(items)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageBarcodes);
