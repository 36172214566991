import {
  PAYMENT_REQUESTED,
  PAYMENT_SUCCESS,
  PAYMENT_FAILURE,
  PAYMENT_SESSION_REQUESTED,
  PAYMENT_SESSION_SUCCESS,
  PAYMENT_SESSION_FAILURE,
  PAYMENT_VERIFICATION_REQUESTED,
  PAYMENT_VERIFICATION_SUCCESS,
  PAYMENT_VERIFICATION_FAILURE,
} from '../types';

const INITIAL_STATE = {
  isFetching: false,
  clientSecret: null,
  paymentStatus: null,
  cancelAtPeriodEnd: null,
  cancelAt: null,
  store_hash: null,
  status: 'waiting',
  verificationStatus: 'waiting',
  errors: null
};

const payments = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case PAYMENT_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        paymentStatus: null,
        clientSecret: null,
        cancelAtPeriodEnd: null,
        cancelAt: null,
        paymentPortalUrl: null,
        status: 'requesting',
        store_hash: null,
        errors: null,
      });

    case PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'success',
        paymentStatus: action.payload.payment_status,
        cancelAtPeriodEnd: action.payload.cancel_at_period_end,
        cancelAt: action.payload.cancel_at,
        paymentPortalUrl: action.payload.payment_portal_url,
        clientSecret: null,
        store_hash: null,
        errors: null
      });

    case PAYMENT_FAILURE:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'waiting',
        paymentStatus: null,
        store_hash: null,
        errors: 'error here'
      });

    case PAYMENT_SESSION_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        paymentStatus: null,
        clientSecret: null,
        status: 'requesting',
        store_hash: null,
        errors: null,
      });

    case PAYMENT_SESSION_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'success',
        paymentStatus: null,
        clientSecret: action.payload.client_secret,
        store_hash: action.payload.store_hash,
        errors: null
      });

    case PAYMENT_SESSION_FAILURE:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'waiting',
        paymentStatus: null,
        clientSecret: null,
        store_hash: null,
        errors: 'error here'
      });


    case PAYMENT_VERIFICATION_REQUESTED:
      return Object.assign({}, null, {
        isFetching: true,
        status: 'requesting',
        verificationStatus: 'requesting',
        paymentStatus: null,
        clientSecret: null,
        store_hash: null,
        errors: null
      });
    
    case PAYMENT_VERIFICATION_SUCCESS:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'success',
        verificationStatus: 'success',
        paymentStatus: action.payload.payment_status,
        clientSecret: null,
        store_hash: null,
        verificationRedirect: false,
        errors: null
      });

    case PAYMENT_VERIFICATION_FAILURE:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'waiting',
        verificationStatus: 'waiting',
        paymentStatus: null,
        clientSecret: null,
        store_hash: null,
        errors: null
      });

    default:
      return state;
  }
}

export default payments;