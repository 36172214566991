import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from "react-router-dom";

import { Button, Text, H3, H2, H4, Dropdown, Panel, Table, Small } from '@bigcommerce/big-design';
import { ArrowDropDownIcon } from '@bigcommerce/big-design-icons';

import { Document, Page, pdfjs } from 'react-pdf';
import PageHeader from '../partials/PageHeader';
import { indexBarcodeTemplates, previewBarcodeTemplate, getTemplateFungibles, getTemplateLineSettings } from '../../redux/actions/barcodeTemplates';
import { searchProductBarcodeValue, downloadBarcodePreview } from '../../redux/actions/products';

import LoadingSpinner from '../partials/LoadingSpinner';
import LoadingPlaceholder from '../partials/LoadingPlaceholder';
import SettingsPage from '../partials/SettingsPage';
import NoLabelTemplatesBox from '../partials/NoLabelTemplatesBox';

const BarcodeActions = ({ payload, getByBarcodeValue, getTemplates, activeBarcode, barcodeTemplates, loadPreview, downloadPreview, downloadUrl, products, templateFungibles, templateLineSettings, loadFungibles, loadLineSettings }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  
  let { barcode, brand } = useParams();

  const [activeTemplate, setActiveTemplate] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [downloadSubmitted, setDownloadSubmitted] = useState(false);
  // const [activeBarcode, setActiveBarcode] = useState(barcode);


  useEffect(() => {
    getTemplates(payload);
    getByBarcodeValue(payload, barcode);
  }, []);

  useEffect(() => {
    if(downloadUrl && downloadSubmitted) { 
      setDownloadSubmitted(false); 
      let win = window.open(downloadUrl, '_blank');
      win.focus();
    }
  }, [downloadUrl]);

  useEffect(() => {
    if(templateFungibles && templateLineSettings && barcodeTemplates.fungiblesLoaded && barcodeTemplates.lineSettingsLoaded) {

      let updatedTemplate = activeTemplate;
      updatedTemplate['barcode_enabled'] = templateFungibles[activeTemplate['barcode_element_uuid']]['enabled'];
      updatedTemplate['barcode_line'] = templateFungibles[activeTemplate['barcode_element_uuid']]['line'];
      updatedTemplate['barcode_symbol_enabled'] = templateFungibles[activeTemplate['barcode_symbol_element_uuid']]['enabled'];
      updatedTemplate['barcode_symbol_line'] = templateFungibles[activeTemplate['barcode_symbol_element_uuid']]['line'];
      updatedTemplate['barcode_symbol_priority'] = templateFungibles[activeTemplate['barcode_symbol_element_uuid']]['priority'];
      updatedTemplate['barcode_symbol_label_enabled'] = templateFungibles[activeTemplate['barcode_symbol_element_uuid']]['label_enabled'];
      updatedTemplate['barcode_symbol_label_override'] = templateFungibles[activeTemplate['barcode_symbol_element_uuid']]['label_override'];
      updatedTemplate['product_name_enabled'] = templateFungibles[activeTemplate['product_name_element_uuid']]['enabled'];
      updatedTemplate['product_name_line'] = templateFungibles[activeTemplate['product_name_element_uuid']]['line'];
      updatedTemplate['product_name_priority'] = templateFungibles[activeTemplate['product_name_element_uuid']]['priority'];
      updatedTemplate['product_name_label_enabled'] = templateFungibles[activeTemplate['product_name_element_uuid']]['label_enabled'];
      updatedTemplate['product_name_label_override'] = templateFungibles[activeTemplate['product_name_element_uuid']]['label_override'];
      updatedTemplate['option_1_enabled'] = templateFungibles[activeTemplate['option_1_element_uuid']]['enabled'];
      updatedTemplate['option_1_line'] = templateFungibles[activeTemplate['option_1_element_uuid']]['line'];
      updatedTemplate['option_1_priority'] = templateFungibles[activeTemplate['option_1_element_uuid']]['priority'];
      updatedTemplate['option_1_label_enabled'] = templateFungibles[activeTemplate['option_1_element_uuid']]['label_enabled'];
      updatedTemplate['option_1_label_override'] = templateFungibles[activeTemplate['option_1_element_uuid']]['label_override'];
      updatedTemplate['option_2_enabled'] = templateFungibles[activeTemplate['option_2_element_uuid']]['enabled'];
      updatedTemplate['option_2_line'] = templateFungibles[activeTemplate['option_2_element_uuid']]['line'];
      updatedTemplate['option_2_priority'] = templateFungibles[activeTemplate['option_2_element_uuid']]['priority'];
      updatedTemplate['option_2_label_enabled'] = templateFungibles[activeTemplate['option_2_element_uuid']]['label_enabled'];
      updatedTemplate['option_2_label_override'] = templateFungibles[activeTemplate['option_2_element_uuid']]['label_override'];
      updatedTemplate['option_3_enabled'] = templateFungibles[activeTemplate['option_3_element_uuid']]['enabled'];
      updatedTemplate['option_3_line'] = templateFungibles[activeTemplate['option_3_element_uuid']]['line'];
      updatedTemplate['option_3_priority'] = templateFungibles[activeTemplate['option_3_element_uuid']]['priority'];
      updatedTemplate['option_3_label_enabled'] = templateFungibles[activeTemplate['option_3_element_uuid']]['label_enabled'];
      updatedTemplate['option_3_label_override'] = templateFungibles[activeTemplate['option_3_element_uuid']]['label_override'];
      updatedTemplate['price_enabled'] = templateFungibles[activeTemplate['price_element_uuid']]['enabled'];
      updatedTemplate['price_line'] = templateFungibles[activeTemplate['price_element_uuid']]['line'];
      updatedTemplate['price_priority'] = templateFungibles[activeTemplate['price_element_uuid']]['priority'];
      updatedTemplate['price_label_enabled'] = templateFungibles[activeTemplate['price_element_uuid']]['label_enabled'];
      updatedTemplate['price_label_override'] = templateFungibles[activeTemplate['price_element_uuid']]['label_override'];
      updatedTemplate['brand_enabled'] = templateFungibles[activeTemplate['brand_element_uuid']]['enabled'];
      updatedTemplate['brand_line'] = templateFungibles[activeTemplate['brand_element_uuid']]['line'];
      updatedTemplate['brand_priority'] = templateFungibles[activeTemplate['brand_element_uuid']]['priority'];
      updatedTemplate['brand_label_enabled'] = templateFungibles[activeTemplate['brand_element_uuid']]['label_enabled'];
      updatedTemplate['brand_label_override'] = templateFungibles[activeTemplate['brand_element_uuid']]['label_override'];

      updatedTemplate['line_settings'] = templateLineSettings;

      setActiveTemplate(updatedTemplate);

      activeBarcode.brand = brand;

      loadPreview(payload, updatedTemplate, activeBarcode);
    }
  }, [templateFungibles, templateLineSettings]);

  const switchActiveTemplate = (event) => {
    let template = barcodeTemplates.templates.find(template => template.uuid === event.hash);

    loadFungibles(payload, event.hash);
    loadLineSettings(payload, event.hash);

    let activeValues = activeBarcode;
    activeValues.options = activeBarcode.option_values;

    setActiveTemplate(template);
    // loadPreview(payload, template, activeValues);
  }

  const handleDownload = () => {
    setDownloadSubmitted(true);
    downloadPreview(payload);
  }

  const description = () => {
    return(
      <>
        <Small>Have questions or need some help? Contact us at <a href="mailto:support@athousandapps.com">support@athousandapps.com</a></Small>
      </>
    )
  }

  const templates = () => {
    let templateItems = [];

    barcodeTemplates.templates.map(template => 
      templateItems.push(
        {
          content: template.template_name,
          onItemClick: (event) => switchActiveTemplate(event),
          hash: template.uuid
        }
      )
    )

    return templateItems;
  }

  const tableItems = () => {
    let items = [];

    items.push({ property: 'Barcode Value', value: barcode });
    items.push({ property: 'Product Name', value: activeBarcode.name });
    activeBarcode.option_values?.map(option =>
      items.push({ property: option.option_display_name, value: option.label })
    )

    return items;
  }

  if(barcodeTemplates.status === 'success' && activeBarcode) {
    return(
      <>
        <PageHeader stripeBackground='#5bc6cc' helpLink="bigcommerce/print-barcode-labels/" />
        <SettingsPage title="Barcode Actions" description={description()}>
          <Panel>
            <H2>Preview With Template</H2>
            { barcodeTemplates.templates.length === 0 && 
              <NoLabelTemplatesBox/>
            }
            { barcodeTemplates.templates.length > 0 &&
              <>
                <H4>Template</H4>
                <Dropdown
                  items={templates()}
                  maxHeight={250}
                  placement="bottom-start"
                  toggle={<Button iconRight={<ArrowDropDownIcon />}>{activeTemplate?.template_name || 'Template'}</Button>}
                />
              </>
            }
            <hr/>
            <div className="info-background">
              <H3>Preview</H3>
              { (Object.keys(activeTemplate).length > 0) && barcodeTemplates.previewUri &&
                <>
                  <Document className="pdf-document" file={{ url: barcodeTemplates.previewUri }} loading={<LoadingSpinner />}>
                    <Page
                      className="pdf-page"
                      pageNumber={1} 
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                    />
                  </Document>
                  { !downloadSubmitted && <div className="top-15"><Button onClick={() => handleDownload()}>Download Preview</Button></div> }
                  { downloadSubmitted && <div className="top-15"><Button disabled>Download Preview</Button></div> }
                </>
              }
              { !barcodeTemplates.previewUri && (Object.keys(activeTemplate).length === 0) &&
                <Text color="secondary60">Select template to preview</Text>
              }
              { !barcodeTemplates.previewUri && (Object.keys(activeTemplate).length > 0) &&
                <LoadingSpinner />
              }
            </div>
            <hr/>
            <Table
              columns={[
                { header: 'Item Property', hash: 'property', render: ({ property }) => property },
                { header: 'Value', hash: 'value', render: ({ value }) => value }
              ]}
              items={tableItems()}
            />
          </Panel>
        </SettingsPage>
      </>
    )
  }


  return (
    <>
      <PageHeader stripeBackground='#5bc6cc' helpLink="bigcommerce/print-barcode-labels/" />
      <SettingsPage title="Barcode Actions" description={description()}>
        <LoadingPlaceholder title="Barcode Actions"/>
      </SettingsPage>
    </>
  )
}

const mapStateToProps = state => ({
  products: state.products,
  searchedBarcodes: state.products.searchedBarcodes,
  barcodeTemplates: state.barcodeTemplates,
  activeBarcode: state.products.activeBarcode,
  downloadUrl: state.products.downloadUrl,
  templateFungibles: state.barcodeTemplates.templateFungibles,
  templateLineSettings: state.barcodeTemplates.templateLineSettings,
  settings: state.settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
  getByBarcodeValue: (payload, barcodeValue) => dispatch(searchProductBarcodeValue(payload, barcodeValue)),
  getTemplates: (payload) => dispatch(indexBarcodeTemplates(payload)),
  loadPreview: (payload, options, values) => dispatch(previewBarcodeTemplate(payload, options, values)),
  downloadPreview: (payload) => dispatch(downloadBarcodePreview(payload)),
  loadFungibles: (payload, uuid) => dispatch(getTemplateFungibles(payload, uuid)),
  loadLineSettings: (payload, uuid) => dispatch(getTemplateLineSettings(payload, uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BarcodeActions);  