import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams, useNavigate } from 'react-router-dom';

import LoadingSpinner from '../partials/LoadingSpinner';
import LoadingPlaceholder from '../partials/LoadingPlaceholder';
import SettingsPage from '../partials/SettingsPage';
import PageHeader from "../partials/PageHeader";
import { Button, Text, H2, H3, H4, Panel, Input, FormControlLabel, Switch, FormGroup, Radio, Checkbox, Small, Table, Toggle } from '@bigcommerce/big-design';
import PresetSection from './PresetSection';

import SlTab from '@shoelace-style/shoelace/dist/react/tab';
import SlTabGroup from '@shoelace-style/shoelace/dist/react/tab-group';
import SlTabPanel from '@shoelace-style/shoelace/dist/react/tab-panel';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';

import TemplateLineOrderContainer from './TemplateLineOrderContainer';

import { updateBarcodeTemplate, previewBarcodeTemplate, getTemplateFungibles, getTemplateLineSettings } from '../../redux/actions/barcodeTemplates';

const EditTemplateForm = ({ payload, newBarcodeTemplate, saveTemplateChanges, barcodeTemplates, loadPreview, templateDetails, newTemplate, loadFungibles, loadLineSettings, templateFungibles, templateLineSettings }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  
  let navigate = useNavigate();
  const { uuid } = useParams();

  const [submitted, setSubmitted] = useState(false);
  const [validTemplate, setValidTemplate] = useState(true);
  const [validationErrors, setValidationErrors] = useState([]);

  const [templateSaved, setTemplateSaved] = useState(true);

  const [templateForm, setTemplateForm] = useState(barcodeTemplates.templates.find(template => template.uuid === uuid));

  const [templateName, setTemplateName] = useState(templateForm['template_name']);
  const [rotate, setRotate] = useState(templateForm['rotate']);
  const [printType, setPrintType] = useState(templateForm['print_type']);

  const [length, setLength] = useState(templateForm['length']);
  const [width, setWidth] = useState(templateForm['width']);
  
  const [sizeMeasurement, setSizeMeasurement] = useState(templateForm['size_measurement']);
  const [lineCount, setLineCount] = useState(templateForm['line_count']);

  const [lineSettings, setLineSettings] = useState([]);

  const [pageWidth, setPageWidth] = useState(templateForm?.page_definition?.page_width || 8.5);
  const [pageLength, setPageLength] = useState(templateForm.page_definition?.page_length || 11);
  const [pageXMargin, setPageXMargin] = useState(templateForm.page_definition?.page_x_margin || 0.0);
  const [pageYMargin, setPageYMargin] = useState(templateForm.page_definition?.page_y_margin || 0.0);
  const [pageRows, setPageRows] = useState(templateForm.page_definition?.rows || 15);
  const [pageColumns, setPageColumns] = useState(templateForm.page_definition?.columns || 3);
  const [pageLabelXPadding, setPageLabelXPadding] = useState(templateForm.page_definition?.label_x_padding || 0.0);
  const [pageLabelYPadding, setPageLabelYPadding] = useState(templateForm.page_definition?.label_y_padding || 0.0);

  const [barcodeEnabled, setBarcodeEnabled] = useState(null);
  const [barcodeLine, setBarcodeLine] = useState(null);
  const [barcodeHeightPercent, setBarcodeHeightPercent] = useState(null);

  const [storeLogoEnabled, setStoreLogoEnabled] = useState(false);
  const [storeLogoLine, setStoreLogoLine] = useState(0);
  const [storeLogoHeightPercent, setStoreLogoHeightPercent] = useState(50);

  const [barcodeSymbolEnabled, setBarcodeSymbolEnabled] = useState(null);
  const [barcodeSymbolLine, setBarcodeSymbolLine] = useState(null);
  const [barcodeSymbolPriority, setBarcodeSymbolPriority] = useState(null);
  const [barcodeSymbolLabelEnabled, setBarcodeSymbolLabelEnabled] = useState(null);
  const [barcodeSymbolLabelOverride, setBarcodeSymbolLabelOverride] = useState(null);

  const [productNameEnabled, setProductNameEnabled] = useState(null);
  const [productNameLine, setProductNameLine] = useState(null);
  const [productNamePriority, setProductNamePriority] = useState(null);
  const [productNameLabelEnabled, setProductNameLabelEnabled] = useState(null);
  const [productNameLabelOverride, setProductNameLabelOverride] = useState(null);

  const [optionOneEnabled, setOptionOneEnabled] = useState(null);
  const [optionOneLine, setOptionOneLine] = useState(null);
  const [optionOnePriority, setOptionOnePriority] = useState(null);
  const [optionOneLabelEnabled, setOptionOneLabelEnabled] = useState(null);
  const [optionOneLabelOverride, setOptionOneLabelOverride] = useState(null);

  const [optionTwoEnabled, setOptionTwoEnabled] = useState(null);
  const [optionTwoLine, setOptionTwoLine] = useState(null);
  const [optionTwoPriority, setOptionTwoPriority] = useState(null);
  const [optionTwoLabelEnabled, setOptionTwoLabelEnabled] = useState(null);
  const [optionTwoLabelOverride, setOptionTwoLabelOverride] = useState(null);

  const [optionThreeEnabled, setOptionThreeEnabled] = useState(null);
  const [optionThreeLine, setOptionThreeLine] = useState(null);
  const [optionThreePriority, setOptionThreePriority] = useState(null);
  const [optionThreeLabelEnabled, setOptionThreeLabelEnabled] = useState(null);
  const [optionThreeLabelOverride, setOptionThreeLabelOverride] = useState(null);

  const [priceEnabled, setPriceEnabled] = useState(null);
  const [priceLine, setPriceLine] = useState(null);
  const [pricePriority, setPricePriority] = useState(null);
  const [priceLabelEnabled, setPriceLabelEnabled] = useState(null);
  const [priceLabelOverride, setPriceLabelOverride] = useState(null);

  const [brandEnabled, setBrandEnabled] = useState(null);
  const [brandLine, setBrandLine] = useState(null);
  const [brandPriority, setBrandPriority] = useState(null);
  const [brandLabelEnabled, setBrandLabelEnabled] = useState(null);
  const [brandLabelOverride, setBrandLabelOverride] = useState(null);

  const [arbitraryTextEnabled, setArbitraryTextEnabled] = useState(false);
  const [arbitraryTextLine, setArbitraryTextLine] = useState(0);
  const [arbitraryTextPriority, setArbitraryTextPriority] = useState(8);
  const [arbitraryTextLabelEnabled, setArbitraryTextLabelEnabled] = useState(false);
  const [arbitraryTextLabelOverride, setArbitraryTextLabelOverride] = useState('');

  const [activePreviewuri, setActivePreviewUri] = useState(null);

  const [previewGenerating, setPreviewGenerating] = useState(false);
  const [enqueuePreviewGeneration, setEnqueuePreviewGeneration] = useState(null);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    loadFungibles(payload, uuid);
    loadLineSettings(payload, uuid);
  }, []);

  useEffect(() => {
    if(newTemplate) { navigate(-1) };
  }, [newTemplate]);

  useEffect(() => {
    let updatedTemplate = templateForm;
    updatedTemplate['line_settings'] = lineSettings;

    handlePreview(updatedTemplate, null);
  }, [lineSettings]);

  useEffect(() => {
    if(templateLineSettings && barcodeTemplates.lineSettingsLoaded) {
      setLineSettings(templateLineSettings);
    }
  }, [templateLineSettings]);

  useEffect(() => {
    if(templateFungibles && barcodeTemplates.fungiblesLoaded) {
      let updatedTemplate = templateForm;

      setBarcodeEnabled(templateFungibles[templateForm['barcode_element_uuid']]['enabled']);
      updatedTemplate['barcode_enabled'] = templateFungibles[templateForm['barcode_element_uuid']]['enabled'];
      setBarcodeLine(templateFungibles[templateForm['barcode_element_uuid']]['line']);
      updatedTemplate['barcode_line'] = templateFungibles[templateForm['barcode_element_uuid']]['line'];
      setBarcodeHeightPercent(templateForm['barcode_height_percent']);

      setStoreLogoEnabled(templateFungibles[templateForm['store_logo_element_uuid']]['enabled']);
      updatedTemplate['store_logo_enabled'] = templateFungibles[templateForm['store_logo_element_uuid']]['enabled'];
      setStoreLogoLine(templateFungibles[templateForm['store_logo_element_uuid']]['line']);
      updatedTemplate['store_logo_line'] = templateFungibles[templateForm['store_logo_element_uuid']]['line'];
      setStoreLogoHeightPercent(templateForm['store_logo_height_percent']);

      setBarcodeSymbolEnabled(templateFungibles[templateForm['barcode_symbol_element_uuid']]['enabled']);
      updatedTemplate['barcode_symbol_enabled'] = templateFungibles[templateForm['barcode_symbol_element_uuid']]['enabled'];
      setBarcodeSymbolLine(templateFungibles[templateForm['barcode_symbol_element_uuid']]['line']);
      updatedTemplate['barcode_symbol_line'] = templateFungibles[templateForm['barcode_symbol_element_uuid']]['line'];
      setBarcodeSymbolPriority(templateFungibles[templateForm['barcode_symbol_element_uuid']]['priority']);
      updatedTemplate['barcode_symbol_priority'] = templateFungibles[templateForm['barcode_symbol_element_uuid']]['priority'];
      setBarcodeSymbolLabelEnabled(templateFungibles[templateForm['barcode_symbol_element_uuid']]['label_enabled']);
      updatedTemplate['barcode_symbol_label_enabled'] = templateFungibles[templateForm['barcode_symbol_element_uuid']]['label_enabled'];
      setBarcodeSymbolLabelOverride(templateFungibles[templateForm['barcode_symbol_element_uuid']]['label_override']);
      updatedTemplate['barcode_symbol_label_override'] = templateFungibles[templateForm['barcode_symbol_element_uuid']]['label_override'];

      setProductNameEnabled(templateFungibles[templateForm['product_name_element_uuid']]['enabled']);
      updatedTemplate['product_name_enabled'] = templateFungibles[templateForm['product_name_element_uuid']]['enabled'];
      setProductNameLine(templateFungibles[templateForm['product_name_element_uuid']]['line']);
      updatedTemplate['product_name_line'] = templateFungibles[templateForm['product_name_element_uuid']]['line'];
      setProductNamePriority(templateFungibles[templateForm['product_name_element_uuid']]['priority']);
      updatedTemplate['product_name_priority'] = templateFungibles[templateForm['product_name_element_uuid']]['priority'];
      setProductNameLabelEnabled(templateFungibles[templateForm['product_name_element_uuid']]['label_enabled']);
      updatedTemplate['product_name_label_enabled'] = templateFungibles[templateForm['product_name_element_uuid']]['label_enabled'];
      setProductNameLabelOverride(templateFungibles[templateForm['product_name_element_uuid']]['label_override']);
      updatedTemplate['product_name_label_override'] = templateFungibles[templateForm['product_name_element_uuid']]['label_override'];

      setOptionOneEnabled(templateFungibles[templateForm['option_1_element_uuid']]['enabled']);
      updatedTemplate['option_1_enabled'] = templateFungibles[templateForm['option_1_element_uuid']]['enabled'];
      setOptionOneLine(templateFungibles[templateForm['option_1_element_uuid']]['line']);
      updatedTemplate['option_1_line'] = templateFungibles[templateForm['option_1_element_uuid']]['line'];
      setOptionOnePriority(templateFungibles[templateForm['option_1_element_uuid']]['priority']);
      updatedTemplate['option_1_priority'] = templateFungibles[templateForm['option_1_element_uuid']]['priority'];
      setOptionOneLabelEnabled(templateFungibles[templateForm['option_1_element_uuid']]['label_enabled']);
      updatedTemplate['option_1_label_enabled'] = templateFungibles[templateForm['option_1_element_uuid']]['label_enabled'];
      setOptionOneLabelOverride(templateFungibles[templateForm['option_1_element_uuid']]['label_override']);
      updatedTemplate['option_1_label_override'] = templateFungibles[templateForm['option_1_element_uuid']]['label_override'];

      setOptionTwoEnabled(templateFungibles[templateForm['option_2_element_uuid']]['enabled']);
      updatedTemplate['option_2_enabled'] = templateFungibles[templateForm['option_2_element_uuid']]['enabled'];
      setOptionTwoLine(templateFungibles[templateForm['option_2_element_uuid']]['line']);
      updatedTemplate['option_2_line'] = templateFungibles[templateForm['option_2_element_uuid']]['line'];
      setOptionTwoPriority(templateFungibles[templateForm['option_2_element_uuid']]['priority']);
      updatedTemplate['option_2_priority'] = templateFungibles[templateForm['option_2_element_uuid']]['priority'];
      setOptionTwoLabelEnabled(templateFungibles[templateForm['option_2_element_uuid']]['label_enabled']);
      updatedTemplate['option_2_label_enabled'] = templateFungibles[templateForm['option_2_element_uuid']]['label_enabled'];
      setOptionTwoLabelOverride(templateFungibles[templateForm['option_2_element_uuid']]['label_override']);
      updatedTemplate['option_2_label_override'] = templateFungibles[templateForm['option_2_element_uuid']]['label_override'];

      setOptionThreeEnabled(templateFungibles[templateForm['option_3_element_uuid']]['enabled']);
      updatedTemplate['option_3_enabled'] = templateFungibles[templateForm['option_3_element_uuid']]['enabled'];
      setOptionThreeLine(templateFungibles[templateForm['option_3_element_uuid']]['line']);
      updatedTemplate['option_3_line'] = templateFungibles[templateForm['option_3_element_uuid']]['line'];
      setOptionThreePriority(templateFungibles[templateForm['option_3_element_uuid']]['priority']);
      updatedTemplate['option_3_priority'] = templateFungibles[templateForm['option_3_element_uuid']]['priority'];
      setOptionThreeLabelEnabled(templateFungibles[templateForm['option_3_element_uuid']]['label_enabled']);
      updatedTemplate['option_3_label_enabled'] = templateFungibles[templateForm['option_3_element_uuid']]['label_enabled'];
      setOptionThreeLabelOverride(templateFungibles[templateForm['option_3_element_uuid']]['label_override']);
      updatedTemplate['option_3_label_override'] = templateFungibles[templateForm['option_3_element_uuid']]['label_override'];

      setPriceEnabled(templateFungibles[templateForm['price_element_uuid']]['enabled']);
      updatedTemplate['price_enabled'] = templateFungibles[templateForm['price_element_uuid']]['enabled'];
      setPriceLine(templateFungibles[templateForm['price_element_uuid']]['line']);
      updatedTemplate['price_line'] = templateFungibles[templateForm['price_element_uuid']]['line'];
      setPricePriority(templateFungibles[templateForm['price_element_uuid']]['priority']);
      updatedTemplate['price_priority'] = templateFungibles[templateForm['price_element_uuid']]['priority'];
      setPriceLabelEnabled(templateFungibles[templateForm['price_element_uuid']]['label_enabled']);
      updatedTemplate['price_label_enabled'] = templateFungibles[templateForm['price_element_uuid']]['label_enabled'];
      setPriceLabelOverride(templateFungibles[templateForm['price_element_uuid']]['label_override']);
      updatedTemplate['price_label_override'] = templateFungibles[templateForm['price_element_uuid']]['label_override'];

      setBrandEnabled(templateFungibles[templateForm['brand_element_uuid']]['enabled']);
      updatedTemplate['brand_enabled'] = templateFungibles[templateForm['brand_element_uuid']]['enabled'];
      setBrandLine(templateFungibles[templateForm['brand_element_uuid']]['line']);
      updatedTemplate['brand_line'] = templateFungibles[templateForm['brand_element_uuid']]['line'];
      setBrandPriority(templateFungibles[templateForm['brand_element_uuid']]['priority']);
      updatedTemplate['brand_priority'] = templateFungibles[templateForm['brand_element_uuid']]['priority'];
      setBrandLabelEnabled(templateFungibles[templateForm['brand_element_uuid']]['label_enabled']);
      updatedTemplate['brand_label_enabled'] = templateFungibles[templateForm['brand_element_uuid']]['label_enabled'];
      setBrandLabelOverride(templateFungibles[templateForm['brand_element_uuid']]['label_override']);
      updatedTemplate['brand_label_override'] = templateFungibles[templateForm['brand_element_uuid']]['label_override'];

      setArbitraryTextEnabled(templateFungibles[templateForm['arbitrary_text_element_uuid']]['enabled']);
      updatedTemplate['arbitrary_text_enabled'] = templateFungibles[templateForm['arbitrary_text_element_uuid']]['enabled'];
      setArbitraryTextLine(templateFungibles[templateForm['arbitrary_text_element_uuid']]['line']);
      updatedTemplate['arbitrary_text_line'] = templateFungibles[templateForm['arbitrary_text_element_uuid']]['line'];
      setArbitraryTextPriority(templateFungibles[templateForm['arbitrary_text_element_uuid']]['priority']);
      updatedTemplate['arbitrary_text_priority'] = templateFungibles[templateForm['arbitrary_text_element_uuid']]['priority'];
      setArbitraryTextLabelEnabled(templateFungibles[templateForm['arbitrary_text_element_uuid']]['label_enabled']);
      updatedTemplate['arbitrary_text_label_enabled'] = templateFungibles[templateForm['arbitrary_text_element_uuid']]['label_enabled'];
      setArbitraryTextLabelOverride(templateFungibles[templateForm['arbitrary_text_element_uuid']]['label_override']);
      updatedTemplate['arbitrary_text_label_override'] = templateFungibles[templateForm['arbitrary_text_element_uuid']]['label_override'];

      setTemplateForm(updatedTemplate);
    }
  }, [templateFungibles]);

  const changeLineCount = (event) => {
    let updatedTemplate = templateForm;
    let updatedLineSettings = lineSettings;

    let newLineCount = parseInt(event);

    let isIncrease = newLineCount > lineCount;

    if(isIncrease) {
      let difference = newLineCount - lineCount;

      for(let i = 0; i < difference; i++){
        updatedLineSettings.push({
          font_size: 12,
          alignment: 'left',
          position: lineCount + i
        })
      }
    } else {
      let difference = lineCount - newLineCount;

      for(let i = 0; i < difference; i++){
        updatedLineSettings.pop();
      }
    }

    setLineCount(newLineCount);

    updatedTemplate['line_count'] = newLineCount;
    updatedTemplate['line_settings'] = updatedLineSettings;

    setTemplateSaved(false);
    setTemplateForm(updatedTemplate);

    handlePreview(templateForm, null);
  }

  const setNumeric = (setField, event) => {
    let value = event.target.value;
    let updatedTemplate = templateForm;
    let valueNumeric = value.replace(/[^\d.-]+/g, '');

    if(valueNumeric > 999.9) {
      valueNumeric = 999.9
    }

    setField(valueNumeric);
    updatedTemplate[event.target.id] = valueNumeric;

    setTemplateSaved(false);
    setTemplateForm(updatedTemplate);

    handlePreview(templateForm, null);
  }

  const setInteger = (setField, event) => {
    let value = event.target.value;
    let updatedTemplate = templateForm;
    let valueNumeric = parseInt(value) || 0;

    if(valueNumeric > 99) {
      valueNumeric = 99
    }

    setField(valueNumeric);
    updatedTemplate[event.target.id] = valueNumeric;

    setTemplateSaved(false);
    setTemplateForm(updatedTemplate);
    handlePreview(templateForm, null);
  }

  const setPageValue = (setField, event) => {
    let value = event.target.value;
    let updatedTemplate = templateForm;

    let valueInteger = parseInt(value) || 0;
    let valueNumeric = value.replace(/[^\d.-]+/g, '') || 0.0;

    if(valueNumeric > 999.9) {
      valueNumeric = 999.9
    }

    if(!updatedTemplate.page_definition) {
      updatedTemplate.page_definition = {
        page_width: 8.5,
        page_length: 11,
        page_x_margin: 0.25,
        page_y_margin: 0.25,
        rows: 15,
        columns: 3,
        label_x_padding: 0.1,
        label_y_padding: 0.1
      }
    }

    if(['rows', 'columns'].includes(event.target.id)) {
      setField(valueInteger);
      updatedTemplate.page_definition[event.target.id] = valueInteger;
    } else {
      setField(valueNumeric);
      updatedTemplate.page_definition[event.target.id] = parseFloat(valueNumeric);
    }

    setTemplateSaved(false);
    setTemplateForm(updatedTemplate);

    validateTemplate(updatedTemplate);

    if (value) {
      event.target.parentElement.classList.add("field--filled");
    } else {
      event.target.parentElement.classList.remove("field--filled");
    }
  }

  const switchBoolean = (setField, field, key) => {
    let updatedTemplate = templateForm;
    updatedTemplate[key] = !field;

    setField(!field);
    setTemplateSaved(false);
    setTemplateForm(updatedTemplate);

    handlePreview(templateForm, null);
  }

  const handleSwitchRotate = () => {
    let updatedTemplate = templateForm;
    updatedTemplate['rotate'] = !rotate;

    setRotate(!rotate);
    setTemplateForm(updatedTemplate);
    setTemplateSaved(false);
    
    handlePreview(templateForm, null);
  }

  const handleSwitchPageType = (newType) => {
    let updatedTemplate = templateForm;

    updatedTemplate['print_type'] = newType;
    setPrintType(newType);

    if (newType === 'sheet') {
      setRotate(false);
      updatedTemplate['rotate'] = false;
    }

    setTemplateForm(updatedTemplate);
    setTemplateSaved(false);

    handlePreview(templateForm, null);
  }

  const setMeasurements = (event) => {
    let measurement = event.target.value;

    let updatedTemplate = templateForm;
    updatedTemplate['size_measurement'] = measurement;
    setTemplateForm(updatedTemplate);

    setSizeMeasurement(measurement);

    handlePreview(templateForm, null);
    setTemplateSaved(false);
  }

  const setPriority = (setFunction, value, key) => {
    let updatedTemplate = templateForm;

    setFunction(value);

    updatedTemplate[key] = value;

    setTemplateForm(updatedTemplate);
    setTemplateSaved(false);

    handlePreview(updatedTemplate, null);
  }

  const setFieldValue = (setField, event) => {
    let value = event.target.value;

    let updatedTemplate = templateForm;
    updatedTemplate[event.target.id] = value;
    setTemplateForm(updatedTemplate);
    setField(value);
    setTemplateSaved(false);

    handlePreview(templateForm, null);
  }

  const handleSubmit = () => {
    setSubmitted(true);
    saveTemplateChanges(payload, templateForm);
    setTemplateSaved(true);
  }

  const handlePreview = (templateForm, values) => {
    validateTemplate(templateForm);

    if(!previewGenerating) {
      setPreviewGenerating(true);
      setEnqueuePreviewGeneration(null);
      loadPreview(payload, templateForm, values);
    } else {
      setEnqueuePreviewGeneration(templateForm);
    }
  }

  const setFontSize = (position, e) => {
    let updatedTemplate = templateForm;

    setLineSettings(
      lineSettings.map((setting) =>
        setting.position === position ? { ...setting, font_size: parseInt(e.target.value) } : { ...setting }
      )
    )

    updatedTemplate['line_settings'] = lineSettings;

    setTemplateForm(updatedTemplate);
    setTemplateSaved(false);
  }

  const setAlignment = (position, e) => {
    let updatedTemplate = templateForm;

    setLineSettings(
      lineSettings.map((setting) =>
        setting.position === position ? { ...setting, alignment: e } : { ...setting }
      )
    )

    updatedTemplate['line_settings'] = lineSettings;

    setTemplateForm(updatedTemplate);
    setTemplateSaved(false);
  }

  const increasePriority = (element, lineElements) => {
    let elementIndex = lineElements.findIndex((el) => el === element);

    if(elementIndex === 0) { return; }

    let el = lineElements[elementIndex - 1];
    let elementNewPriority = lineElements[elementIndex - 1].priority;
    let elementOldPriority = element.priority;

    if(element.element === 'Barcode Symbol') { setPriority(setBarcodeSymbolPriority, elementNewPriority, 'barcode_symbol_priority') }
    if(element.element === 'Product Name') { setPriority(setProductNamePriority, elementNewPriority, 'product_name_priority') }
    if(element.element === 'Variation Option 1') { setPriority(setOptionOnePriority, elementNewPriority, 'option_1_priority') }
    if(element.element === 'Variation Option 2') { setPriority(setOptionTwoPriority, elementNewPriority, 'option_2_priority') }
    if(element.element === 'Variation Option 3') { setPriority(setOptionThreePriority, elementNewPriority, 'option_3_priority') }
    if(element.element === 'Price') { setPriority(setPricePriority, elementNewPriority, 'price_priority') }
    if(element.element === 'Brand') { setPriority(setBrandPriority, elementNewPriority, 'brand_priority') }
    if(element.element === 'Branding Text') { setPriority(setArbitraryTextPriority, elementNewPriority, 'arbitrary_text_priority') }

    if(el.element === 'Barcode Symbol') { setPriority(setBarcodeSymbolPriority, elementOldPriority, 'barcode_symbol_priority') }
    if(el.element === 'Product Name') { setPriority(setProductNamePriority, elementOldPriority, 'product_name_priority') }
    if(el.element === 'Variation Option 1') { setPriority(setOptionOnePriority, elementOldPriority, 'option_1_priority') }
    if(el.element === 'Variation Option 2') { setPriority(setOptionTwoPriority, elementOldPriority, 'option_2_priority') }
    if(el.element === 'Variation Option 3') { setPriority(setOptionThreePriority, elementOldPriority, 'option_3_priority') }
    if(el.element === 'Price') { setPriority(setPricePriority, elementOldPriority, 'price_priority') }
    if(el.element === 'Brand') { setPriority(setBrandPriority, elementOldPriority, 'brand_priority') }
    if(el.element === 'Branding Text') { setPriority(setArbitraryTextPriority, elementOldPriority, 'arbitrary_text_priority') }

    let movedElement = document.querySelector(`#line-element-${elementNewPriority}`);

    movedElement.classList.add("blink-action");

    setTimeout(() => {
      movedElement.classList.remove("blink-action");
    }, 1000);
  }
  
  const decreasePriority = (element, lineElements) => {
    // lineElements.sort((a,b) => a.last_nom - b.last_nom);
    let elementIndex = lineElements.findIndex((el) => el === element);

    if(elementIndex === (lineElements.length - 1)) { return; }

    let el = lineElements[elementIndex + 1];
    let elementNewPriority = lineElements[elementIndex + 1].priority;
    let elementOldPriority = element.priority;

    if(element.element === 'Barcode Symbol') { setPriority(setBarcodeSymbolPriority, elementNewPriority, 'barcode_symbol_priority') }
    if(element.element === 'Product Name') { setPriority(setProductNamePriority, elementNewPriority, 'product_name_priority') }
    if(element.element === 'Variation Option 1') { setPriority(setOptionOnePriority, elementNewPriority, 'option_1_priority') }
    if(element.element === 'Variation Option 2') { setPriority(setOptionTwoPriority, elementNewPriority, 'option_2_priority') }
    if(element.element === 'Variation Option 3') { setPriority(setOptionThreePriority, elementNewPriority, 'option_3_priority') }
    if(element.element === 'Price') { setPriority(setPricePriority, elementNewPriority, 'price_priority') }
    if(element.element === 'Brand') { setPriority(setBrandPriority, elementNewPriority, 'brand_priority') }
    if(element.element === 'Branding Text') { setPriority(setArbitraryTextPriority, elementNewPriority, 'arbitrary_text_priority') }

    if(el.element === 'Barcode Symbol') { setPriority(setBarcodeSymbolPriority, elementOldPriority, 'barcode_symbol_priority') }
    if(el.element === 'Product Name') { setPriority(setProductNamePriority, elementOldPriority, 'product_name_priority') }
    if(el.element === 'Variation Option 1') { setPriority(setOptionOnePriority, elementOldPriority, 'option_1_priority') }
    if(el.element === 'Variation Option 2') { setPriority(setOptionTwoPriority, elementOldPriority, 'option_2_priority') }
    if(el.element === 'Variation Option 3') { setPriority(setOptionThreePriority, elementOldPriority, 'option_3_priority') }
    if(el.element === 'Price') { setPriority(setPricePriority, elementOldPriority, 'price_priority') }
    if(el.element === 'Brand') { setPriority(setBrandPriority, elementOldPriority, 'brand_priority') }
    if(el.element === 'Branding Text') { setPriority(setArbitraryTextPriority, elementOldPriority, 'arbitrary_text_priority') }

    let movedElement = document.querySelector(`#line-element-${elementNewPriority}`);

    movedElement.classList.add("blink-action");

    setTimeout(() => {
      movedElement.classList.remove("blink-action");
    }, 1000);

  }

  const readyPreview = () => {
    setPreviewGenerating(false);
    if(enqueuePreviewGeneration) {
      handlePreview(enqueuePreviewGeneration, null);
    }
  }

  const validateTemplate = (templateForm) => {
    let valid = true;
    let updatedValidationErrors = [];

    // Must have a template name
    if (!templateForm['template_name']) {
      valid = false;
      updatedValidationErrors.push('Template name missing');
    }

    // Must have label lengths and widths
    if (!templateForm['width'] ||  !templateForm['length']) {
      valid = false;
      updatedValidationErrors.push('Templates must have defined label length and width');
    }

    // If it's a sheet, it must have page length, page width, rows and columns
    if (printType === 'sheet') {
      if (!templateForm.page_definition.page_length || !templateForm.page_definition.page_width || !templateForm.page_definition.columns || !templateForm.page_definition.rows) {
        valid = false;
        updatedValidationErrors.push('Sheets must have defined page length, page width, columns and rows.');
      }
    }

    setValidationErrors(updatedValidationErrors);
    setValidTemplate(valid);
  }

  const description = () => {
    return(
      <>
        <Text>Rename the template if needed, and redefine the label's size and included information with the options here. As changes are made, a preview of the template will appear on the right.</Text>
        <Text>To see a template with a given product or variant, save the template, find the product in the "Labels + Print" section's Catalog Search, then click the item's "Preview Labels" button.</Text>
        <Text>Note: if text extends beyond the edge of a label, it will not be printed. If you need the text to appear, consider shrinking the font size, or changing labels.</Text>
        <Small>Have questions or need some help? Contact us at <a href="mailto:support@athousandapps.com">support@athousandapps.com</a></Small>
        <Panel>
          <div className="info-background">
          <H3>Preview</H3>
          { barcodeTemplates.previewUri &&
            <Document className="pdf-document" file={{ url: barcodeTemplates.previewUri }} loading={<LoadingSpinner size="xxSmall" statusText="Loading Preview" />} onLoadSuccess={() => readyPreview()}>
              <Page
                className="pdf-page"
                pageNumber={1} 
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>
          }
          { barcodeTemplates.errors &&
            <Text color="danger40">
              Failed to load preview
            </Text>
          }
          </div>
        </Panel>
      </>
    )
  }

  const items = [
    { value: 'roll', label: 'Roll' },
    { value: 'sheet', label: 'Sheet' }
  ];

  if (!barcodeTemplates.fungiblesLoaded || !barcodeTemplates.lineSettingsLoaded) {
    return (
      <>
        <PageHeader stripeBackground='#5bc6cc' helpLink="ecwid/manage-barcode-label-templates/" />
        <SettingsPage title="Barcode Templates" description={description()}>
          <LoadingPlaceholder title="Edit Label Template" />
        </SettingsPage>
      </>
    )
  }

  return(
    <>
      { barcodeTemplates.status === 'success' && !barcodeTemplates.isFetching &&
        <>
          <PageHeader stripeBackground='#5bc6cc' helpLink="bigcommerce/manage-barcode-label-templates-2/" />
          <SettingsPage title="Barcode Templates" description={description()}>
            <Panel>
              <div className="flex-container">
                <div className="flex-3">
                  <Panel>
                    <H2>Editing: {templateName}</H2>

                    <H3>Barcode Template Options</H3>
                      { !submitted && <Input required label="Template Name" placeholder="Template Name" id="template_name" type="text" value={templateName} onChange={(e) => setFieldValue(setTemplateName, e)} /> }
                      { submitted && <Input required label="Template Name" placeholder="Template Name" id="template_name" type="text" value={templateName} onChange={(e) => setFieldValue(setTemplateName, e)} disabled /> }                                      

                    <hr/>

                    <H3>Print Type</H3>
                    <Toggle
                      id="action-type"
                      items={items}
                      onChange={(e) => handleSwitchPageType(e)}
                      value={printType}
                    />

                    { printType === 'sheet' &&
                      <>
                        <H3>Page Options</H3>
                        <div className="flex-container padding-15 a-card__darken">
                          <div className="flex-1">
                            <H4>Use Template Preset</H4>
                            <PresetSection
                              setTemplateForm={setTemplateForm}
                              templateForm={templateForm}
                              setLength={setLength}
                              setWidth={setWidth}
                              setPageWidth={setPageWidth}
                              setPageLength={setPageLength}
                              setPageXMargin={setPageXMargin}
                              setPageYMargin={setPageYMargin}
                              setPageRows={setPageRows}
                              setPageColumns={setPageColumns}
                              setPageLabelXPadding={setPageLabelXPadding}
                              setPageLabelYPadding={setPageLabelYPadding}
                              setSizeMeasurement={setSizeMeasurement}
                              handlePreview={handlePreview}
                            />
                          </div>
                          <div className="flex-1">
                            { !submitted && <Input required label="Page Width" placeholder="Page Width" id="page_width" type="text" value={pageWidth} onChange={(e) => setPageValue(setPageWidth, e)} /> }
                            { !submitted && <Input required label="Page Length" placeholder="Page Length" id="page_length" type="text" value={pageLength} onChange={(e) => setPageValue(setPageLength, e)} /> }
                            
                            { !submitted && <Input required label="X Margin" placeholder="Page X Margin" id="page_x_margin" type="text" value={pageXMargin} onChange={(e) => setPageValue(setPageXMargin, e)} /> }
                            { !submitted && <Input required label="Y Margin" placeholder="Page Y Margin" id="page_y_margin" type="text" value={pageYMargin} onChange={(e) => setPageValue(setPageYMargin, e)} /> }

                            { !submitted && <Input required label="Page Rows" placeholder="Page Rows" id="rows" type="text" value={pageRows} onChange={(e) => setPageValue(setPageRows, e)} /> }
                            { !submitted && <Input required label="Page Columns" placeholder="Page Columns" id="columns" type="text" value={pageColumns} onChange={(e) => setPageValue(setPageColumns, e)} /> }

                            { !submitted && <Input required label="Label X Padding" placeholder="Page Label X Padding" id="label_x_padding" type="text" value={pageLabelXPadding} onChange={(e) => setPageValue(setPageLabelXPadding, e)} /> }
                            { !submitted && <Input required label="Label Y Padding" placeholder="Page Label Y Padding" id="label_y_padding" type="text" value={pageLabelYPadding} onChange={(e) => setPageValue(setPageLabelYPadding, e)} /> }
                          </div>
                        </div>
                      </>
                    }

                    <hr/>

                    <H4>Label Dimensions</H4>
                    <div className="flex-container padding-15 a-card__darken">
                      <div className="flex-1">
                        { !submitted && <Input required label="Length" placeholder="Length" id="length" type="text" value={length} onChange={(e) => setNumeric(setLength, e)} /> }
                        { submitted && <Input required label="Length" placeholder="Length" id="length" type="text" value={length} disabled /> }

                        { !submitted && <Input required label="Width" placeholder="Width" id="width" type="text" value={width} onChange={(e) => setNumeric(setWidth, e)} /> }
                        { submitted && <Input required label="Width" placeholder="Width" id="width" type="text" value={width} disabled /> }
                      </div>
                      <div className="flex-1 padding-15">
                        <FormGroup>
                          <Radio checked={sizeMeasurement === "in"} value="in" label="in" onChange={(e) => setMeasurements(e)} />
                          <Radio checked={sizeMeasurement === "cm"} value="cm" label="cm" onChange={(e) => setMeasurements(e)} />
                          <Radio checked={sizeMeasurement === "mm"} value="mm" label="mm" onChange={(e) => setMeasurements(e)} />
                        </FormGroup>
                      </div>
                    </div>
                    
                    <div className="flex-container padding-15">
                      <div className="flex-1">
                        { !submitted && <Input required label="Line Count" placeholder="Line Count" id="line_count" type="text" value={lineCount || ''} onChange={(e) => setInteger(changeLineCount, e)} /> }
                        { submitted && <Input required label="Line Count" placeholder="Line Count" id="line_count" type="text" value={lineCount || ''} disabled /> }
                      </div>
                      <div className="flex-1 padding-15">
                        { printType === 'roll' &&
                          <>
                            <FormControlLabel renderOptional={false}>Rotate Barcode</FormControlLabel>
                            <Switch checked={rotate} onChange={() => handleSwitchRotate()} />
                          </>
                        }
                      </div>
                    </div>

                    <hr/>

                    <H3>Barcode Row</H3>
                    <div className="padding-15">
                      <table className="full-width">
                        <tr>
                          <th className="width-33 table-header">Enabled</th>
                          <th className="width-33 table-header">Line</th>
                          <th className="width-35 table-header">Height % of label</th>
                        </tr>
                        <tr>
                          <td className="text-center table-cell">
                            <input checked={barcodeEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setBarcodeEnabled, barcodeEnabled, 'barcode_enabled')} />
                          </td>
                          <td className="text-center table-cell">
                            <select value={barcodeLine} id="barcode_line" onChange={(e) => setInteger(setBarcodeLine, e)} className="field__select">
                              { lineSettings.map((_lineSetting, index) =>
                                <option value={index} key={index}>{index + 1}</option>
                              )}
                            </select>
                          </td>
                          <td className="text-center left-15 right-15 table-cell">
                            { !submitted && <Input required label="" placeholder="Barcode height %" id="barcode_height_percent" type="text" value={barcodeHeightPercent || ''} onChange={(e) => setInteger(setBarcodeHeightPercent, e)} /> }
                            { submitted && <Input required label="" placeholder="Barcode height %" id="barcode_height_percent" type="text" value={barcodeHeightPercent || ''}  /> }
                          </td>
                        </tr>
                      </table>
                    </div>

                    <H3>Label Parts</H3>
                    <div className="padding-15">
                      <table className="full-width">
                        <tr>
                          <th className="width-12 table-header">Enabled</th>
                          <th className="width-30 table-header">Label Part</th>
                          <th className="width-12 table-header">Line</th>
                          <th className="width-12 table-header">Include Name</th>
                          <th className="width-34 table-header">Name Override</th>
                        </tr>
                        <tr>
                          <td className="text-center table-cell">
                            <input checked={barcodeSymbolEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setBarcodeSymbolEnabled, barcodeSymbolEnabled, 'barcode_symbol_enabled')} />
                          </td>
                          <td className="text-center table-cell">
                            <Text>Barcode Symbol</Text>
                          </td>
                          <td className="text-center table-cell">
                            <select value={barcodeSymbolLine} id="barcode_symbol_line" onChange={(e) => setInteger(setBarcodeSymbolLine, e)}>
                              { lineSettings.map((_lineSetting, index) =>
                                <option value={index} key={index}>{index + 1}</option>
                              )}
                            </select>
                          </td>
                          <td className="text-center table-cell">
                            <input checked={barcodeSymbolLabelEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setBarcodeSymbolLabelEnabled, barcodeSymbolLabelEnabled, 'barcode_symbol_label_enabled')} />
                          </td>
                          <td className="text-center left-15 right-15 table-cell">
                            { !submitted && <Input placeholder="Name Override" id="barcode_symbol_label_override" type="text" value={barcodeSymbolLabelOverride || ''} onChange={(e) => setFieldValue(setBarcodeSymbolLabelOverride, e)} /> }
                            { submitted && <Input placeholder="Name Override" id="barcode_symbol_label_override" type="text" value={barcodeSymbolLabelOverride || ''}  /> }
                          </td>
                        </tr>

                        <tr>
                          <td className="text-center table-cell">
                            <input checked={productNameEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setProductNameEnabled, productNameEnabled, 'product_name_enabled')} />
                          </td>
                          <td className="text-center table-cell">
                            <Text>Product Name</Text>
                          </td>
                          <td className="text-center table-cell">
                            <select value={productNameLine} id="product_name_line" onChange={(e) => setInteger(setProductNameLine, e)}>
                              { lineSettings.map((_lineSetting, index) =>
                                <option value={index} key={index}>{index + 1}</option>
                              )}
                            </select>
                          </td>
                          <td className="text-center table-cell">
                            <input checked={productNameLabelEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setProductNameLabelEnabled, productNameLabelEnabled, 'product_name_label_enabled')} />
                          </td>
                          <td className="text-center left-15 right-15 table-cell">
                            { !submitted && <Input placeholder="Name Override" id="product_name_label_override" type="text" value={productNameLabelOverride || ''} onChange={(e) => setFieldValue(setProductNameLabelOverride, e)} /> }
                            { submitted && <Input placeholder="Name Override" id="product_name_label_override" type="text" value={productNameLabelOverride || ''}  /> }
                          </td>
                        </tr>

                        <tr>
                          <td className="text-center table-cell">
                            <input checked={optionOneEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setOptionOneEnabled, optionOneEnabled, 'option_1_enabled')} />
                          </td>
                          <td className="text-center table-cell">
                            <Text>Variant Option 1</Text>
                          </td>
                          <td className="text-center table-cell">
                            <select value={optionOneLine} id="option_1_line" onChange={(e) => setInteger(setOptionOneLine, e)}>
                              { lineSettings.map((_lineSetting, index) =>
                                <option value={index} key={index}>{index + 1}</option>
                              )}
                            </select>
                          </td>
                          <td className="text-center table-cell">
                            <input checked={optionOneLabelEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setOptionOneLabelEnabled, optionOneLabelEnabled, 'option_1_label_enabled')} />
                          </td>
                          <td className="text-center left-15 right-15 table-cell">
                            { !submitted && <Input placeholder="Name Override" id="option_1_label_override" type="text" value={optionOneLabelOverride || ''} onChange={(e) => setFieldValue(setOptionOneLabelOverride, e)} /> }
                            { submitted && <Input placeholder="Name Override" id="option_1_label_override" type="text" value={optionOneLabelOverride || ''}  /> }
                          </td>
                        </tr>

                        <tr>
                          <td className="text-center table-cell">
                            <input checked={optionTwoEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setOptionTwoEnabled, optionTwoEnabled, 'option_2_enabled')} />
                          </td>
                          <td className="text-center table-cell">
                            <Text>Variant Option 2</Text>
                          </td>
                          <td className="text-center table-cell">
                            <select value={optionTwoLine} id="option_2_line" onChange={(e) => setInteger(setOptionTwoLine, e)}>
                              { lineSettings.map((_lineSetting, index) =>
                                <option value={index} key={index}>{index + 1}</option>
                              )}
                            </select>
                          </td>
                          <td className="text-center table-cell">
                            <input checked={optionTwoLabelEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setOptionTwoLabelEnabled, optionTwoLabelEnabled, 'option_2_label_enabled')} />
                          </td>
                          <td className="text-center left-15 right-15 table-cell">
                            { !submitted && <Input placeholder="Name Override" id="option_2_label_override" type="text" value={optionTwoLabelOverride || ''} onChange={(e) => setFieldValue(setOptionTwoLabelOverride, e)} /> }
                            { submitted && <Input placeholder="Name Override" id="option_2_label_override" type="text" value={optionTwoLabelOverride || ''}  /> }
                          </td>
                        </tr>

                        <tr>
                          <td className="text-center table-cell">
                            <input checked={optionThreeEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setOptionThreeEnabled, optionThreeEnabled, 'option_3_enabled')} />
                          </td>
                          <td className="text-center table-cell">
                            <Text>Variant Option 3</Text>
                          </td>
                          <td className="text-center table-cell">
                            <select value={optionThreeLine} id="option_3_line" onChange={(e) => setInteger(setOptionThreeLine, e)}>
                              { lineSettings.map((_lineSetting, index) =>
                                <option value={index} key={index}>{index + 1}</option>
                              )}
                            </select>
                          </td>
                          <td className="text-center table-cell">
                            <input checked={optionThreeLabelEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setOptionThreeLabelEnabled, optionThreeLabelEnabled, 'option_3_label_enabled')} />
                          </td>
                          <td className="text-center left-15 right-15 table-cell">
                            { !submitted && <Input placeholder="Name Override" id="option_3_label_override" type="text" value={optionThreeLabelOverride || ''} onChange={(e) => setFieldValue(setOptionThreeLabelOverride, e)} /> }
                            { submitted && <Input placeholder="Name Override" id="option_3_label_override" type="text" value={optionThreeLabelOverride || ''}  /> }
                          </td>
                        </tr>

                        <tr>
                          <td className="text-center table-cell">
                            <input checked={priceEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setPriceEnabled, priceEnabled, 'price_enabled')} />
                          </td>
                          <td className="text-center table-cell">
                            <Text>Price</Text>
                          </td>
                          <td className="text-center table-cell">
                            <select value={priceLine} id="price_line" onChange={(e) => setInteger(setPriceLine, e)}>
                              { lineSettings.map((_lineSetting, index) =>
                                <option value={index} key={index}>{index + 1}</option>
                              )}
                            </select>
                          </td>
                          <td className="text-center table-cell">
                            <input checked={priceLabelEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setPriceLabelEnabled, priceLabelEnabled, 'price_label_enabled')} />
                          </td>
                          <td className="text-center left-15 right-15 table-cell">
                            { !submitted && <Input placeholder="Name Override" id="price_label_override" type="text" value={priceLabelOverride || ''} onChange={(e) => setFieldValue(setPriceLabelOverride, e)} /> }
                            { submitted && <Input placeholder="Name Override" id="price_label_override" type="text" value={priceLabelOverride || ''}  /> }
                          </td>
                        </tr>

                        <tr>
                          <td className="text-center table-cell">
                            <input checked={brandEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setBrandEnabled, brandEnabled, 'brand_enabled')} />
                          </td>
                          <td className="text-center table-cell">
                            <Text>Brand</Text>
                          </td>
                          <td className="text-center table-cell">
                            <select value={brandLine} id="brand_line" onChange={(e) => setInteger(setBrandLine, e)}>
                              { lineSettings.map((_lineSetting, index) =>
                                <option value={index} key={index}>{index + 1}</option>
                              )}
                            </select>
                          </td>
                          <td className="text-center table-cell">
                            <input checked={brandLabelEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setBrandLabelEnabled, brandLabelEnabled, 'brand_label_enabled')} />
                          </td>
                          <td className="text-center left-15 right-15 table-cell">
                            { !submitted && <Input placeholder="Name Override" id="brand_label_override" type="text" value={brandLabelOverride || ''} onChange={(e) => setFieldValue(setBrandLabelOverride, e)} /> }
                            { submitted && <Input placeholder="Name Override" id="brand_label_override" type="text" value={brandLabelOverride || ''}  /> }
                          </td>
                        </tr>

                      </table>

                      
                    </div>

                    <hr/>
                    <div className="padding-15">
                      <H3>Store Branding</H3>
                      <Small>Set your store logo in <Link to="/store_settings">app settings</Link>.</Small>
                      
                      <H4>Store Logo Row</H4>
                      <table className="full-width">
                        <tr>
                          <th className="width-33 table-header">Enabled</th>
                          <th className="width-33 table-header">Line</th>
                          <th className="width-33 table-header">Height % of Label</th>
                        </tr>
                        <tr>
                          <td className="text-center table-cell">
                            <input checked={storeLogoEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setStoreLogoEnabled, storeLogoEnabled, 'store_logo_enabled')} />
                          </td>
                          <td className="text-center table-cell">
                            <select value={storeLogoLine} id="store_logo_line" onChange={(e) => setInteger(setStoreLogoLine, e)} className="field__select">
                              { lineSettings.map((_lineSetting, index) =>
                                <option value={index} key={index}>{index + 1}</option>
                              )}
                            </select>
                          </td>
                          <td className="text-center left-15 right-15 table-cell">
                            { !submitted && <Input required label="" placeholder="Store Logo height %" id="store_logo_height_percent" type="text" value={storeLogoHeightPercent || ''} onChange={(e) => setInteger(setStoreLogoHeightPercent, e)} /> }
                            { submitted && <Input required label="" placeholder="Store Logo height %" id="store_logo_height_percent" type="text" value={storeLogoHeightPercent || ''}  /> }
                          </td>
                        </tr>
                      </table>

                      <H4>Branding Text</H4>
                      <table className="full-width">
                        <tr>
                          <th className="width-33 table-header">Enabled</th>
                          <th className="width-33 table-header">Line</th>
                          <th className="width-33 table-header">Text</th>
                        </tr>
                        <tr>
                          <td className="text-center table-cell">
                            <input checked={arbitraryTextEnabled} type="checkbox" value="on" tabindex="0" onClick={() => switchBoolean(setArbitraryTextEnabled, arbitraryTextEnabled, 'arbitrary_text_enabled')} />
                          </td>
                          <td className="text-center table-cell">
                            <select value={arbitraryTextLine} id="arbitrary_text_line" onChange={(e) => setInteger(setArbitraryTextLine, e)} className="field__select">
                              { lineSettings.map((_lineSetting, index) =>
                                <option value={index} key={index}>{index + 1}</option>
                              )}
                            </select>
                          </td>
                          <td className="text-center left-15 right-15 table-cell">
                            { !submitted && <Input placeholder="Branding Text" id="arbitrary_text_label_override" type="text" value={arbitraryTextLabelOverride || ''} onChange={(e) => setFieldValue(setArbitraryTextLabelOverride, e)} /> }
                            { submitted && <Input placeholder="Branding Text" id="arbitrary_text_label_override" type="text" value={arbitraryTextLabelOverride || ''}  /> }
                          </td>
                        </tr>
                      </table>
                    </div>

                    <hr/>

                    <H3>Line Settings</H3>
                    <div className="flex-container padding-15">
                      <div className="flex-1">
                        <SlTabGroup placement="start">
                          <SlTab slot="nav" panel="disabled" disabled>
                            Select a Line Number
                          </SlTab>

                          { lineSettings.map((lineSetting, index) => 
                            <SlTab slot="nav" panel={`panel-${index}`}>
                              {index + 1}
                            </SlTab>
                          )}

                          <SlTabPanel name="disabled">Select a line number to modify the order that selected label parts appear in.</SlTabPanel>
                          { lineSettings.map((lineSetting, index) => 
                            <SlTabPanel name={`panel-${index}`}>
                              <div className="bottom-15">
                                <H4>Placement</H4>

                                <Toggle
                                  id="toggle-example"
                                  items={[
                                    {
                                      value: 'left',
                                      label: 'Align Left'
                                    },
                                    {
                                      value: 'center',
                                      label: 'Align Center'
                                    },
                                    {
                                      value: 'right',
                                      label: 'Align Right'
                                    }
                                  ]}
                                  label="Text Alignment"
                                  onChange={(e) => setAlignment(lineSetting.position, e)}
                                  value={lineSetting.alignment}
                                />

                                { !submitted && <Input id={`setting_font_size_${index}`} required label="Font Size" placeholder="Font Size" type="text" value={lineSetting.font_size || ''} onChange={(e) => setFontSize(lineSetting.position, e)} /> }
                                { submitted && <Input id={`setting_font_size_${index}`} required label="Font Size" placeholder="Font Size" type="text" value={lineSetting.font_size || ''} disabled /> }

                                <H4>Order</H4>
                                <TemplateLineOrderContainer
                                  barcode={barcodeEnabled && (index === barcodeLine)}
                                  storeLogo={storeLogoEnabled && (index === storeLogoLine)}
                                  
                                  barcodeSymbol={barcodeSymbolEnabled && (index === barcodeSymbolLine)}
                                  productName={productNameEnabled && (index === productNameLine)}
                                  optionOne={optionOneEnabled && (index === optionOneLine)}
                                  optionTwo={optionTwoEnabled && (index === optionTwoLine)}
                                  optionThree={optionThreeEnabled && (index === optionThreeLine)}
                                  price={priceEnabled && (index === priceLine)}
                                  brand={brandEnabled && (index === brandLine)}
                                  arbitraryText={arbitraryTextEnabled && (index === arbitraryTextLine)}
                                  
                                  barcodeSymbolPriority={barcodeSymbolPriority}
                                  productNamePriority={productNamePriority}
                                  optionOnePriority={optionOnePriority}
                                  optionTwoPriority={optionTwoPriority}
                                  optionThreePriority={optionThreePriority}
                                  pricePriority={pricePriority}
                                  brandPriority={brandPriority}
                                  arbitraryTextPriority={arbitraryTextPriority}

                                  increasePriority={increasePriority}
                                  decreasePriority={decreasePriority}
                                />

                              </div>
                            </SlTabPanel>
                          )}
                        </SlTabGroup>
                      </div>
                    </div>

                    <hr/>
                    { validTemplate &&
                      <Button isLoading={submitted} onClick={!submitted ? () => handleSubmit() : () => null}>Save Template</Button> 
                    }
                    { !validTemplate && <Button isLoading={submitted} onClick={!submitted ? () => handleSubmit() : () => null} disabled>Save Template</Button> }
                    { (validationErrors.length > 0) &&
                      <>
                        { validationErrors.map((error) =>
                          <Text color="danger40">
                            {error} 
                          </Text>
                        )}
                        <br/>
                      </>
                    }
                    { !templateSaved && 
                      <Text color="warning40">Template changes have not been saved</Text>
                    }
                  </Panel>
                </div>
              </div>
            </Panel>
          </SettingsPage>
        </>
      }

      { (barcodeTemplates.status !== 'success' || barcodeTemplates.isFetching) &&
        <>
          <PageHeader stripeBackground='#5bc6cc' helpLink="bigcommerce/manage-barcode-label-templates-2/" />
          <SettingsPage title="Barcode Templates" description={description()}>
            <LoadingPlaceholder title="Barcode Templates" />
          </SettingsPage>
        </>
      }
    </>
  )
}

const mapStateToProps = state => ({
  barcodeTemplates: state.barcodeTemplates,
  newTemplate: state.barcodeTemplates.newTemplate,
  templateFungibles: state.barcodeTemplates.templateFungibles,
  templateLineSettings: state.barcodeTemplates.templateLineSettings,
});

const mapDispatchToProps = (dispatch) => ({
  saveTemplateChanges: (payload, options) => dispatch(updateBarcodeTemplate(payload, options)),
  loadPreview: (payload, options, values) => dispatch(previewBarcodeTemplate(payload, options, values)),
  loadFungibles: (payload, uuid) => dispatch(getTemplateFungibles(payload, uuid)),
  loadLineSettings: (payload, uuid) => dispatch(getTemplateLineSettings(payload, uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTemplateForm);
