import {
  BARCODE_TEMPLATE_CREATE_REQUESTED,
  BARCODE_TEMPLATE_CREATE_SUCCESS,
  BARCODE_TEMPLATE_CREATE_FAILURE,
  BARCODE_TEMPLATE_DELETE_REQUESTED,
  BARCODE_TEMPLATE_DELETE_SUCCESS,
  BARCODE_TEMPLATE_DELETE_FAILURE,
  BARCODE_TEMPLATE_INDEX_REQUESTED,
  BARCODE_TEMPLATE_INDEX_SUCCESS,
  BARCODE_TEMPLATE_INDEX_FAILURE,
  BARCODE_TEMPLATE_UPDATE_REQUESTED,
  BARCODE_TEMPLATE_UPDATE_SUCCESS,
  BARCODE_TEMPLATE_UPDATE_FAILURE,
  BARCODE_TEMPLATE_PREVIEW_REQUESTED,
  BARCODE_TEMPLATE_PREVIEW_SUCCESS,
  BARCODE_TEMPLATE_PREVIEW_FAILURE,
  GET_FUNGIBLES_REQUESTED,
  GET_FUNGIBLES_SUCCESS,
  GET_FUNGIBLES_FAILURE,
  GET_LINE_SETTINGS_REQUESTED,
  GET_LINE_SETTINGS_SUCCESS,
  GET_LINE_SETTINGS_FAILURE,
  BARCODE_TEMPLATES_UPGRADE_REQUESTED,
  BARCODE_TEMPLATES_UPGRADE_SUCCESS,
  BARCODE_TEMPLATES_UPGRADE_FAILURE,
} from '../types';

const INITIAL_STATE = {
  isFetching: false,
  isDeleting: false,
  templates: [],
  templateLineSettings: null,
  templateFungibles: null,
  fungiblesLoaded: false,
  lineSettingsLoaded: false,
  newTemplate: null,
  status: 'waiting',
  previewUri: null,
  errors: null
};

const barcodeTemplates = (state = INITIAL_STATE, action) => {
  const newState = { ...state };

  switch(action.type) {
    case BARCODE_TEMPLATE_INDEX_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        newTemplate: null,
        templateFungibles: null,
        fungiblesLoaded: false,
        status: 'requesting'
      });

    case BARCODE_TEMPLATE_INDEX_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'success',
        newTemplate: null,
        templates: action.payload.templates,
        errors: null
      });

    case BARCODE_TEMPLATE_INDEX_FAILURE:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'waiting',
        newTemplate: null,
        errors: 'error here'
      });

    case BARCODE_TEMPLATE_CREATE_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        newTemplate: null,
        status: 'requesting'
      });

    case BARCODE_TEMPLATE_CREATE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'success',
        newTemplate: action.payload.newTemplate,
        templates: [...state.templates, action.payload.newTemplate],
        errors: null
      });

    case BARCODE_TEMPLATE_CREATE_FAILURE:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'waiting',
        newTemplate: null,
        errors: 'error here'
      });

    case BARCODE_TEMPLATE_UPDATE_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        newTemplate: null,
        status: 'requesting'
      });

    case BARCODE_TEMPLATE_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'success',
        newTemplate: action.payload.newTemplate,
        errors: null
      });

    case BARCODE_TEMPLATE_UPDATE_FAILURE:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'waiting',
        newTemplate: null,
        errors: 'error here'
      });


    case BARCODE_TEMPLATE_PREVIEW_REQUESTED:
      return Object.assign({}, state, {
        isFetching: false,
        newTemplate: null,
      });

    case BARCODE_TEMPLATE_PREVIEW_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        newTemplate: null,
        previewUri: action.payload.previewUri,
        errors: null
      });

    case BARCODE_TEMPLATE_PREVIEW_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        newTemplate: null,
        errors: 'error here'
      });


    case BARCODE_TEMPLATE_DELETE_REQUESTED:
      return Object.assign({}, state, {
        isDeleting: true,
      });

    case BARCODE_TEMPLATE_DELETE_SUCCESS:
      newState.isDeleting = false;
      newState.errors = null;
      newState.templates = newState.templates.filter(template => template.uuid !== action.payload.uuid);
      return newState;

    case BARCODE_TEMPLATE_DELETE_FAILURE:
      return Object.assign({}, null, {
        isDeleting: false,
        errors: 'error here'
      });


    case GET_FUNGIBLES_REQUESTED:
      return Object.assign({}, state, {
        templateFungibles: null,
        fungiblesLoaded: false,
      });

    case GET_FUNGIBLES_SUCCESS:
      return Object.assign({}, state, {
        templateFungibles: action.payload.fungibles,
        fungiblesLoaded: true
      });

    case GET_FUNGIBLES_FAILURE:
      return Object.assign({}, state, {
        templateFungibles: null,
        fungiblesLoaded: true,
        errors: 'error here'
      });

    case GET_LINE_SETTINGS_REQUESTED:
      return Object.assign({}, state, {
        templateLineSettings: null,
        lineSettingsLoaded: false,
      });

    case GET_LINE_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        templateLineSettings: action.payload.line_settings,
        lineSettingsLoaded: true,
      });

    case GET_LINE_SETTINGS_FAILURE:
      return Object.assign({}, state, {
        templateLineSettings: null,
        lineSettingsLoaded: true,
        errors: 'error here'
      });

    default:
      return state;
  }
}

export default barcodeTemplates;