import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Button, Text, H4, H3, H2, Dropdown, Panel, Table, Small, Collapse } from '@bigcommerce/big-design';
import { ArrowDropDownIcon, FileDownloadIcon } from '@bigcommerce/big-design-icons';

import LoadingPlaceholder from '../partials/LoadingPlaceholder';
import SettingsPage from '../partials/SettingsPage';
import NoLabelTemplatesBox from '../partials/NoLabelTemplatesBox';
import PrintLine from "./PrintLine";
import ModifyCountDialog from './ModifyCountDialog';
import LoadingSpinner from '../partials/LoadingSpinner';
import PageHeader from '../partials/PageHeader';

import { indexBarcodeTemplates } from '../../redux/actions/barcodeTemplates';
import { requestPrint } from '../../redux/actions/prints';

const PrintBarcodes = ({ payload, getTemplates, selectedProductList, barcodeTemplates, postPrint, downloadUrl, prints, missingBarcodes }) => {
  const [submitted, setSubmitted] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState({});
  const [modifyItem, setModifyItem] = useState(null);
  const [modifyType, setModifyType] = useState(null);
  const [modifyCount, setModifyCount] = useState(null);
  const [modifyItemId, setModifyItemId] = useState(null);
  const [showPrintCountDialog, setShowPrintCountDialog] = useState(false);
  const [labelCount, setLabelCount] = useState(0);
  const [printCounts, setPrintCounts] = useState({
      products: {},
      variations: {}
    }
  )

  const [sortOrder, setSortOrder] = useState('SKU');

  const switchActiveTemplate = (event) => {
    let template = barcodeTemplates.templates.find(template => template.uuid === event.hash);
    setActiveTemplate(template);
  }

  const templates = () => {
    let templateItems = [];

    barcodeTemplates.templates.map(template => 
      templateItems.push(
        {
          content: template.template_name,
          onItemClick: (event) => switchActiveTemplate(event),
          hash: template.uuid
        }
      )
    )

    return templateItems;
  }

  const setPrintCount = (type, itemId, modifyItem, count) => {
    let tempCounts = printCounts;

    tempCounts[type][itemId] = {
      count: count,
      item: modifyItem
    };

    setPrintCounts(tempCounts);
    calculateTotal();
  }

  const calculateTotal = () => {
    let tempTotal = 0;

    Object.keys(selectedProductList.products).map(itemId =>
      tempTotal = tempTotal + (parseInt(printCounts.products[itemId]?.count) || 0)
    )

    Object.keys(selectedProductList.variations).map(itemId => 
      tempTotal = tempTotal + (parseInt(printCounts.variations[itemId]?.count) || 0)
    )

    setLabelCount(tempTotal);
  }

  const setAllToZero = () => {
    let tempCounts = {
      products: {},
      variations: {}
    };

    Object.keys(selectedProductList.products).map(itemId =>
      tempCounts.products[itemId] = {
        count: 0,
        item: selectedProductList.products[itemId]
      }
    )

    Object.keys(selectedProductList.variations).map(itemId =>
      tempCounts.variations[itemId] = {
        count: 0,
        item: selectedProductList.variations[itemId]
      }
    )

    setPrintCounts(tempCounts);
  }

  const setAllToOne = () => {
    let tempCounts = {
      products: {},
      variations: {}
    };

    Object.keys(selectedProductList.products).map(itemId =>
      tempCounts.products[itemId] = {
        count: 1,
        item: selectedProductList.products[itemId]
      }
    )

    Object.keys(selectedProductList.variations).map(itemId =>
      tempCounts.variations[itemId] = {
        count: 1,
        item: selectedProductList.variations[itemId]
      }
    )

    setPrintCounts(tempCounts);
  }

  const setAllToInventory = () => {
    let tempCounts = {
      products: {},
      variations: {}
    };

    Object.keys(selectedProductList.products).map(itemId =>
      tempCounts.products[itemId] = {
        count: selectedProductList.products[itemId].quantity,
        item: selectedProductList.products[itemId]
      }
    )

    Object.keys(selectedProductList.variations).map(itemId =>
      tempCounts.variations[itemId] = {
        count: selectedProductList.variations[itemId].quantity,
        item: selectedProductList.variations[itemId]
      }
    )

    setPrintCounts(tempCounts);
  }

  const sortItems = () => {
    let items = [];

    items.push(
      {
        content: 'SKU',
        onItemClick: () => setSortOrder('SKU'),
        hash: 'SKU'
      }
    );

    items.push(
      {
        content: 'Bin Picking Number (Default location)',
        onItemClick: () => setSortOrder('Bin Picking Number (Default location)'),
        hash: 'Bin Picking Number (Default location)'
      }
    );

    items.push(
      {
        content: 'Name',
        onItemClick: () => setSortOrder('Name'),
        hash: 'Name'
      }
    );



    return items;
  }

  const download = () => {
    postPrint(payload, activeTemplate.uuid, printCounts, sortOrder);
    setSubmitted(true);
  }

  useEffect(() => {
    getTemplates(payload);
    setAllToZero();
  }, []);

  useEffect(() => {
    calculateTotal();
  }, [printCounts]);

  useEffect(() => {
    if(downloadUrl && submitted) { 
      let win = window.open(downloadUrl, '_blank');
      win.focus();
      setSubmitted(false);
    }
  }, [downloadUrl]);

  useEffect(() => {
    if (barcodeTemplates.status === 'success' && barcodeTemplates.templates.length > 0 && !activeTemplate.uuid) {      
      setActiveTemplate(barcodeTemplates.templates[0]);
    }
  }, [barcodeTemplates]);

  const description = () => {
    return(
      <> 
        <Text>Select a template to print the selected barcodes with by choosing it from the Templates dropdown.</Text>
        <Text>You can select the number of labels to print for all products from the buttons from the top card. "Set All to 0" sets the print count of all items to 0, "Set All to 1" sets the counts to 1, and "Set All to Quantity in Stock" will set the number of labels to print to match the number of items of that type in stock.</Text>
        <Text>You can also click on any item to modify the print count of any individual item.</Text>
        <Text>Once you have a template selected and a print count set for your items, click the "Confirm and Download Print File" button to download a print pdf.</Text>
        <Text>Note: you can still print labels for items that are missing barcodes. The labels will have an empty space where the barcode would have been. If your label template doesn't use a barcode row, you can ignore these warnings.</Text>
        <Small>Have questions or need some help? Contact us at <a href="mailto:support@athousandapps.com">support@athousandapps.com</a></Small>
      </>
    )
  }

  return(
    <>
      <PageHeader stripeBackground='#1774bb' helpLink="bigcommerce/print-barcode-labels/" />
      <SettingsPage title="Print Barcodes" description={description()}>
        <Panel>
          <H2>Print Template</H2>
          { barcodeTemplates.status === 'success' && barcodeTemplates.templates.length > 0 && 
            <Dropdown
              items={templates()}
              maxHeight={250}
              placement="bottom-start"
              toggle={<Button iconRight={<ArrowDropDownIcon />}>{activeTemplate?.template_name || "Select Template"}</Button>}
            />
          }
          { barcodeTemplates.status === 'success' && barcodeTemplates.templates.length === 0 && 
            <NoLabelTemplatesBox/>
          }

          { barcodeTemplates.status !== 'success' &&
            <LoadingSpinner size="xxSmall" statusText="Loading Templates" />
          }
          { activeTemplate?.uuid && (labelCount > 0) && (prints.status !== "requesting") && 
            <div className="top-15">
              <Button iconRight={<FileDownloadIcon />} onClick={() => download()}>
                Confirm and Download Print File
              </Button>
              <Text color="warning40">
                Please ensure your browser allows popups for this page when downloading your print file.
              </Text>
            </div> }
          { (!activeTemplate.uuid || (labelCount === 0)) && (prints.status !== "requesting") && <div className="top-15"><Button iconRight={ <FileDownloadIcon /> } disabled>Confirm and Download Print File</Button></div> }
          { prints.status === "requesting" && 
            <div className="top-15">
              <LoadingSpinner size="xxSmall" statusText="Generating Print File" />
              <Text color="warning40">
                Please ensure your browser allows popups for this page when downloading your print file.
              </Text>
            </div>
          }
          <hr/>

          <H3>Print Settings</H3>

          <div className="background-darken padding-20">
            <H4>Sort Order</H4>
            <Dropdown
              items={sortItems()}
              maxHeight={250}
              placement="bottom-start"
              toggle={<Button iconRight={<ArrowDropDownIcon />}>{sortOrder}</Button>}
            />
          </div>
          <br/>
          <br/>

          <span><Button variant="secondary" onClick={() => setAllToZero()}>Set All to 0</Button></span>
          <span className="left-15"><Button variant="secondary" onClick={() => setAllToOne()}>Set All to 1</Button></span>
          <span className="left-15"><Button variant="secondary" onClick={() => setAllToInventory()}>Set All to Quantity in Stock</Button></span>
        </Panel>
        <Panel>
          { Object.keys(selectedProductList.products).map(itemId =>
              <PrintLine
                id={itemId}
                itemId={itemId}
                item={selectedProductList.products[itemId]}
                type='products'
                printCount={printCounts.products[itemId]?.count}
                setModifyItem={setModifyItem}
                setModifyType={setModifyType}
                setModifyCount={setModifyCount}
                setModifyItemId={setModifyItemId}
                setShowPrintCountDialog={setShowPrintCountDialog}
                missingBarcode={ (missingBarcodes.indexOf(selectedProductList.products[itemId].sku) !== -1) || (missingBarcodes.indexOf(selectedProductList.products[itemId].upc) !== -1) }
              />
            )
          }
          { Object.keys(selectedProductList.variations).map(itemId =>
              <PrintLine
                id={itemId}
                itemId={itemId}
                item={selectedProductList.variations[itemId]}
                type='variations'
                printCount={printCounts.variations[itemId]?.count}
                setModifyItem={setModifyItem}
                setModifyType={setModifyType}
                setModifyCount={setModifyCount}
                setModifyItemId={setModifyItemId}
                setShowPrintCountDialog={setShowPrintCountDialog}
                missingBarcode={ (missingBarcodes.indexOf(selectedProductList.variations[itemId].sku) !== -1) || (missingBarcodes.indexOf(selectedProductList.variations[itemId].upc) !== -1) }
              />
            )
          }
        </Panel>

      </SettingsPage>

      { showPrintCountDialog &&
        <ModifyCountDialog
          modifyItemId={modifyItemId}
          modifyItem={modifyItem}
          modifyType={modifyType}
          modifyCount={modifyCount}
          setPrintCount={setPrintCount}
          setShowPrintCountDialog={setShowPrintCountDialog}
          showPrintCountDialog={showPrintCountDialog}
        />
      }
    </>
  )
}

const mapStateToProps = state => ({
  selectedProductList: state.products.selectedProductList,
  missingBarcodes: state.products.missingBarcodes,
  barcodeTemplates: state.barcodeTemplates,
  prints: state.prints,
  downloadUrl: state.prints.downloadUrl,
});

const mapDispatchToProps = (dispatch) => ({
  getTemplates: (payload) => dispatch(indexBarcodeTemplates(payload)),
  postPrint: (payload, template, printCounts, sortOrder) => dispatch(requestPrint(payload, template, printCounts, sortOrder)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintBarcodes);
